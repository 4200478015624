const DownloadModule = {
  blob: async (blob, mimeType) => {
    let fileData;
    fileData = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.readAsDataURL(blob);
    });

    return new Promise((resolve) => {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'SHARE_BLOB',
        mimeType: mimeType,
        data: fileData
      }));

      resolve(fileData)
    })
  }
}

export default DownloadModule;
