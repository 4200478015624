<template>
  <Transition name="slide-fade">
    <div v-if="active">
      <div  class="top-colortable h-[8px] w-full top-0 lef-0 fixed map-overlay !h-[20px]" :style="cssLinearGradient">
        <div v-if="showLabels" class="text-sm grid grid-flow-row h-full" :class="`grid-cols-${colortable.length}`">
          <div v-for="(label, idx) in labels" class="text-center select-none">
            <div class="label-pill h-full sm:block" v-if="label.length > 0" :class="{
              'block absolute sm:relative': (idx === 0 || idx === labels.length-1) && lotsOfColors,
              'left-3 sm:left-auto': (idx === 0) && lotsOfColors,
              'right-3 sm:right-auto': (idx === labels.length-1) && lotsOfColors,
              'hidden': (!(idx === 0 || idx === labels.length-1)) && lotsOfColors,
            }">
              <span class="bg-white/75 text-black font-bold px-2 py-0.5 rounded-b-lg text-xs" v-text="label"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'SimpleColortable',
  props: ['colortable'],
  data() {
    return {
      colorStrategy: 'solid',
      showLabels: true
    }
  },
  mounted() {
    document.body.classList.add(['body-simple-colorbar-padding'])
  },
  unmounted() {
    document.body.classList.remove(['body-simple-colorbar-padding'])
  },
  computed: {
    active() {
      return this.colortable.length > 0;
    },
    lotsOfColors() {
      return this.colortable.length > 4;
    },
    cssLinearGradient() {
      if (! this.active) {
        return '';
      }

      if(this.colorStrategy === 'gradient') {
        const len = this.colortable.length;
        const colorsAsRgb = this.colortable.map((c, i) => {
          return `rgb(${c.color[0]},${c.color[1]},${c.color[2]}) ${(i/len)*100}%`
        });
        return `background: linear-gradient(90deg, ${colorsAsRgb.join(', ')});`;
      }
      else if(this.colorStrategy === 'solid') {
        const len = this.colortable.length;
        const colorsAsRgb = this.colortable.map((c, i) => {
          return `rgb(${c.color[0]},${c.color[1]},${c.color[2]}) ${(i/len)*100}%, rgb(${c.color[0]},${c.color[1]},${c.color[2]}) ${((i+1)/len)*100}%`
        });
        return `background: linear-gradient(90deg, ${colorsAsRgb.join(', ')});`;
      }

      return '';
    },
    labels() {
      return this.colortable.map(c => c.name)
    }
  }
}
</script>

<style>
.body-simple-colorbar-padding {
  padding-top: 20px !important;
}
</style>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  display: none;
}

.top-colortable {
	z-index: 4
}

.label-pill {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: inherit;
  margin-top: -3px;
}
</style>
