<script setup>
import { Icon } from '@iconify/vue'
</script>

<template>
  <div>
    <div class="w-full sm:px-10 my-3 sm:my-6">
        <div class="relative">
          <div @click="onCopyUrl(shareUrl('social', 'ww_share'))">
            <input type="text"
              class="col-span-6 bg-gray-50 dark:bg-ww-blue-100 border border-gray-300 dark:border-gray-600 text-black dark:text-white opacity-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-4"
              :value="shareUrl('social', 'ww_share')"
              disabled
              readonly>
          </div>
          <button @click="onCopyUrl(shareUrl('social', 'ww_share'))"
            class="absolute end-2.5 top-1/2 -translate-y-1/2 text-gray-900 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-ww-blue-100 rounded-lg py-2 px-2.5 inline-flex items-center justify-center bg-white dark:bg-ww-blue-200 border-gray-200 dark:border-gray-600 border">
              <span v-if="!copied" class="inline-flex items-center">
                  <svg class="w-3 h-3 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                      <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                  </svg>
                  <span class="text-xs font-semibold">Copy</span>
              </span>
              <span v-else class="inline-flex items-center">
                  <svg class="w-3 h-3 text-blue-700 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
                  <span class="text-xs font-semibold text-blue-700">Copied</span>
              </span>
          </button>
        </div>
    </div>
  </div>
  <div>
    <div class="share-network-list sm:pt-5">
      <ShareNetwork
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        :style="{backgroundColor: network.color}"
        :url="shareUrl('social', network.network)"
        :title="sharing.title"
        :description="sharing.description"
        :hashtags="sharing.hashtags"
      >
        <Icon :icon="network.icon" class="ml-1 sm:w-8 sm:h-8 inline" />
        <span>{{ network.name }}</span>
      </ShareNetwork>
      <button v-if="networks.length == 7"
        type="button"
        class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-xs px-2.5 py-1.5 text-center me-2 mb-2 font-bold">
        Show more options...
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      copied: false,
      sharing: {
        url: `${window.location.href}`,
        title: 'WeatherWise',
        description: 'WeatherWise - Extreme weather radar app. Real-time & high-resolution data to protect you, your community, and property from nature\'s fury.',
        hashtags: 'wx'
      },
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'fa6-brands:facebook', color: '#1877f2' },
        { network: 'reddit', name: 'Reddit', icon: 'fa6-brands:reddit-alien', color: '#ff4500' },
        { network: 'telegram', name: 'Telegram', icon: 'fa-brands:telegram-plane', color: '#0088cc' },
        // { network: 'messenger', name: 'Messenger', icon: 'fa6-brands:facebook-messenger', color: '#0084ff' },
        { network: 'twitter', name: 'X/Twitter', icon: 'ri:twitter-x-fill', color: '#000' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fa6-brands:whatsapp', color: '#25d366' },
        { network: 'sms', name: 'SMS', icon: 'fa6-solid:comment-dots', color: '#333333' }
      ]
    }
  },
  methods: {
    onCopyUrl(url) {
      navigator.clipboard.writeText(url)
      .then(() => {
        this.copied = true;

        setTimeout(() => {
          this.copied = false;
        }, 2000)
      }, (err) => {
        console.error('Async: Could not copy text: ', err);
      });
    },
    onShowMoreOptionsClick(e) {
      this.networks.push({ network: 'baidu', name: 'Baidu', icon: 'fa6-solid:paw', color: '#2529d8' })
      this.networks.push({ network: 'buffer', name: 'Buffer', icon: 'fa6-brands:buffer', color: '#323b43' })
      this.networks.push({ network: 'email', name: 'Email', icon: 'fa6-solid:envelope', color: '#333333' })
      this.networks.push({ network: 'evernote', name: 'Evernote', icon: 'fa6-brands:evernote', color: '#2dbe60' })
      this.networks.push({ network: 'flipboard', name: 'Flipboard', icon: 'fa6-brands:flipboard', color: '#e12828' })
      this.networks.push({ network: 'hackernews', name: 'HackerNews', icon: 'fa6-brands:hacker-news', color: '#ff4000' })
      this.networks.push({ network: 'instapaper', name: 'Instapaper', icon: 'fa6-solid:italic', color: '#428bca' })
      this.networks.push({ network: 'line', name: 'Line', icon: 'fa6-brands:line', color: '#00c300' })
      this.networks.push({ network: 'linkedin', name: 'LinkedIn', icon: 'fa6-brands:linkedin', color: '#007bb5' })
      this.networks.push({ network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fa6-brands:odnoklassniki', color: '#ed812b' })
      this.networks.push({ network: 'pinterest', name: 'Pinterest', icon: 'fa6-brands:pinterest', color: '#bd081c' })
      this.networks.push({ network: 'pocket', name: 'Pocket', icon: 'fa6-brands:get-pocket', color: '#ef4056' })
      this.networks.push({ network: 'quora', name: 'Quora', icon: 'fa6-brands:quora', color: '#a82400' })
      this.networks.push({ network: 'skype', name: 'Skype', icon: 'fa6-brands:skype', color: '#00aff0' })
      this.networks.push({ network: 'stumbleupon', name: 'StumbleUpon', icon: 'fa6-brands:stumbleupon', color: '#eb4924' })
      this.networks.push({ network: 'tumblr', name: 'Tumblr', icon: 'fa6-brands:tumblr', color: '#35465c' })
      // this.networks.push({ network: 'viber', name: 'Viber', icon: 'fa6-brands:viber', color: '#59267c' })
      this.networks.push({ network: 'vk', name: 'Vk', icon: 'fa6-brands:vk', color: '#4a76a8' })
      this.networks.push({ network: 'weibo', name: 'Weibo', icon: 'fa6-brands:weibo', color: '#e9152d' })
      this.networks.push({ network: 'wordpress', name: 'Wordpress', icon: 'fa6-brands:wordpress', color: '#21759b' })
      this.networks.push({ network: 'xing', name: 'Xing', icon: 'fa6-brands:xing', color: '#026466' })
      this.networks.push({ network: 'yammer', name: 'Yammer', icon: 'fa6-brands:yammer', color: '#0072c6' })
    },
    shareUrl(medium = null, source = null, campaign = null) {
      const url = new URL(this.sharing.url);
      const searchParams = url.searchParams;

      if (medium) {
        searchParams.append('utm_medium', medium);
      }

      if (source) {
        searchParams.append('utm_source', source);
      }

      if (campaign) {
        searchParams.append('utm_campaign', campaign);
      }

      return url.toString();
    }
  }
}
</script>

<style scoped>
input[disabled] {pointer-events:none}

.share-network-list {
  @apply flex flex-row flex-wrap justify-center max-w-[1000px] mx-auto;
}

a[class^="share-network-"] {
  @apply flex flex-row items-center px-0 py-1 text-white rounded-lg cursor-pointer mr-2.5 mb-2.5 overflow-hidden last:mr-0;
}

a[class^="share-network-"] .fah {
  @apply bg-black/20 p-2.5 flex-none;
}

a[class^="share-network-"] span {
  @apply px-2.5 flex-1 font-medium;
}
</style>
