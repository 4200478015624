<template>
	<div v-if="toas.length > 0" class="relative inline">
    <button type="button" class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl font-bold rounded-lg text-xs sm:text-sm px-2.5 py-1 text-center me-2 mt-2" @click.prevent="toggle($event)"><Icon icon="ph:clock" class="inline mr-1 size-5" />Time Of Arrival <Icon :icon="isOpen ? 'gravity-ui:chevron-up-wide' : 'gravity-ui:chevron-down-wide'" class="inline -mt-[1px] ml-1 font-bold size-5" /></button>
    <Transition name="slide-fade">
      <div v-if="isOpen" v-on-click-outside="onMenuOutsideClick" class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 left-0 top-10 absolute">
        <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
        <li v-for="toa in toas">
          <a href="#" class="block px-4 py-2 hover:bg-gray-100" :class="{
            'font-bold': tropicalStore.toa === toa.id
          }" v-text="toa.name" @click.prevent="onToaOptionClick($event, toa)"></a>
        </li>
      </ul>
      </div>
    </Transition> 
  </div>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components'
</script>

<script>
import { Icon } from '@iconify/vue'

import { useTropicalStore } from '@/stores/tropical'

export default {
  name: 'ToaButton',
  props: ['storm'],
  components: {
    Icon
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    onMenuOutsideClick() {
      setTimeout(() => {
        this.isOpen = false;
      }, 10);
    },
    toggle() {
      if (!this.isOpen) {
        this.open();
      } else {
        this.close();
      }
    },
    async onToaOptionClick(e, toa) {
      // console.log(toa, this.storm)

      this.close();

      this.$parent.$emit('close')

      if(toa.id === this.tropicalStore.toa) {
        window.map.tropical.clearToa();
      }
      else {
        this.tropicalStore.setToa(toa.id);

        const kind = (() => {
          if(toa.id.includes('earliest')) return 'earliest';
          if(toa.id.includes('most_likely')) return 'most_likely';

          throw new Error('Unknown TOA kind');
        })()
        await window.map.tropical.fetchAndRenderToa(this.storm, kind);
      }
    }
  },
  computed: {
    tropicalStore: () => useTropicalStore(),
    toas() {
      const list = [];

      if(this.storm.time_of_arrival_earliest_url !== null) {
        list.push({
          id: `${this.storm.id}-earliest`,
          name: 'Earliest Reasonable'
        })
      }

      if(this.storm.time_of_arrival_most_likely_url !== null) {
        list.push({
          id: `${this.storm.id}-most_likely`,
          name: 'Most Likely'
        })
      }

      return list;
    }
  },
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  display: none;
}
</style>
