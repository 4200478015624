<script setup>
import { ref, onMounted, computed, watch, nextTick } from 'vue';
import { useDebounceFn, useNetwork } from '@vueuse/core'
import { storeToRefs } from 'pinia';

import MapboxMap from '@/components/MapboxMap.vue'
import Colortable from '@/components/Colortable.vue'
import AppLogo from '@/components/AppLogo.vue'
import Outlooks from '@/components/Outlooks.vue'
import Sidebar from '@/components/Sidebar.vue'
import RadarDrawer from '@/components/RadarDrawer.vue'
import SatelliteDrawer from '@/components/SatelliteDrawer.vue'
import RadarValueInspector from '@/components/RadarValueInspector.vue'
import Menu from '../components/Menu.vue'
import OnboardingLayout from '@/components/OnboardingLayout.vue'
import RecordingGlowEffect from '@/components/Extra/RecordingGlowEffect.vue'
import DrawingToolbar from '@/components/DrawingToolbar.vue'
import App from '@/logic/App'

import { useAppStore } from '@/stores/app'
import { useWarningsStore } from '@/stores/warnings';
import { useMesoscaleDiscussionsStore } from '@/stores/mesoscale_discussions';
import { useRadarTowersStore } from '@/stores/radar_towers';
import { useDrawingStore } from '@/stores/drawing';
import { usePreferencesStore } from '@/stores/settings/preferences';

import UrlHash from '@/tools/url-hash'

const drawingStore = useDrawingStore()
const { annotating } = storeToRefs(drawingStore)

const preferencesStore = usePreferencesStore()
const { isLeftHanded } = storeToRefs(preferencesStore)

const currentStep = ref(1)
const onboardingComplete = localStorage.getItem('onboardingComplete')
const hashParams = new UrlHash();
const showOnboarding = ref(onboardingComplete !== 'true' && hashParams.size <= 1)

const isWeatherWiseBridgeDefined = ref(false);

const appStore = useAppStore();
const radarTowerStore = useRadarTowersStore();

// Can't use v-bind because the userback element is outside of the app's root element
function updateUserbackButtonStyle() {
  const styleElement = document.createElement('style');
  styleElement.id = 'userback-button-style';
  styleElement.textContent = `
    .userback-button {
      bottom: 10.5rem !important;
    }
  `;

  if (isLeftHanded.value) {
    styleElement.textContent += `
      .userback-button {
        left: 0.75rem !important;
      }
    `;
  }

  const existingStyle = document.getElementById(styleElement.id);
  if (existingStyle) {
    existingStyle.replaceWith(styleElement);
  } else {
    document.head.appendChild(styleElement);
  }
}

onMounted(() => {
    isWeatherWiseBridgeDefined.value = typeof window.WeatherWiseBridge !== 'undefined';
    updateUserbackButtonStyle();
});

const refreshData = useDebounceFn(() => {
  if(App.isNativeApp()) {
    (async () => {
      try {
        const appMetadata = await (await fetch('/app.json')).json()

        if(appMetadata.hash !== App.hash()) {
          console.log('New version available. Reloading...');

          appStore.updating = true;

          nextTick(() => {
            setTimeout(() => {
              window.location.reload(true);
            }, 800);
          })
        }
      }
      catch(e) {
        console.log('Failed to get app metadata', e)
      }
    })();
  }

  useWarningsStore().load()
  useMesoscaleDiscussionsStore().load()

  if (window.map && window.map.tropical) {
    window.map.tropical.fetchAndRenderStorms()
    window.map.tropical.fetchAndRenderDisturbances()
  }

  if(appStore.mode === 'RADAR') {
    const activeTower = radarTowerStore.activeTower
    const {activeProductCode} = storeToRefs(radarTowerStore)

    if (activeTower) {
      if(! radarTowerStore.isPlaying) {
        window.map.radar.loadRadarData(activeTower, activeProductCode.value)
      }
    }
  }
  else if(appStore.mode === 'SATELLITE') {
    window.map.satellite.changeSatelliteProduct(window.map.satellite.satelliteStore.activeSatelliteId, window.map.satellite.satelliteStore.activeProductCode)
  }
}, 500)

// window.refreshData = refreshData;

const { isOnline } = useNetwork()

watch(isOnline, (online) => {
  if (online) {
    refreshData()
  }
})

watch(isLeftHanded, (leftHanded) => {
  updateUserbackButtonStyle()
})

window.addEventListener('message', (event) => {
  try {
    if (typeof event.data === 'string') {
      const data = JSON.parse(event.data || event.nativeEvent?.data);
      if (data.type === 'REFRESH_DATA') {
        console.log('REFRESH_DATA')
        refreshData()
      }
    }
  } catch (error) {
    console.error(`Error listening for message`, error)
  }
}, true)

const handleOnboardingCompletion = () => {
  showOnboarding.value = false
  console.log('Onboarding completed!')
  // Any additional logic for completion
}

const handleColorUpdate = (color) => {
  window.drawing.setLineColor(color)
}

const handleBrushSizeUpdate = (size) => {
  window.drawing.setLineWidth(size)
}

const handleUndo = () => {
  window.drawing.undo()
}

const handleRedo = () => {
  window.drawing.redo()
}

const handleEraserToggle = (isEnabled) => {
  console.log('Eraser toggled', isEnabled)
  if (isEnabled) {
    window.drawing.enableEraser()
  } else {
    window.drawing.disableEraser()
  }
}

const handleLockToggle = (isLocked) => {
  if (isLocked) {
    window.drawing.lockMap()
  } else {
    window.drawing.unlockMap()
  }
}

const handleDestroy = () => {
  window.drawing.clearAnnotations()
}

const handleClose = () => {
  window.drawing.disable()
}
</script>

<template>
  <MapboxMap />
  <Colortable />
  <AppLogo />
  <Sidebar />

  <Outlooks v-if="appStore.isReady() && appStore.mode === 'OUTLOOKS'" />
  <RadarDrawer v-if="appStore.isReady() && appStore.mode === 'RADAR'" />
  <SatelliteDrawer v-if="appStore.isReady() && appStore.mode === 'SATELLITE'" />
  <RadarValueInspector v-if="radarTowerStore.inspectorActive" />

  <Menu />
  <RecordingGlowEffect />
  <OnboardingLayout
    v-if="showOnboarding"
    :current-step="currentStep"
    @update:step="currentStep = $event"
    @completion="handleOnboardingCompletion"
    @close="showOnboarding = false"
  />
  <DrawingToolbar
    v-if="annotating"
    @update:color="handleColorUpdate"
    @update:brushSize="handleBrushSizeUpdate"
    @undo="handleUndo"
    @redo="handleRedo"
    @toggleEraser="handleEraserToggle"
    @toggleLock="handleLockToggle"
    @destroy="handleDestroy"
    @close="handleClose"
  />
</template>

<style>
html,
body {
  overflow: hidden;
}
body {
  background-color: #5D757E;
}
#userback_button_container .userback-button-e[wstyle=circle] {
  @apply right-3 bottom-28 mb-1.5 !important;
  @apply right-3 sm:bottom-8 sm:mb-auto !important;
}

</style>
