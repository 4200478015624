import { defineStore } from 'pinia'

export const useTropicalStore = defineStore('tropical', {
  state: () => ({
    wsp: null,
    toa: null,
    fwr: null,
    storms: [],
    disturbances: []
  }),

  actions: {
    setWsp(wsp) {
      this.wsp = wsp;
    },

    clearWsp() {
      this.setWsp(null)
    },

    setToa(toa) {
      this.toa = toa;
    },

    clearToa() {
      this.setToa(null)
    },

    setFwr(fwr) {
      this.fwr = fwr;
    },

    clearFwr() {
      this.setFwr(null)
    },

    setStorms(storms) {
      this.storms = storms;
    },

    clearStorms() {
      this.setStorms([])
    },

    setDisturbances(disturbances) {
      this.disturbances = disturbances;
    },

    clearDisturbances() {
      this.setDisturbances([])
    },
  }
})
