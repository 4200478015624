import { colord, extend as colordExtend } from "colord";
import a11yPlugin from "colord/plugins/a11y";
import { useDrawingSettingsStore } from '@/stores/settings/drawing';
import { useDrawingStore } from '@/stores/drawing';

colordExtend([a11yPlugin]);

const preventDefault = (e) => e.preventDefault();

export class MapboxDrawingPlugin {
    constructor(map) {
        this.map = map;
        this.annotations = []; // Stores all completed annotations
        this.undoStack = [];
        this.redoStack = [];
        this.locked = false;

        this.drawingSettingsStore = useDrawingSettingsStore();
        this.drawingStore = useDrawingStore();

        this.setLineColor(this.drawingSettingsStore.getLineColor);

        // Bind event handlers
        this.startDrawing = this.startDrawing.bind(this);
        this.continueDrawing = this.continueDrawing.bind(this);
        this.endDrawing = this.endDrawing.bind(this);

        // Initialise the annotation source and layer
        this.initAnnotationSource();
    }

    initAnnotationSource() {
        if (!this.map.getSource('annotations')) {
            this.map.addSource('annotations', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                }
            });

            // Add the outline layer first (it should be underneath)
            this.map.addLayer({
                id: 'annotations-layer-outline',
                type: 'line',
                source: 'annotations',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': ['get', 'outlineColor'],
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,         // Min zoom level
                        ['*', ['get', 'width'], 0.8], // Scale width by 0.5 at zoom 10
                        15,         // Mid zoom level
                        ['*', ['get', 'width'], 1.5], // Original width at zoom 15
                        20,         // Max zoom level
                        ['*', ['get', 'width'], 3] // Scale width by 2 at zoom 20
                    ],
                    'line-opacity': 1  // Full opacity for outline
                }
            });

            // Main line layer on top
            this.map.addLayer({
                id: 'annotations-layer',
                type: 'line',
                source: 'annotations',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': ['get', 'color'],
                    'line-width': [
                        'interpolate',
                        ['linear'], // Linear interpolation
                        ['zoom'],   // Based on map zoom level
                        10,         // Min zoom level
                        ['*', ['get', 'width'], 0.5], // Scale width by 0.5 at zoom 10
                        15,         // Mid zoom level
                        ['*', ['get', 'width'], 1], // Original width at zoom 15
                        20,         // Max zoom level
                        ['*', ['get', 'width'], 2] // Scale width by 2 at zoom 20
                    ]
                }
            });
        }
    }

    enable() {
        if (!this.drawingStore.isAnnotating) {
            this.drawingStore.startAnnotating();

            // Disable map interactions to focus on drawing
            this.lockMap();

            // Attach drawing event listeners with passive: false to prevent scrolling
            this.map.on('mousedown', this.startDrawing);
            this.map.on('touchstart', this.startDrawing, { passive: false });
        }
    }

    disable() {
        if (this.drawingStore.isAnnotating) {
            this.drawingStore.stopAnnotating();

            // Re-enable map interactions
            this.unlockMap();

            // Remove all event listeners
            this.map.off('mousedown', this.startDrawing);
            this.map.off('touchstart', this.startDrawing);
            this.map.off('mousemove', this.continueDrawing);
            this.map.off('mouseup', this.endDrawing);
            this.map.off('touchmove', this.continueDrawing);
            this.map.off('touchend', this.endDrawing);
        }
    }

    lockMap() {
        this.locked = true;
        this.map.getCanvas().style.cursor = 'crosshair';

        // Prevent default touch behaviour on the canvas
        this.map.getCanvas().addEventListener('touchstart', preventDefault, { passive: false });
        this.map.getCanvas().addEventListener('touchmove', preventDefault, { passive: false });

        // Disable map interactions to focus on drawing
        this.map.scrollZoom.disable();
        this.map.doubleClickZoom.disable();
        this.map.touchZoomRotate.disable();
        this.map.dragPan.disable(); // Disable drag panning to allow drawing
    }

    unlockMap() {
        this.locked = false;
        this.map.getCanvas().style.cursor = '';

        // Remove touch prevention listeners
        this.map.getCanvas().removeEventListener('touchstart', preventDefault);
        this.map.getCanvas().removeEventListener('touchmove', preventDefault);

        // Re-enable map interactions
        this.map.scrollZoom.enable();
        this.map.doubleClickZoom.enable();
        this.map.touchZoomRotate.enable();
        this.map.dragPan.enable(); // Re-enable drag panning
    }

    startDrawing(event) {
        if (!this.drawingStore.isAnnotating || !this.locked) return;

        event.preventDefault();
        this.drawing = true;

        // Handle touch vs mouse events properly
        const point = event.originalEvent?.touches
            ? this.map.unproject(this.getPointFromTouch(event.originalEvent.touches[0]))
            : event.lngLat;

        // Create a new line with the current color and width
        this.currentLine = {
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [[point.lng, point.lat]]
            },
            "properties": {
                "color": this.drawingSettingsStore.getRGBALineColor,
                "outlineColor": this.outlineColor, // Add outline color to properties
                "width": this.drawingSettingsStore.getLineWidth
            }
        };

        // Add listeners for drawing continuation and end
        this.map.on('mousemove', this.continueDrawing);
        this.map.on('mouseup', this.endDrawing);
        this.map.on('touchmove', this.continueDrawing);
        this.map.on('touchend', this.endDrawing);
    }

    continueDrawing(event) {
        if (!this.drawing) return;

        event.preventDefault();

        // Handle touch vs mouse events properly
        const point = event.originalEvent?.touches
            ? this.map.unproject(this.getPointFromTouch(event.originalEvent.touches[0]))
            : event.lngLat;

        this.currentLine.geometry.coordinates.push([point.lng, point.lat]);

        // Update the map with the current line and annotations
        this.updateAnnotations();
    }

    endDrawing() {
        if (this.drawing) {
            this.drawing = false;
            // Save the completed line with its specific properties
            this.annotations.push(this.currentLine);
            this.undoStack.push(this.currentLine);

            // Clear currentLine and update the map
            this.currentLine = null;
            this.updateAnnotations();

            // Remove temporary event listeners
            this.map.off('mousemove', this.continueDrawing);
            this.map.off('mouseup', this.endDrawing);
            this.map.off('touchmove', this.continueDrawing);
            this.map.off('touchend', this.endDrawing);
        }
    }

    updateAnnotations() {
        // Ensure the source exists before attempting to update
        const source = this.map.getSource('annotations');
        if (source) {
            const features = [...this.annotations];
            if (this.currentLine) features.push(this.currentLine);

            source.setData({
                "type": "FeatureCollection",
                "features": features
            });
        }
    }

    undo() {
        if (this.undoStack.length > 0) {
            const lastOperation = this.undoStack.pop();

            // Handle array of annotations (from clear operation)
            if (Array.isArray(lastOperation) && lastOperation.length > 0 && lastOperation[0].type === 'Feature') {
                this.redoStack.push([...this.annotations]);
                this.annotations = [...lastOperation];
            } else {
                // Handle single annotation (normal drawing operation)
                this.redoStack.push(lastOperation);
                this.annotations.pop();
            }

            this.updateAnnotations();
        }
    }

    redo() {
        if (this.redoStack.length > 0) {
            const operation = this.redoStack.pop();

            // Handle array of annotations (from clear operation)
            if (Array.isArray(operation) && operation.length > 0 && operation[0].type === 'Feature') {
                this.undoStack.push([...this.annotations]);
                this.annotations = [...operation];
            } else {
                // Handle single annotation (normal drawing operation)
                this.annotations.push(operation);
                this.undoStack.push(operation);
            }

            this.updateAnnotations();
        }
    }

    setLineColor(color) {
        const mainColor = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3] || 1})`;
        this.drawingSettingsStore.setLineColor(color);

        // Use white outline only for very dark colors (luminance < 0.2)
        const luminance = colord(mainColor).luminance();
        this.outlineColor = luminance < 0.1 ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)';
    }

    setLineWidth(width) {
        this.drawingSettingsStore.setLineWidth(width); // Updates only for new lines
    }

    clearAnnotations() {
        // Store current annotations in the undo stack as a single operation
        if (this.annotations.length > 0) {
            this.undoStack.push([...this.annotations]);
        }

        this.redoStack = [];
        this.annotations = [];
        this.updateAnnotations();
    }

    // Helper method to get point from touch event
    getPointFromTouch(touch) {
        const rect = this.map.getCanvas().getBoundingClientRect();
        return {
            x: touch.clientX - rect.left,
            y: touch.clientY - rect.top
        };
    }
}
