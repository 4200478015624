import { defineStore } from 'pinia'

export const useMesoscaleDiscussionsSettingsStore = defineStore('settings/mesoscale_discussions', {
  state: () => ({
    visible: true,
  }),

  persist: true,

  actions: {
    toggleVisible() {
      this.visible = !this.visible;
    }
  }
})
