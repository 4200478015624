export const beamHeight = (rangeMeters, elevationAngleDegrees) => {
    const earthRadius = 6371000; // Radius of the Earth in meters
    const refractionFactor = 4 / 3; // Refraction factor to account for the atmosphere

    // Convert the elevation angle to radians
    const elevationAngleRadians = elevationAngleDegrees * (Math.PI / 180);

    // Compute the effective Earth radius with refraction
    const effectiveEarthRadius = refractionFactor * earthRadius;

    // Compute the height of the radar beam
    const beamHeight = Math.sqrt(
        Math.pow(rangeMeters, 2) + Math.pow(effectiveEarthRadius, 2) +
        2 * rangeMeters * effectiveEarthRadius * Math.sin(elevationAngleRadians)
    ) - effectiveEarthRadius;

    return beamHeight;
}