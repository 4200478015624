import LocationModule from './modules/LocationModule';
import DownloadModule from './modules/DownloadModule';
import AlertModule from './modules/AlertModule';

const ExpoBridge = {
  location: LocationModule,
  download: DownloadModule,
  alert: AlertModule
};

export default ExpoBridge;
