<template>
    <div class="flex flex-col gap-4">
        <!-- Dark Mode Toggle -->
        <div class="flex items-center justify-between gap-32">
            <span class="text-sm font-medium dark:text-gray-300">Dark Mode</span>
            <div class="flex rounded-md shadow-sm" role="group">
                <button class="px-3 py-1 text-sm border rounded-l-md border-r-0" :class="darkMode === 'on' ? 'bg-blue-700 text-white border-blue-700' : 'bg-white dark:bg-ww-blue border-gray-300 dark:border-gray-600 dark:text-gray-300'"
                    @click="setDarkMode('on')">
                On
              </button>
                <button class="px-3 py-1 text-sm rounded-r-md border" :class="darkMode === 'off' ? 'bg-blue-700 text-white border-blue-700' : 'bg-white dark:bg-ww-blue border-gray-300 dark:border-gray-600 dark:text-gray-300'"
                    @click="setDarkMode('off')">
                Off
              </button>
            </div>
        </div>

        <!-- Left-handed Mode Toggle -->
        <div class="flex items-center justify-between gap-32 md:hidden">
            <span class="text-sm font-medium dark:text-gray-300">Left-handed Mode</span>
            <button class="relative inline-flex h-6 w-11 items-center rounded-full transition-colors" :class="isLeftHanded ? 'bg-blue-700' : 'bg-gray-200'" @click="toggleLeftHanded">
              <span
                class="inline-block h-4 w-4 transform rounded-full bg-white transition-transform"
                :class="isLeftHanded ? 'translate-x-6' : 'translate-x-1'"
              />
            </button>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { usePreferencesStore } from '@/stores/settings/preferences'

const preferencesStore = usePreferencesStore()
const { darkMode, isLeftHanded } = storeToRefs(preferencesStore)

const setDarkMode = (mode) => {
    preferencesStore.setDarkMode(mode)
}

const toggleLeftHanded = () => {
    preferencesStore.toggleLeftHanded()
}
</script>