import { toRaw } from 'vue'
import moment from 'moment'

export const removeExpired = (features, datetimeKey = 'expires_at') => {
  const now = moment.utc()

  return features.filter((f) => {
    const expiresAt = moment.utc(f.properties[datetimeKey])

    return now.isBefore(expiresAt)
  })
}

export const automaticallyRemoveExpired = (store, checkInterval, datetimeKey = 'expires_at') => {
  return setInterval(() => {
    const features = removeExpired(toRaw(store.geojson.features), datetimeKey)
    if (features.length != store.geojson.features) {
      store.geojson.features = features
    }
  }, checkInterval);
}