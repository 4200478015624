<script setup>
import { onUnmounted, ref } from 'vue';
import DotNavigation from './DotNavigation.vue'

defineEmits(['update:step'])

const videoRef = ref(null);

onUnmounted(() => {
  if (videoRef.value) {
    videoRef.value.pause();
    videoRef.value.src = '';
  }
});
</script>

<template>
  <div class="step">
    <div class="video-container">
      <video
        ref="videoRef"
        src="../../assets/onboarding/welcome-video.mp4"
        poster="../../assets/onboarding/welcome-video.png"
        class="video"
        autoplay
        muted
        loop
        playsinline
        preload="metadata"
      ></video>
    </div>
    <h1 class="mt-14 text-center text-[#1e293b] dark:text-white">Welcome to Weather<span class="text-[#F6B91A] font-bold">Wise</span></h1>
    <div class="text-center mt-6">
        <p class="my-6 font-medium text-[#64748b] dark:text-slate-300">Get all the details you need to keep yourself, your loved ones, and your property prepared for whatever nature throws your way.</p>
        <p class="my-4 font-medium text-[#64748b] dark:text-slate-300">We focus on the details that matter. That's why we deliver only the highest quality weather data, straight from the source to your screen.</p>
    </div>
    <DotNavigation :current-step="1" @update:step="$emit('update:step', $event)" />
  </div>
</template>

<style scoped>
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.illustration {
  width: 240px;
  height: 240px;
  margin-bottom: 2rem;
}

h1 {
  font-size: 1.875rem;
  font-weight: 700;
}

.video-container {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  background-color: black;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}
</style>
