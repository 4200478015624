import { default as USA_raw } from './Radar/USA.geojson'
import USA_other from './Radar/USA-other.geojson'
import { default as CAN_raw } from './Radar/CAN.geojson'
import { default as COL_raw } from './Radar/COL.geojson'
import { default as FIN_raw } from './Radar/FIN.geojson'
import { default as ISL_raw } from './Radar/ISL.geojson'
import { default as POL_raw } from './Radar/POL.geojson'

const buildProductList = (list) => {
  return list.map(f => {
    const products = f.properties.products.map(p => {
      return f.properties.elevations.map((e, i) => {
        return `${p}${i}`
      })
    }).flat()

    f.properties.products = products;

    return f;
  });
};

const USA = USA_raw.map(f => {
  f.properties.products.push('PRT');
  return f;
});

// All CAN towers have the same products
// So we can save some time (and bytes) here...
const CAN = buildProductList(CAN_raw.map(f => {
  f.properties.products = ["REF","VEL","CC","ZDR","KDP"];
  return f;
})).map(f => {
  f.properties.products.push('PRT');
  return f;
});

// We can take a shortcut with COL
// Because all products are available for elevations
// So we can compute the final product list
const COL = buildProductList(COL_raw);

const FIN = FIN_raw.map(f => {
  const products = f.properties.products.map(p => {
    return f.properties.elevations.map((e, i) => {
      return `${p}${i}`
    })
  }).flat()

  f.properties.products = products;

  return f;
});

const POL = buildProductList(POL_raw);

const ISL = buildProductList(ISL_raw)

const features = [USA, USA_other, COL, CAN, FIN, ISL].flat();

// In dev, let's check for duplicate towers
if(import.meta.env.DEV) {
  const towerIds = features.map(f => f.properties.id);
  const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
  const duplicates = [...new Set(findDuplicates(towerIds))]

  if(duplicates.length > 0) {
    console.error("Duplicate radar towers found!", duplicates)
  }
}

export default Object.freeze({
  type: "FeatureCollection",
  features
})