//@ts-check

/**
 * Check if the input is a number matrix
 * @param {any} matrix
 * @returns {matrix is number[][]}
 */
export function isNumberMatrix(matrix) {
    return Array.isArray(matrix) && matrix.every(row => Array.isArray(row) && row.every(item => typeof item === 'number'));
}

/**
 * Check if the input is a string array
 * @param {any} array
 * @returns {array is string[]}
 */
export function isStringArray(array) {
    return Array.isArray(array) && array.every(item => typeof item === 'string');
}
