import moment from 'moment'
import { toCanvas } from 'html-to-image';
import { useRecordingStore } from '@/stores/recording'

export default class Screenshot {
  constructor(map) {
    this.map = map
    this.canvas = map.getCanvas();

    this.recordingStore = useRecordingStore()
  }

  async take() {
    this.recordingStore.startScreenshot()
    const dt = moment.utc().format("YYYY_MM_DD_HH_mm_ss");
    const filename = `WeatherWise_${dt}`;

    // Create a new canvas to draw both the map and the watermark
    const newCanvas = document.createElement('canvas');
    newCanvas.width = this.canvas.width;
    newCanvas.height = this.canvas.height;
    const ctx = newCanvas.getContext('2d');

    // Draw the map canvas
    ctx.drawImage(this.canvas, 0, 0);

    const watermark = new Image();
    watermark.src = 'ww-watermark-300.png'; // Replace with your watermark image path
    await watermark.decode(); // Ensure the image is loaded before using it

    // Add watermark to the top right corner
    const watermarkWidth = 300; // Adjust as needed
    const watermarkHeight = 150; // Adjust as needed
    const marginTop = 70;
    const marginRight = 10;
    ctx.drawImage(
      watermark,
      newCanvas.width - watermarkWidth - marginRight,
      marginTop,
      watermarkWidth,
      watermarkHeight
    );

    const overlays = document.querySelectorAll('.map-overlay');
    for (const overlay of overlays) {
      const overlayCanvas = await toCanvas(overlay);
      ctx.drawImage(overlayCanvas, overlay.offsetLeft, overlay.offsetTop);
    }

    this.recordingStore.stopScreenshot()

    setTimeout(() => {
      const mimeType = 'image/png';
      newCanvas.toBlob((blob) => {
        navigator._download.blob(blob, mimeType);
      }, mimeType);
    }, 1000)
  }
}
