<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { vOnClickOutside } from '@vueuse/components'
import { shareMap, screenshot, recordGif, recordVideo, draw, locateMe } from '@/logic/MenuActions'
import { isLocating } from '@/tools/locationUtils';
import { ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRecordingStore } from '@/stores/recording';
import { useDrawingSettingsStore } from '@/stores/settings/drawing';
import { usePreferencesStore } from '@/stores/settings/preferences';
import { useAppStore } from '@/stores/app';

const appStore = useAppStore()

const drawingStore = useDrawingSettingsStore()
const { annotating } = storeToRefs(drawingStore)

const preferencesStore = usePreferencesStore()
const { isLeftHanded } = storeToRefs(preferencesStore)

// console.log(`isLeftHanded: ${isLeftHanded.value}`)

const menuButton = ref<HTMLButtonElement | null>(null)
const { menu_open: menuOpen } = storeToRefs(appStore)
const shareMenuOpen = ref(false)
const { recording: isRecording, screenshot: isTakingScreenshot, recording_method } = storeToRefs(useRecordingStore())

const closeMenu = () => {
    appStore.toggleMenu(false)
    shareMenuOpen.value = false
}

const toggleMenu = () => {
    appStore.toggleMenu()
    shareMenuOpen.value = false
}

const stopRecording = () => {
    if(recording_method.value === 'MP4') {
        setTimeout(() => window.mp4.stop(), 1000)
    } else {
        window.gif.stop()
    }
    useRecordingStore().stop()
}

const startRecordingMp4 = () => {
    if (isRecording.value || isTakingScreenshot.value) return;

    menuOpen.value = false;
    recordVideo()
}

const startRecordingGif = () => {
    if (isRecording.value || isTakingScreenshot.value) return;

    menuOpen.value = false;
    recordGif()
}
</script>

<template>
    <div>
        <Transition name="slide-fade">
            <div v-if="menuOpen"
                 v-on-click-outside="[closeMenu, { ignore: [menuButton] }]"
                 class="absolute bottom-[7.5rem] right-3 w-[50vw] max-w-[calc(100vw-2rem)] z-[26] md:w-64 md:top-20 md:left-3 md:bottom-auto bg-white dark:bg-ww-blue rounded-lg shadow ring-1 ring-black/5 dark:ring-white/10 focus:outline-none flex flex-col-reverse md:flex-col"
                 :class="{ 'left-3 right-auto': isLeftHanded }">
                <div class="py-1 flex flex-col-reverse md:flex-col" role="none">
                    <div class="relative">
                        <!-- Example of a menu item with a submenu -->
                        <button v-if="false" @click.prevent="shareMenuOpen = !shareMenuOpen"
                                class="w-full group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 active:text-gray-900 outline-none"
                                role="menuitem">
                            <Icon icon="fluent:share-ios-24-filled" class="mr-3 size-5 text-gray-400" />
                            <span class="">Share</span>
                            <Icon :icon="shareMenuOpen ? 'mingcute:up-line' : 'mingcute:down-line'"
                                  class="ml-auto size-4 text-gray-400" />
                        </button>

                        <Transition name="slide">
                            <div v-if="shareMenuOpen"
                                 class="bg-gray-50 dark:bg-ww-blue-200 py-1">
                                <a href="#"
                                   class="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 pl-12">
                                    Copy Link
                                </a>
                                <a href="#"
                                   class="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 pl-12">
                                    Email
                                </a>
                                <a href="#"
                                   class="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 pl-12">
                                    Social Media
                                </a>
                            </div>
                        </Transition>
                    </div>

                    <a href="#" @click.prevent="menuOpen = false; screenshot()"
                       class="group flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none">
                        <Icon icon="ion:camera" class="mr-3 size-5" />
                        Screenshot
                    </a>
                    <a href="#" @click.prevent="startRecordingGif" class="group flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 active:bg-gray-100 dark:active:bg-ww-blue-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1" id="menu-item-2">
                        <!-- Active: "text-gray-500", Not Active: "" -->
                        <Icon icon="teenyicons:gif-outline" class="mr-3 size-5" />
                        Record GIF
                    </a>
                    <a href="#" @click.prevent="startRecordingMp4" class="group flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 active:bg-gray-100 dark:active:bg-ww-blue-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1" id="menu-item-2">

                        <!-- Active: "text-gray-500", Not Active: "" -->
                        <Icon icon="fluent:video-28-filled" class="mr-3 size-5" />
                        Record MP4
                    </a>
                    <a href="#" @click.prevent="menuOpen = false; shareMap()" class="group flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1" id="menu-item-2">
                        <!-- Active: "text-gray-500", Not Active: "" -->
                        <Icon icon="fluent:share-ios-24-filled" class="mr-3 size-5" />
                        Share Map
                    </a>
                </div>
                <hr class="dark:border-gray-600">
                <div class="py-1 flex flex-col-reverse md:flex-col" role="none">
                    <button :disabled="isLocating" @click.prevent="async () => { await locateMe(); menuOpen = false; }" class="group flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1" id="menu-item-2">
                        <!-- Active: "text-gray-500", Not Active: "" -->
                        <Icon icon="solar:map-arrow-up-outline" class="mr-3 size-5" />
                        <span v-if="!isLocating">Locate Me</span>
                        <span v-else>Locating...</span>
                    </button>
                    <a href="#" @click.prevent="menuOpen = false; draw()" class="group flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1" id="menu-item-3">
                        <!-- Active: "text-gray-500", Not Active: "" -->
                        <Icon icon="mingcute:pencil-3-line" class="mr-3 size-5" />
                        <span v-if="!annotating">Draw</span>
                        <span v-else>Stop Drawing</span>
                    </a>
                </div>
            </div>
        </Transition>
        <div>
            <button v-if="!isRecording" @click.prevent="toggleMenu"
                    ref="menuButton"
                    type="button"
                    class="bottom-16 right-3 md:top-4 md:right-auto md:left-3 absolute w-12 h-12 rounded-full transition-all duration-300 focus:outline-none primary-button active:scale-90 z-[19]"
                    :class="{ 'left-3 right-auto': isLeftHanded }">
                <Icon v-if="!menuOpen" icon="mingcute:menu-line" class="inline size-8" />
                <Icon v-else icon="clarity:window-close-line" class="inline size-8" />
            </button>
            <button @click.prevent="stopRecording()"
                    v-else
                    class="bottom-14 right-3 md:top-4 md:right-auto md:left-3 absolute w-12 h-12 rounded-full transition-all duration-300 focus:outline-none bg-red-700 hover:bg-red-800 text-white active:scale-90 z-[19]"
                    :class="{ 'left-3 right-auto': isLeftHanded }">
                <Icon icon="bi:stop-fill" class="inline size-8" />
            </button>
        </div>
    </div>
</template>

<style scoped>
.primary-button {
    background-color: #F6B91A;
    color: #17274F;
    transform-origin: center;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

:root.dark .primary-button {
    background-color: #F6B91A;
    color: #17274F;
}

.primary-button:active {
    background-color: #17274F;
    color: #F6B91A;
    box-shadow: 0 0 0 2px #17274F, 0 0 0 4px #F6B91A;
    transform: scale(0.9);
}

:root.dark .primary-button:active {
    background-color: #F6B91A;
    color: #17274F;
    box-shadow: 0 0 0 2px #F6B91A, 0 0 0 4px #17274F;
}

@media (hover: hover) and (pointer: fine) {
    .primary-button:hover {
        background-color: #17274F;
        color: #F6B91A;
    }

    .primary-button:hover {
        background-color: #F6B91A;
        color: #17274F;
    }
}

/* Slide fade transition */
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform-origin: top;
}

.slide-fade-enter-from {
    transform: translateY(10px);
    opacity: 0;
}

.slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
}

/* For the share submenu, we want a slightly softer animation */
.relative .slide-fade-enter-active,
.relative .slide-fade-leave-active {
    transition: all 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.relative .slide-fade-enter-from {
    transform: translateY(5px);
    opacity: 0;
}

.relative .slide-fade-leave-to {
    transform: translateY(5px);
    opacity: 0;
}

/* Accordion slide transition */
.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform-origin: top;
}

.slide-enter-from {
    transform: scaleY(0);
    opacity: 0;
}

.slide-leave-to {
    transform: scaleY(0);
    opacity: 0;
}
</style>