const ALLOWED_PRODUCTS = ['BR', 'BV', 'CC', 'SW'];

/**
 * Parses .pal file content based on specific format rules.
 * @param {string} palContent - The contents of a .pal file.
 * @returns {Object} - Parsed data organized by categories (e.g., product, units, colors).
 */
export function parsePalText(palContent) {
  if(palContent.length > 4096 * 2) {
    throw new Error(`Pal contents too big. Character length exceeds 4096.`);
  }

  const data = {
    product: null,
    units: null,
    scale: null,
    offset: null,
    step: null,
    rf: null,
    colors: []
  };

  // Regular expression patterns with case-insensitive flag (`i`) added
  const patterns = {
    product: /^Product:\s*(.+)/i,
    units: /^Units:\s*(.+)/i,
    scale: /^Scale:\s*(-?\d+(\.\d+)?)/i,
    offset: /^Offset:\s*(-?\d+(\.\d+)?)/i,
    step: /^Step:\s*(-?\d+(\.\d+)?)/i,
    rf: /^RF:\s*(\d+)\s+(\d+)\s+(\d+)/i,
    color: /^Color:\s*(-?\d+(\.\d+)?)\s+(\d+)\s+(\d+)\s+(\d+)(?:\s+(\d+)\s+(\d+)\s+(\d+))?/i,
    color4: /^Color4:\s*(-?\d+(\.\d+)?)\s+(\d+)\s+(\d+)\s+(\d+)\s+(\d+)(?:\s+(\d+)\s+(\d+)\s+(\d+)\s+(\d+))?/i,
    solidColor: /^SolidColor:\s*(-?\d+(\.\d+)?)\s+(\d+)\s+(\d+)\s+(\d+)/i,
    solidColor4: /^SolidColor4:\s*(-?\d+(\.\d+)?)\s+(\d+)\s+(\d+)\s+(\d+)\s+(\d+)(?:\s+(\d+)\s+(\d+)\s+(\d+)\s+(\d+))?/i
  };

  // Split content by new lines and process each line
  const lines = palContent.trim().split(/\r?\n/);

  lines.forEach((line) => {
    line = line.trim();

    if (line.startsWith('#') || !line) return; // Ignore comments and empty lines

    for (const [key, pattern] of Object.entries(patterns)) {
      const match = line.match(pattern);
      if (match) {
        switch (key) {
          case 'product':
            data.product = match[1].trim().toUpperCase();
            break;
          case 'units':
            data.units = match[1].trim().toUpperCase();
            break;
          case 'scale':
            data.scale = parseFloat(match[1]);
            if(isNaN(data.scale)) {
              data.scale = null;
            }
            break;
          case 'offset':
            data.offset = parseFloat(match[1]);
            if(isNaN(data.offset)) {
              data.offset = null;
            }
            break;
          case 'step':
            data.step = parseFloat(match[1]);
            if(isNaN(data.step)) {
              data.step = null;
            }
            break;
          case 'rf':
            data.rf = [ +match[1], +match[2], +match[3] ];
            break;
          case 'color':
            data.colors.push({
              value: parseFloat(match[1]),
              color1: [ +match[3], +match[4], +match[5] ],
              color2: match[6] ? [ +match[6], +match[7], +match[8] ] : null
            });
            break;
          case 'color4':
            data.colors.push({
              value: parseFloat(match[1]),
              color1: [ +match[3], +match[4], +match[5], +match[6] ],
              color2: match[7] ? [ +match[7], +match[8], +match[9], +match[10] ] : null
            });
            break;
          case 'solidColor':
            data.colors.push({
              value: parseFloat(match[1]),
              color1: [ +match[3], +match[4], +match[5] ],
              color2: [ +match[3], +match[4], +match[5] ]
            });
            break;
          case 'solidColor4':
            data.colors.push({
              value: parseFloat(match[1]),
              color1: [ +match[3], +match[4], +match[5], +match[6] ],
              color2: [ +match[3], +match[4], +match[5], +match[6] ]
            });
            break;
          default:
            console.warn(`Unrecognized line: "${line}"`);
        }
        return; // Stop checking other patterns if a match is found
      }
    }
  });

  if(data.product === null) {
    throw new Error(`Unable to detect product: ${palContent}`);
  }
  if(! ALLOWED_PRODUCTS.includes(data.product)) {
    throw new Error(`Unsupported product: ${data.product}`);
  }

  if(data.colors.length < 2) {
    throw new Error('Color Table must have atleast 2 colors');
  }

  // Apply scale and offset (if they're defined)
  data.colors = data.colors.map(c => {
    if(data.scale !== null) {
      c.value = (1 / data.scale) * c.value;
    }
    if(data.offset !== null) {
      c.value+=data.offset
    }
    return c;
  });

  // Ensure all the colors are ordered by value
  data.colors.sort((a, b) => {
    return a.value - b.value;
  });

  return data;
}

export function parsePal(content, step = 1) {
  const pal = parsePalText(content)

  const generateMap = (colorPoints, step = 1) => {
    const minValue = colorPoints[0].value;
    const maxValue = colorPoints[colorPoints.length - 1].value;
    const distance = parseInt((maxValue - minValue) / step);

    // console.log(minValue, maxValue, distance, step);

    const colors = [];

    function interpolateColor(color1, color2, factor) {
      return color1.map((color, i) => Math.round(color + (color2[i] - color) * factor));
    }

    for (let i = 0; i <= distance; i++) {
      const value = minValue + i * step;
      for (let j = 0; j < colorPoints.length - 1; j++) {
        if (colorPoints[j].value <= value && value <= colorPoints[j + 1].value) {
          let delta = colorPoints[j + 1].value - colorPoints[j].value;
          if(delta === 0) {
            delta = 0.00001;
          }
          const t = (value - colorPoints[j].value) / delta;
          let color;

          // console.log(colorPoints[j + 1].value, colorPoints[j].value)

          // console.log(value, value - colorPoints[j].value, colorPoints[j + 1].value - colorPoints[j].value)

          // If there is no color2, then interpolate to the next color
          if(colorPoints[j].color2 === null) {
            // console.log(colorPoints[j].color1, colorPoints[j + 1].color1, t)
            color = interpolateColor(colorPoints[j].color1, colorPoints[j + 1].color1, t)
          }
          // If there is color2, then interpolate from color1 to color2
          else {
            color = interpolateColor(colorPoints[j].color1, colorPoints[j].color2, t)
          }

          colors.push(color);
          break;
        }
      }
    }

    return {
        pal,
        colors,
        min: minValue,
        max: maxValue
    }
  }

  return generateMap(pal.colors, step);
}
