import { toRaw } from 'vue'
import { defineStore } from 'pinia';

interface RegionSettings {
  border: {
    thickness: number;
    color: string;
  };
  label: {
    fontSize: number;
    color: string;
    density: number;
  };
}

interface MapSettings {
  country: RegionSettings;
  state: RegionSettings;
  county: RegionSettings;
}

const defaults = {
  country: {
    border: {
      thickness: 2,
      color: '#A09DAF',
    },
    label: {
      fontSize: 30,
      color: '#FFFFFF',
      density: 4,
    }
  },
  state: {
    border: {
      thickness: 1.5,
      color: '#95939F',
    },
    label: {
      fontSize: 30,
      color: '#FFFFFF',
      density: 3,
    }
  },
  county: {
    border: {
      thickness: 1,
      color: '#ADABBA',
    },
    label: {
      fontSize: 10,
      color: '#FFFFFF',
      density: 6,
    }
  },
  settlement: {
    label: {
      fontSize: 30,
      color: '#FFFFFF',
      density: 6,
    }
  }
};

export const useMapSettingsStore = defineStore('settings/map', {
  state: (): MapSettings => (JSON.parse(JSON.stringify(defaults))),

  getters: {
    anyChanged: (state) => {
      return JSON.stringify(toRaw(state.country)) !== JSON.stringify(defaults.country) ||
              JSON.stringify(toRaw(state.state)) !== JSON.stringify(defaults.state) ||
              JSON.stringify(toRaw(state.county)) !== JSON.stringify(defaults.county) ||
              JSON.stringify(toRaw(state.settlement)) !== JSON.stringify(defaults.settlement);
    }
  },

  persist: true,

  actions: {
    updateRegionSettings(region: keyof MapSettings, settings: RegionSettings) {
      this[region] = settings;
    },
    
    updateBorderSettings(region: keyof MapSettings, settings: RegionSettings['border']) {
      this[region].border = settings;
    },
    
    updateLabelSettings(region: keyof MapSettings, settings: RegionSettings['label']) {
      this[region].label = settings;
    },

    reset() {
      this.$state = JSON.parse(JSON.stringify(defaults));

      this.applyToMapStyle()
    },

    getMapStyleMods() {
      const mods = [];

      // Country
      mods.push({
        kind: 'paint',
        layer: 'admin-0-boundary',
        property: 'line-color',
        value: this.country.border.color
      })
      mods.push({
        kind: 'paint',
        layer: 'admin-0-boundary',
        property: 'line-width',
        value: [
          "interpolate",
          [
            "linear"
          ],
          [
            "zoom"
          ],
          3,
          this.country.border.thickness/2,
          10,
          this.country.border.thickness
        ]
      })
      mods.push({
        kind: 'paint',
        layer: 'admin-0-boundary-all',
        property: 'line-color',
        value: this.country.border.color
      })
      mods.push({
        kind: 'paint',
        layer: 'admin-0-boundary-all',
        property: 'line-width',
        value: [
          "interpolate",
          [
            "linear"
          ],
          [
            "zoom"
          ],
          3,
          this.country.border.thickness/2,
          10,
          this.country.border.thickness
        ]
      })
      mods.push({
        kind: 'layout',
        layer: 'country-label',
        property: 'text-size',
        value: [
          "interpolate",
          [
            "cubic-bezier",
            0.2,
            0,
            0.7,
            1
          ],
          [
            "zoom"
          ],
          1,
          [
            "step",
            [
              "get",
              "symbolrank"
            ],
            this.country.label.fontSize/2,
            4,
            this.country.label.fontSize/4,
            5,
            this.country.label.fontSize/4
          ],
          9,
          [
            "step",
            [
              "get",
              "symbolrank"
            ],
            this.country.label.fontSize,
            4,
            this.country.label.fontSize*0.75,
            5,
            this.country.label.fontSize*0.75
          ]
        ]
      })
      mods.push({
        kind: 'paint',
        layer: 'country-label',
        property: 'text-color',
        value: this.country.label.color
      })

      // State
      mods.push({
        kind: 'paint',
        layer: 'admin-1-boundary',
        property: 'line-color',
        value: this.state.border.color
      })
      mods.push({
        kind: 'paint',
        layer: 'admin-1-boundary',
        property: 'line-width',
        value: [
          "interpolate",
          [
            "linear"
          ],
          [
            "zoom"
          ],
          3,
          this.state.border.thickness/2,
          10,
          this.state.border.thickness
        ]
      })
      mods.push({
        kind: 'layout',
        layer: 'state-label',
        property: 'text-size',
        value: [
          "interpolate",
          [
            "cubic-bezier",
            0.85,
            0.7,
            0.65,
            1
          ],
          [
            "zoom"
          ],
          4,
          [
            "step",
            [
              "get",
              "symbolrank"
            ],
            this.state.label.fontSize/2,
            6,
            this.state.label.fontSize/3,
            7,
            this.state.label.fontSize/3
          ],
          9,
          [
            "step",
            [
              "get",
              "symbolrank"
            ],
            this.state.label.fontSize,
            6,
            this.state.label.fontSize*0.75,
            7,
            this.state.label.fontSize*0.75
          ]
        ]
      })
      mods.push({
        kind: 'paint',
        layer: 'state-label',
        property: 'text-color',
        value: this.state.label.color
      })

      // County
      mods.push({
        kind: 'paint',
        layer: 'admin-2-boundary',
        property: 'line-color',
        value: this.county.border.color
      })
      mods.push({
        kind: 'paint',
        layer: 'admin-2-boundary',
        property: 'line-width',
        value: [
          "interpolate",
          [
            "linear"
          ],
          [
            "zoom"
          ],
          3,
          this.county.border.thickness/2,
          10,
          this.county.border.thickness
        ]
      })
      mods.push({
        kind: 'paint',
        layer: 'settlement-major-label',
        property: 'text-color',
        value: this.settlement.label.color
      })
      mods.push({
        kind: 'paint',
        layer: 'settlement-minor-label',
        property: 'text-color',
        value: this.settlement.label.color
      })
      
      mods.push({
        kind: 'layout',
        layer: 'settlement-major-label',
        property: 'text-size',
        value: [
          "interpolate",
          [
            "cubic-bezier",
            0.2,
            0,
            0.9,
            1
          ],
          [
            "zoom"
          ],
          8,
          [
            "step",
            [
              "get",
              "symbolrank"
            ],
            this.settlement.label.fontSize/2,
            9,
            this.settlement.label.fontSize/2,
            10,
            this.settlement.label.fontSize/2
          ],
          15,
          [
            "step",
            [
              "get",
              "symbolrank"
            ],
            this.settlement.label.fontSize,
            9,
            this.settlement.label.fontSize*0.8,
            10,
            this.settlement.label.fontSize*0.7,
            11,
            this.settlement.label.fontSize*0.65,
            12,
            this.settlement.label.fontSize*0.6,
            13,
            this.settlement.label.fontSize*0.55,
            15,
            this.settlement.label.fontSize*0.5
          ]
        ]
      })
      mods.push({
        kind: 'layout',
        layer: 'settlement-minor-label',
        property: 'text-size',
        value: [
          "interpolate",
          [
            "cubic-bezier",
            0.2,
            0,
            0.9,
            1
          ],
          [
            "zoom"
          ],
          3,
          [
            "step",
            [
              "get",
              "symbolrank"
            ],
            13.200000000000001,
            9,
            12.100000000000001,
            10,
            11.55,
            12,
            10.450000000000001,
            14,
            9.350000000000001,
            16,
            7.15,
            17,
            4.4
          ],
          13,
          [
            "step",
            [
              "get",
              "symbolrank"
            ],
            this.settlement.label.fontSize*1.4*1,
            9,
            this.settlement.label.fontSize*1.4*0.9,
            10,
            this.settlement.label.fontSize*1.4*0.8,
            11,
            this.settlement.label.fontSize*1.4*0.7,
            12,
            this.settlement.label.fontSize*1.4*0.6,
            13,
            this.settlement.label.fontSize*1.4*0.5,
            15,
            this.settlement.label.fontSize*1.4*0.4
          ]
        ]
      })
      

      mods.push({
        kind: 'filter',
        layer: 'settlement-major-label',
        value: [
          "all",
          [
            "<=",
            [
              "get",
              "filterrank"
            ],
            this.settlement.label.density
          ],
          [
            "match",
            [
              "get",
              "class"
            ],
            "settlement",
            [
              "match",
              [
                "get",
                "worldview"
              ],
              [
                "all",
                "US"
              ],
              true,
              false
            ],
            "disputed_settlement",
            [
              "all",
              [
                "==",
                [
                  "get",
                  "disputed"
                ],
                "true"
              ],
              [
                "match",
                [
                  "get",
                  "worldview"
                ],
                [
                  "all",
                  "US"
                ],
                true,
                false
              ]
            ],
            false
          ],
          [
            "step",
            [
              "zoom"
            ],
            false,
            8,
            [
              "<",
              [
                "get",
                "symbolrank"
              ],
              11
            ],
            10,
            [
              "<",
              [
                "get",
                "symbolrank"
              ],
              12
            ],
            11,
            [
              "<",
              [
                "get",
                "symbolrank"
              ],
              13
            ],
            12,
            [
              "<",
              [
                "get",
                "symbolrank"
              ],
              15
            ],
            13,
            [
              ">=",
              [
                "get",
                "symbolrank"
              ],
              11
            ],
            14,
            [
              ">=",
              [
                "get",
                "symbolrank"
              ],
              13
            ]
          ]
        ]
      })
      mods.push({
        kind: 'filter',
        layer: 'settlement-minor-label',
        value: [
          "all",
          [
            "<=",
            [
              "get",
              "filterrank"
            ],
            this.settlement.label.density
          ],
          [
            "match",
            [
              "get",
              "class"
            ],
            "settlement",
            [
              "match",
              [
                "get",
                "worldview"
              ],
              [
                "all",
                "US"
              ],
              true,
              false
            ],
            "disputed_settlement",
            [
              "all",
              [
                "==",
                [
                  "get",
                  "disputed"
                ],
                "true"
              ],
              [
                "match",
                [
                  "get",
                  "worldview"
                ],
                [
                  "all",
                  "US"
                ],
                true,
                false
              ]
            ],
            false
          ],
          [
            "step",
            [
              "zoom"
            ],
            true,
            8,
            [
              ">=",
              [
                "get",
                "symbolrank"
              ],
              11
            ],
            10,
            [
              ">=",
              [
                "get",
                "symbolrank"
              ],
              12
            ],
            11,
            [
              ">=",
              [
                "get",
                "symbolrank"
              ],
              13
            ],
            12,
            [
              ">=",
              [
                "get",
                "symbolrank"
              ],
              15
            ],
            13,
            [
              ">=",
              [
                "get",
                "symbolrank"
              ],
              11
            ],
            14,
            [
              ">=",
              [
                "get",
                "symbolrank"
              ],
              13
            ]
          ]
        ]
      })

      return mods;
    },

    applyToMapStyle() {
      const mods = this.getMapStyleMods();
      mods.forEach(mod => {
        if(mod.kind === 'paint') {
          window.map.setPaintProperty(mod.layer, mod.property, mod.value);
        }
        else if(mod.kind === 'layout') {
          window.map.setLayoutProperty(mod.layer, mod.property, mod.value);
        }
        else if(mod.kind === 'filter') {
          window.map.setFilter(mod.layer, mod.value);
        }
      });
    }
  }
});