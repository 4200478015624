import moment from 'moment'

export default {
  // Convective/severe
  'USA/SPC/CONVECTIVE/CATEGORICAL': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
  ],
  'USA/SPC/CONVECTIVE/TORNADO': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
  ],
  'USA/SPC/CONVECTIVE/WIND': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
  ],
  'USA/SPC/CONVECTIVE/HAIL': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
  ],
  'USA/SPC/CONVECTIVE/PROB': [
    // {
    //   id: 'DAY_3',
    //   name: 'Day 3'
    // },
    {
      id: 'DAY_4',
      name: 'Day 4'
    },
    {
      id: 'DAY_5',
      name: 'Day 5'
    },
    {
      id: 'DAY_6',
      name: 'Day 6'
    },
    {
      id: 'DAY_7',
      name: 'Day 7'
    },
    {
      id: 'DAY_8',
      name: 'Day 8'
    },
  ],
  'USA/SPC/THUNDERSTORM/THUNDERSTORM': () => {
    const now = moment.utc();

    const nowYear = now.format('YYYY');
    const nowMonth = now.format('MM');
    const nowDay = now.format('DD');

    const t00 = moment.utc(`${nowYear}-${nowMonth}-${nowDay} 00:00:00`).local().format('HH');
    const t04 = moment.utc(`${nowYear}-${nowMonth}-${nowDay} 04:00:00`).local().format('HH');
    const t12 = moment.utc(`${nowYear}-${nowMonth}-${nowDay} 12:00:00`).local().format('HH');
    const t16 = moment.utc(`${nowYear}-${nowMonth}-${nowDay} 16:00:00`).local().format('HH');
    const t20 = moment.utc(`${nowYear}-${nowMonth}-${nowDay} 20:00:00`).local().format('HH');
    
    if(now.isAfter(moment.utc('21:00', 'hh:mm'))) {
      return [{
        id: '0000',
        name: `${t00}-${t04}:00`
      },{
        id: '0400',
        name: `${t04}-${t12}:00`
      }];
    }
    else if(now.isAfter(moment.utc('17:00', 'hh:mm'))) {
      return [{
        id: '2000',
        name: `${t20}-${t00}:00`
      },{
        id: '0000',
        name: `${t00}-${t04}:00`
      },{
        id: '0400',
        name: `${t04}-${t12}:00`
      }];
    }
    else if(now.isAfter(moment.utc('13:00', 'hh:mm'))) {
      return [{
        id: '1600',
        name: `${t16}-${t20}:00`
      },{
        id: '2000',
        name: `${t20}-${t00}:00`
      },{
        id: '0000',
        name: `${t00}-${t04}:00`
      }];
    }
    else if(now.isAfter(moment.utc('06:00', 'hh:mm'))) {
      return [{
        id: '1200',
        name: `${t12}-${t16}:00`
      },{
        id: '1600',
        name: `${t16}-${t20}:00`
      },{
        id: '2000',
        name: `${t20}-${t00}:00`
      }];
    }
    else if(now.isAfter(moment.utc('01:30', 'hh:mm'))) {
      return [{
        id: '0400',
        name: `${t04}-${t12}:00`
      }];
    }

    return [];
  },
  // Fire weather
  'USA/SPC/FIRE/DRYT': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
  ],
  'USA/SPC/FIRE/WINDRH': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
  ],
  // CPC climate
  'USA/CPC/CLIMATE/TEMP': [
    {
      id: '6_10_DAY',
      name: '6-10 Day'
    },
    {
      id: '8_14_DAY',
      name: '8-14 Day'
    },
    {
      id: '3_4_WEEKS',
      name: 'Weeks 3-4'
    },
    {
      id: '1_MONTH',
      name: 'Monthly (1 Month)'
    },
    {
      id: '3_MONTH',
      name: 'Seasonal (3 Month)'
    },
  ],
  'USA/CPC/CLIMATE/PRECIP': [
    {
      id: '6_10_DAY',
      name: '6-10 Day'
    },
    {
      id: '8_14_DAY',
      name: '8-14 Day'
    },
    {
      id: '3_4_WEEKS',
      name: 'Weeks 3-4'
    },
    {
      id: '1_MONTH',
      name: 'Monthly (1 Month)'
    },
    {
      id: '3_MONTH',
      name: 'Seasonal (3 Month)'
    },
  ],
  // CPC drought
  'USA/CPC/DROUGHT/DROUGHT': [
    {
      id: 'CURRENT',
      name: 'Current'
    },
    {
      id: 'MONTHLY',
      name: 'Monthly'
    },
    {
      id: 'SEASONAL',
      name: 'Seasonal'
    },
  ],
  // WPC Excessive Rainfall
  'USA/WPC/EXCESSIVE_RAINFALL/RAINFALL': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
    {
      id: 'DAY_4',
      name: 'Day 4'
    },
    {
      id: 'DAY_5',
      name: 'Day 5'
    },
  ],
  // WPC
  'USA/WPC/QPF/DAILY': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
  ],
  'USA/WPC/QPF/MULTI_DAY': [
    {
      id: 'DAY_1_2',
      name: 'Day 1-2'
    },
    {
      id: 'DAY_1_3',
      name: 'Day 1-3'
    },
    {
      id: 'DAY_1_5',
      name: 'Day 1-5'
    },
    {
      id: 'DAY_1_7',
      name: 'Day 1-7'
    },
  ],
  'USA/WPC/QPF/DAY_1_6_HOURS': [
    {
      id: '00',
      name: '00z'
    },
    {
      id: '06',
      name: '06z'
    },
    {
      id: '12',
      name: '12z'
    },
    {
      id: '18',
      name: '18z'
    },
  ],
  'USA/WPC/QPF/DAY_2_6_HOURS': [
    {
      id: '00',
      name: '00z'
    },
    {
      id: '06',
      name: '06z'
    },
    {
      id: '12',
      name: '12z'
    },
    {
      id: '18',
      name: '18z'
    },
  ],
  'USA/WPC/QPF/DAY_3_6_HOURS': [
    {
      id: '00',
      name: '00z'
    },
    {
      id: '06',
      name: '06z'
    },
    {
      id: '12',
      name: '12z'
    },
    {
      id: '18',
      name: '18z'
    },
  ],
  // WPC WSSI
  'USA/WPC/WSSI/OVERALL': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
    {
      id: 'DAY_1_3',
      name: 'Day 1-3'
    },
  ],
  'USA/WPC/WSSI/SNOW_AMOUNT': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
    {
      id: 'DAY_1_3',
      name: 'Day 1-3'
    },
  ],
  'USA/WPC/WSSI/SNOW_LOAD': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
    {
      id: 'DAY_1_3',
      name: 'Day 1-3'
    },
  ],
  'USA/WPC/WSSI/ICE_ACCUMULATION': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
    {
      id: 'DAY_1_3',
      name: 'Day 1-3'
    },
  ],
  'USA/WPC/WSSI/FLASH_FREEZE': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
    {
      id: 'DAY_1_3',
      name: 'Day 1-3'
    },
  ],
  'USA/WPC/WSSI/BLOWING_SNOW': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
    {
      id: 'DAY_1_3',
      name: 'Day 1-3'
    },
  ],
  'USA/WPC/WSSI/GROUND_BLIZZARD': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
    {
      id: 'DAY_1_3',
      name: 'Day 1-3'
    },
  ],
  // WPC Winter Weather
  'USA/WPC/WINTER_WEATHER/SNOWFALL_4_INCHES': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
  ],
  'USA/WPC/WINTER_WEATHER/SNOWFALL_8_INCHES': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
  ],
  'USA/WPC/WINTER_WEATHER/SNOWFALL_12_INCHES': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
  ],
  'USA/WPC/WINTER_WEATHER/FREEZING_RAIN': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
  ],
  'USA/AVALANCHE_ORG/AVALANCHES/CONDITIONS': [
    {
      id: 'CURRENT',
      name: 'Current'
    }
  ]
};