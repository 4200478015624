const help = {
    'ABI_Band13_Clean_Infrared': `Band 13, known as the Clean Infrared (IR) band, is a high-resolution thermal infrared channel on the GOES satellite that measures emitted radiation from the Earth in the 10.1 to 10.6 µm range. It is primarily used to monitor cloud tops, identify storms, and detect severe weather patterns both day and night. Since it’s unaffected by visible light, Band 13 is especially valuable for tracking weather systems in the dark or in heavily clouded areas, making it an essential tool for meteorologists in forecasting and analyzing atmospheric conditions.`,
    'ABI_GeoColor': `Geo Color is a composite satellite imagery product that combines multiple spectral bands from the GOES satellite to create a realistic, true-color view of the Earth. It provides a visual representation of the atmosphere and surface, blending visible and infrared data to show clouds, land, water, and other features in near-natural colors during the day. At night, GeoColor switches to infrared bands to depict cloud cover and other phenomena using different color enhancements. This product is valuable for tracking weather systems, analyzing cloud development, and monitoring environmental changes in a visually intuitive format, both day and night.`,
    'ABI_Dust': `The Dust product, available from the GOES satellite, is a specialized imagery tool that helps detect and monitor airborne dust, sand, and other aerosols in the atmosphere. It uses a combination of infrared bands to highlight dust particles, appearing in distinct colors for easy identification against the background of clouds, land, and ocean. This product is particularly useful for identifying dust storms, volcanic ash, and Saharan dust plumes, which can impact air quality and visibility. The Dust product helps forecasters track dust movement, assess potential hazards to aviation, and monitor its effects on weather and climate.`,
    'ABI_FireTemp': `The Fire Temperature product from the GOES satellite is designed to detect and monitor wildfires and other thermal anomalies by using infrared bands to estimate the temperature of hotspots on the Earth's surface. This product highlights active fires by identifying areas of high heat, with color enhancements showing the intensity of the fire. It is particularly useful for early detection of wildfires, tracking fire behavior, and assessing the spread of fire during both day and night. The Fire Temperature product provides critical data for emergency responders and meteorologists to assess fire risks and manage firefighting efforts.`,
    'ABI_Band2_Red_Visible_1km': `Band 2, known as the Red Visible band, is a high-resolution channel on the GOES satellite that captures reflected sunlight in the visible spectrum, specifically in the 0.64 µm range. It provides detailed images of the Earth's surface and cloud formations during daylight hours. Band 2 is particularly useful for identifying cloud structures, storm development, and surface features like smoke, dust, and vegetation. With its high spatial resolution, it helps meteorologists and forecasters monitor weather patterns and assess environmental conditions in real-time.`,
}

export default [
    {
        "id": "GOES-East",
        "products": [
            // {
            //     "id": "ABI_Air_Mass",
            //     "name": "Air Mass",
            // },
            {
                "id": "ABI_GeoColor",
                "name": "Geo Color",
                "help": help['ABI_GeoColor'],
            },
            {
                "id": "ABI_Band13_Clean_Infrared",
                "name": "Clean Infrared",
                "help": help['ABI_Band13_Clean_Infrared'],
            },
            {
                "id": "ABI_Dust",
                "name": "Dust",
                "help": help['ABI_Dust'],
            },
            {
                "id": "ABI_FireTemp",
                "name": "Fire Temperature",
                "help": help['ABI_FireTemp'],
            },
            {
                "id": "ABI_Band2_Red_Visible_1km",
                "name": "Red Visible",
                "help": help['ABI_Band2_Red_Visible_1km'],
            }
        ],
        "name": "GOES East",
        "help": `The GOES-16 (GOES East) satellite, operated by NOAA, is a next-generation geostationary weather satellite that provides real-time, high-resolution imagery of weather systems, severe storms, and environmental conditions across the Americas and surrounding oceans. Positioned at 75.2°W longitude, it monitors the Atlantic Ocean, eastern U.S., Gulf of Mexico, and the Caribbean. Equipped with advanced sensors like the Advanced Baseline Imager (ABI), GOES-16 delivers detailed data for weather forecasting, storm tracking, and environmental monitoring, significantly enhancing our ability to predict and respond to weather events.`,
        "bounds": [
            -155.9130726357321,
            -81.5046269257671,
            6.616461638070547,
            81.5046269257671
        ],
        "area_of_coverage": {
            "center": [
                -75.2,
                0
            ],
            "radius_m": 10018750
        }
    },
    {
        "id": "GOES-West",
        "products": [
            // {
            //     "id": "ABI_Air_Mass",
            //     "name": "Air Mass",
            // },
            {
                "id": "ABI_GeoColor",
                "name": "Geo Color",
                "help": help['ABI_GeoColor'],
            },
            {
                "id": "ABI_Band13_Clean_Infrared",
                "name": "Clean Infrared",
                "help": help['ABI_Band13_Clean_Infrared'],
            },
            {
                "id": "ABI_Dust",
                "name": "Dust",
                "help": help['ABI_Dust'],
            },
            {
                "id": "ABI_FireTemp",
                "name": "Fire Temperature",
                "help": help['ABI_FireTemp'],
            },
            {
                "id": "ABI_Band2_Red_Visible_1km",
                "name": "Red Visible",
                "help": help['ABI_Band2_Red_Visible_1km'],
            }
        ],
        "name": "GOES West",
        "help": `The GOES-18 (GOES West) satellite, operated by NOAA, is a cutting-edge geostationary satellite that monitors weather and environmental conditions over the western United States, the Pacific Ocean, and parts of Alaska and Hawaii. Positioned at 136.9°W longitude, it provides real-time, high-resolution imagery and data critical for tracking storms, wildfires, coastal fog, and other atmospheric phenomena. Equipped with advanced instruments like the Advanced Baseline Imager (ABI), GOES-18 enhances weather forecasting and disaster preparedness, especially for areas prone to severe weather and natural hazards in the western U.S. and Pacific region.`,
        "bounds": [
            -217.61307263573212,
            -81.5046269257671,
            -55.083538361929456,
            81.5046269257671
        ],
        "area_of_coverage": {
            "center": [
                -136.9,
                0
            ],
            "radius_m": 10018750
        }
    }
]