export default /* glsl */`
precision highp float;
precision highp int;
uniform mat4 u_matrix;
attribute vec3 a_pos;
varying float colorUV;

uniform float minimum;
uniform float maximum;
uniform float opacity;
uniform float colormap_length;

void main() {
    float value = min(max(a_pos.z, minimum), maximum);
    int colorIndex = int((value - minimum) / (maximum - minimum) * colormap_length);
    colorUV = float(colorIndex) / colormap_length;

    gl_Position = u_matrix * vec4(a_pos.xy, 0.0, 1.0);
}
`