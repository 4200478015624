<template>
  <button class="border-solid border-4 block w-full rounded-lg py-3 mb-2" :class="{
    'border-[#F6B91A]': radarSettings.colortable[groupId] === table.id,
  }" :style="getAsCssGradient(groupId, table.id)" @click="onColortableChange(groupId, table.id)">
    <span class="backdrop-blur-sm bg-white/70 rounded px-1.5 font-medium text-xs relative top-[-1px] dark:text-black" v-text="name"></span>
  </button>
</template>

<script>
import RadarColortable from '@/data/Colortables/Radar.js'

import { useRadarTowersStore } from '@/stores/radar_towers'
import { useRadarSettingsStore } from '@/stores/settings/radar'

export default {
  name: 'RadarColortableButton',
  props: ['groupId', 'table', 'name'],
  computed: {
    radarTowersStore() {
      return useRadarTowersStore()
    },
    radarSettings() {
      return useRadarSettingsStore()
    },
  },
  methods: {
    getAsCssGradient(groupId, tableId) {
      return RadarColortable.getAsCssGradient(groupId, tableId);
    },
    async onColortableChange(groupId, tableId) {
      // console.log(groupId);
      this.radarSettings.colortable[groupId] = tableId;

      // This allows the colortable to be changed while playing
      if(! this.radarTowersStore.isPlaying) {
        if(this.radarTowersStore.activeProductGroup.id === groupId) {
          window.map.radar.drawScan(window.map.radar.getRenderer().getData(), this.radarTowersStore.activeTowerId, this.radarTowersStore.activeProductCode);
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
