import { defineStore } from 'pinia';

export const useDrawingStore = defineStore('drawing', {
    state: () => ({
        annotating: false,
    }),

    getters: {
        isAnnotating: (state) => state.annotating,
    },

    actions: {
        startAnnotating() {
            this.annotating = true;
        },
        stopAnnotating() {
            this.annotating = false;
        },
    }
})
