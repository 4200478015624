<script setup lang="ts">
import App from '@/logic/App'

defineProps<{
  error: string,
  success: string,
  dragActive: boolean
}>()

const uploadMsg = App.isNativeApp() ? 'Tap to upload' : 'Drop .pal files here or click to upload';
</script>

<template>
  <div
    :class="[
      'p-8 border-2 border-dashed rounded-lg text-center transition-colors',
      dragActive ? 'border-blue-400 bg-blue-50 dark:bg-blue-900' : 'border-gray-300 dark:border-gray-700',
      error ? 'border-red-400 bg-red-50 dark:bg-red-900' : ''
    ]"
  >
    <div class="flex flex-col items-center">
      <p class="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-200" v-text="uploadMsg"></p>
      <p class="text-sm text-gray-500">
        Only .pal files are accepted
      </p>
      <p v-if="success" class="mt-5 text-sm text-green-600 dark:text-green-400 font-bold">
        {{ success }}
      </p>
      <p v-if="error" class="mt-5 text-sm text-red-600 dark:text-red-200 font-bold">
        {{ error }}
      </p>
    </div>
  </div>
</template>