import { defineStore } from 'pinia';
import { weatherAlerts, alertSections } from '@/data/weather_alerts';
import { CATEGORY_STORMBASED } from '@/data/nws_warning_config.js'

export const useAlertsStore = defineStore('alerts', {
  state: () => ({
    alerts: weatherAlerts,
    selectedAlertId: null as string | null,
    activeTab: CATEGORY_STORMBASED as string
  }),

  getters: {
    sections: (state) => alertSections.map(section => ({
      ...section,
      alerts: state.alerts.filter(alert => alert.significance === section.significance)
    })),

    selectedAlert: (state) => 
      state.selectedAlertId 
        ? state.alerts.find(alert => alert.id === state.selectedAlertId) || null
        : null
  },

  actions: {
    setSelectedAlert(alert) {
      this.selectedAlertId = alert?.id ?? null;
    },

    setActiveTab(tab: string) {
      this.activeTab = tab;
    }
  }
});