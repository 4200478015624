export const circle = (options) => {
    const def = {
        size: 128,
        color: '#FFFFFF',
        border_color: "#000000",
        border_size: 4,
        text: '',
        text_font: "bold 16px Arial",
        text_color: '#313439'
    };

    const config = {...def, ...options }
    return {
        width: config.size,
        height: config.size,
        data: new Uint8Array(config.size * config.size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');

            const radius = (config.size / 2) - 2;
            const context = this.context;

            // Draw the inner circle.
            context.beginPath();
            context.arc(
                this.width / 2,
                this.height / 2,
                radius,
                0,
                Math.PI * 2
            );
            context.fillStyle = config.color;
            context.strokeStyle = config.border_color;
            context.lineWidth = config.border_size;
            context.fill();
            context.stroke();

            if(config.text.length > 0) {
                context.textBaseline = "middle";
                context.textAlign = "center";
                context.font = config.text_font;
                context.fillStyle = config.text_color;
                context.fillText(config.text, canvas.width/2, (canvas.height/2)+2);
            }

            // Update this image's data with data from the canvas.
            this.data = context.getImageData(
                0,
                0,
                this.width,
                this.height
            ).data;
        },

        // Call once before every frame where the icon will be used.
        render: function () {
            // Return `true` to let the map know that the image was updated.
            return true;
        }
    };
};

export const hatch = (options) => {
    const def = {
        size: 10,
        color: '#000000'
    };

    const config = {...def, ...options }
    return {
        width: config.size,
        height: config.size,
        data: new Uint8Array(config.size * config.size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');

            this.context.fillStyle = config.color;
            this.context.fillRect( 2, 7, 1, 1 );
            this.context.fillRect( 3, 6, 1, 1 );
            this.context.fillRect( 4, 5, 1, 1 );
            this.context.fillRect( 5, 4, 1, 1 );
            this.context.fillRect( 6, 3, 1, 1 );
            this.context.fillRect( 7, 2, 1, 1 );

            // Update this image's data with data from the canvas.
            this.data = this.context.getImageData(
                0,
                0,
                this.width,
                this.height
            ).data;
        },

        // Call once before every frame where the icon will be used.
        render: function () {
            // Return `true` to let the map know that the image was updated.
            return true;
        }
    };
};
