<script setup lang="ts">
import { ref, computed } from 'vue'

import AlertToggle from './AlertToggle.vue';
import { Icon } from '@iconify/vue';

import { useAlertsStore } from '@/stores/alerts';
import { useWarningsSettingsStore } from '@/stores/settings/warnings'

const alertsStore = useAlertsStore();
const settingsStore = useWarningsSettingsStore();

const props = defineProps<{
  alert;
}>();

defineEmits<{
  (e: 'update:enabled', value): void;
}>();

const config = computed(() => settingsStore.config[props.alert.country_iso][props.alert.id]);
</script>

<template>
  <div 
    class="flex items-center justify-between py-3 px-4 transition-colors duration-200 hover:bg-gray-100 dark:hover:bg-gray-700/50 cursor-pointer group"
    @click="alertsStore.setSelectedAlert(alert)"
  >
    <div class="flex items-center gap-4">
      <div
        class="h-6 w-6 rounded ring-1 ring-white"
        :style="{ backgroundColor: config.color }"
      />
      <span class="dark:text-white text-lg font-medium">{{ alert.name }}</span>
    </div>
    <div class="flex items-center gap-4">
      <AlertToggle
        :enabled="config.enabled"
        @update:enabled="$emit('update:enabled', {alert, enabled: $event})"
        @click.stop
      />
      <Icon 
        icon="mdi:chevron-right" 
        class="w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300 transition-colors" 
      />
    </div>
  </div>
</template>