<script setup>

</script>

<template>
  <div>
    <span v-if="feature.properties.emergency" class="bg-red-200 text-red-800 border border-red-800 text-xs font-bold px-2.5 py-1 rounded inline-block mr-2 mb-1">Emergency!</span>
    <button v-if="interactionEnabled" class="bg-ww-blue text-white border border-[#17274F] hover:border-[#F6B91A] text-xs px-2.5 py-1 rounded inline-block mr-2 mb-1" @click.stop :class="{
      'border-[#F6B91A]': false
    }">👀<span class="ml-1.5 font-bold">1</span></button>
    <button v-if="interactionEnabled&&0" class="bg-ww-blue text-white border border-[#17274F] hover:border-[#F6B91A] text-xs px-2.5 py-1 rounded inline-block mr-2 mb-1" @click.stop :class="{
      'border-[#F6B91A]': false
    }">💬<span class="ml-1.5 font-bold">10</span></button>
    <button v-if="interactionEnabled" class="bg-ww-blue text-white border border-[#17274F] hover:border-[#F6B91A] text-xs px-2.5 py-1 rounded inline-block mr-2 mb-1" @click.stop :class="{
      'border-[#F6B91A]': false
    }">🧑<span class="ml-1.5 font-bold">1.1M</span></button>
    
    <button v-for="state in feature.properties.states" class="bg-ww-blue text-white font-bold border border-[#17274F] hover:border-[#F6B91A] text-xs px-2.5 py-1 rounded inline-block mr-2 mb-1" :title="state.name" v-text="state.code" @click.stop="zoomToState(state.code)"></button>

    <span v-if="feature.properties.office" class="bg-ww-blue text-white border border-[#17274F] text-xs font-bold px-2.5 py-1 rounded inline-block mr-2 mb-1" v-text="feature.properties.office"></span>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import { useAppStore } from '@/stores/app'

import { bboxForState } from '@/data/USA/state-bbox.js'

export default {
  name: 'AlertTags',
  data() {
    return {
      interactionEnabled: false
    }
  },
  props: ['feature'],
  computed: {
    appStore: () => useAppStore()
  },
  methods: {
    zoomToState(code) {
      const box = bboxForState(code);

      if(box === undefined) return;

      const sw = new mapboxgl.LngLat(box[0], box[1]);
      const ne = new mapboxgl.LngLat(box[2], box[3]);
      const llb = new mapboxgl.LngLatBounds(sw, ne);

      window.map.fitBounds(llb, {
        padding: window.innerWidth / 12,
        duration: 500
      });

      this.appStore.toggleWarningsSidebar(false);
    }
  }
}
</script>

<style scoped>

</style>
