import { defineStore } from 'pinia'

import { productGroups } from '@/data/radar_products.js'

export const useRadarSettingsStore = defineStore('settings/radar', {
  state: () => ({
    storm_tracks: 1,
    lightning: 1,
    melting_layer: 0,
    max_scans: 15,
    colortable: (() => {
      const table = {}
      productGroups.forEach(g => {
        if(typeof g.id !== 'string') return;

        table[g.id] = "DEFAULT";
      })
      return table;
    })(),
    colortable_pal_upload: (() => {
      const table = {}
      productGroups.forEach(g => {
        if(typeof g.id !== 'string') return;

        table[g.id] = [];
      })
      return table;
    })()
  }),

  persist: true
})
