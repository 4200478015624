export default [
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -87.325439453125,
        35.25498962402344,
        303.0
      ]
    },
    "properties": {
      "id": "FWLX",
      "code": "FWLX",
      "secondary": true,
      "products": [
        "REF0",
        "VEL0",
        "CC0",
        "ZDR0"
      ],
      "elevations": [0.5]
    }
  }
]