<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { tabs } from '@/data/weather_alerts';
import AlertSection from './AlertSettings/AlertSection.vue';
import TabSelector from './AlertSettings/TabSelector.vue';
import AlertToggle from './AlertSettings/AlertToggle.vue';

import { useAlertsStore } from '@/stores/alerts';
import { useWarningsSettingsStore } from '@/stores/settings/warnings';

const alertsStore = useAlertsStore();
const settingsStore = useWarningsSettingsStore();

const { activeTab } = storeToRefs(alertsStore);

const setEnabled = (value) => {
  settingsStore.config[value.alert.country_iso][value.alert.id].enabled = value.enabled;
};
</script>

<template>
  <div class="mt-3">
    <div class="max-w-3xl mx-auto space-y-6">
      <div class="flex justify-center mb-6" :class="{
        'hidden sm:flex': tabs.length > 2
      }">
        <TabSelector
          :tabs="tabs"
          :active-tab="activeTab"
          @update:active-tab="alertsStore.setActiveTab"
        />
      </div>

      <div v-if="tabs.length > 2" class="sm:hidden">
        <div class="flex">
          <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" v-model="activeTab">
            <option v-for="t in tabs" :value="t.id" v-text="t.label"></option>
          </select>
        </div>
      </div>

      <!-- Alert Sections -->
      <template v-for="(section, index) in alertsStore.sections" :key="section.significance">
        <AlertSection
          v-if="section.alerts.some(alert => alert.category === activeTab)"
          :section="section"
          :active-category="activeTab"
          @update:enabled="(value) => setEnabled(value)"
        />
      </template>

      <div 
        v-if="false"
        class="flex items-center justify-between py-3 px-4 cursor-pointer group"
      >
        <div class="flex items-center gap-4">
          <span class="text-white text-lg font-medium">Show only active alerts</span>
        </div>
        <div class="flex items-center gap-4">
          <AlertToggle
            :enabled="settingsStore.filter_active"
            @update:enabled="settingsStore.toggleFilterActive()"
            @click.stop
          />
        </div>
      </div>
    </div>
  </div>
</template>