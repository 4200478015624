<template>
  <div v-if="radarTowersStore.anyActive" class="fixed bottom-1 left-0 px-1 z-[20] w-full sm:w-auto sm:ml-2 sm:bottom-2">
    <div class="drawer-container transition-all duration-300 ease-in-out bg-white  dark:bg-ww-blue rounded-2xl shadow-lg flex flex-col text-black dark:text-white w-full sm:w-[375px] md:w-[425px]"
       :class="{
        'drawer-open': isOpen,
        'drawer-closed': !isOpen,
        'drawer-fullscreen': isNavigatorStandalone()
       }">
      <div @click="toggleDraw($event)" ref="drawTab" class="flex items-center justify-center cursor-pointer rounded-t-2xl px-4" :class="{
        'py-2': isOpen,
        'h-full': ! isOpen,
        'pb-4': (! isOpen) && isNavigatorStandalone()
      }">
        <div class="w-6/12 text-left">
          <div class="truncate"><span class="font-bold" v-text="radarTowersStore.activeTower.properties.code"></span><span class="mx-1">&bull;</span><span class="font-bold" v-text="productGroup.name"></span></div>
          <RadarScanDateTime />
        </div>
        <Icon :icon="isOpen ? 'gravity-ui:chevron-down-wide' : 'gravity-ui:chevron-up-wide'" class="inline" />
        <div class="w-6/12 text-right" ref="controls">
          <RadarPlaybackControls />
        </div>
      </div>
      <div ref="scrollable-content" v-if="isOpen" class="flex-grow flex flex-col border-t border-gray-200 overflow-y-auto">
        <div v-if="view === 'PRODUCTS'">
          <div class="flex justify-start items-start m-3 select-none">
            <div class="w-7/12 text-sm">
              <template v-if="radarTowersStore.scanDatetime === null">Loading...</template>
              <template v-else>
                <div class="text-xs sm:text-sm">
                  <span>Scan At: </span>
                  <span v-text="radarTowersStore.scanLocalDatetime" :title="`UTC: ${radarTowersStore.scanDatetime}`" class="font-bold" :class="{
                    'bg-red-100 text-red-800 px-2.5 py-0.5 rounded-full': !radarTowersStore.isPlaying && radarTowersStore.scanIsOld && !radarTowersStore.hasBufferedScans
                  }"></span>
                  <a href="#" class="ml-2 inline" @click.prevent="showProductHelp($event)"><Icon icon="carbon:help" class="size-4 inline" /></a>
                </div>
                <div v-if="radarTowersStore.scanVcp !== null" class="text-xs sm:text-sm mt-1">
                  <span>VCP: </span>
                  <span class="font-bold">{{ radarTowersStore.scanVcp }} <span v-if="getVCPName(radarTowersStore.scanVcp)" class="font-bold">({{ getVCPName(radarTowersStore.scanVcp) }})</span></span>
                  <a href="#" class="ml-2 inline" @click.prevent="showExtraHelp('VCP')"><Icon icon="carbon:help" class="size-4 inline" /></a>
                </div>
              </template>
            </div>
            <div class="text-right relative w-5/12">
              <RadarTilts :product-group="productGroup" />
            </div>
          </div>
          <div>
            <div v-for="row in productsGrid" :key="row" class="flex flex-nowrap space-x-4">
              <template v-for="col in row" :key="col">
                <div class="flex-1 min-w-0 p-2 text-center select-none" @click="onProductClick($event, col)">
                  <div style="height: 60px; width: 60px;" class="ml-auto mr-auto rounded-lg overflow-hidden cursor-pointer" :class="{
                    'border-sky-400 border border-4 rounded-lg': col.tilts.map(t => t.product).includes(radarTowersStore.activeProductCode)
                  }">
                    <DynamicProductTile v-if="dynamicProductTiles.includes(col.id)" :groupId="col.id" :tableId="radarSettings.colortable[col.id]" />
                    <img v-else :src="getImageUrl(col.id)" :alt="col.name">
                  </div>
                  <div v-text="col.name" class="text-sm mt-1"></div>
                </div>
              </template>
            </div>
          </div>
          <div class="p-5 pt-1">
            <div class="relative inline-flex items-center justify-center w-full mb-6">
              <hr class="w-full h-px bg-gray-300 border-0">
              <span class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 text-xs rounded-lg">Settings</span>
            </div>
            <div class="grid grid-cols-2 gap-4">
              <div v-if="stormTracksSettingSupported">
                <label class="inline-flex items-center cursor-pointer w-full">
                  <input type="checkbox" value="" class="sr-only peer" v-model.number="radarSettings.storm_tracks" :true-value="1" :false-value="0" @change="onStormTracksChange">
                  <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                  <span class="ms-3 text-sm font-bold">Storm Tracks</span>
                  <a href="#" class="ml-auto" @click.prevent="showExtraHelp('STORM_TRACKS')"><Icon icon="carbon:help" class="size-5" /></a>
                </label>
              </div>
              <div v-if="stormTracksSettingSupported&&false">
                <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Storm Tracks</span></label>
                <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg inline p-1" v-model.number="radarSettings.storm_tracks" @change="onStormTracksChange">
                  <option value="0">Off</option>
                  <option value="1">Standard</option>
                  <!-- <option value="2">Pro</option> -->
                </select>
                <a href="#" class="inline float-right" @click.prevent="showExtraHelp('STORM_TRACKS')"><Icon icon="carbon:help" class="inline size-5" /></a>
              </div>
              <div v-if="meltingLayerSettingSupported&&false">
                <label class="inline-flex items-center cursor-pointer w-full">
                  <input type="checkbox" value="" class="sr-only peer" v-model.number="radarSettings.melting_layer" :true-value="1" :false-value="0" @change="onMeltingLayerChange">
                  <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                  <span class="ms-3 text-sm font-bold">Melting Layer</span>
                  <a href="#" class="ml-auto" @click.prevent="showExtraHelp('MELTING_LAYER')"><Icon icon="carbon:help" class="size-5" /></a>
                </label>
              </div>
              <div v-if="lightningSettingSupported">
                <label class="inline-flex items-center cursor-pointer w-full">
                  <input type="checkbox" value="" class="sr-only peer" v-model.number="radarSettings.lightning" :true-value="1" :false-value="0" @change="onLightningChange">
                  <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                  <span class="ms-3 text-sm font-bold">Lightning</span>
                  <a href="#" class="ml-auto" @click.prevent="showExtraHelp('LIGHTNING')"><Icon icon="carbon:help" class="size-5" /></a>
                </label>
              </div>
              <div v-if="lightningSettingSupported&&false">
                <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Lightning</span></label>
                <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg inline p-1" v-model.number="radarSettings.lightning" @change="onLightningChange">
                  <option value="0">Off</option>
                  <option value="1">Standard</option>
                  <!-- <option value="2">Pro</option> -->
                </select>
                <a href="#" class="inline float-right" @click.prevent="showExtraHelp('LIGHTNING')"><Icon icon="carbon:help" class="inline size-5" /></a>
              </div>
              <div v-if="false">
                <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Max Scans</span></label>
                <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg inline p-1" v-model.number="radarSettings.max_scans">
                  <option value="15">15 scans</option>
                  <option value="50">50 scans</option>
                  <option value="100">100 scans</option>
                </select>
                <a href="#" class="inline float-right" @click.prevent="showExtraHelp('MAX_SCANS')"><Icon icon="carbon:help" class="inline size-5" /></a>
              </div>
              <div class="col-span-2">
                <button @click.prevent="onColortableChangeClick($event)" type="button" class="block w-full text-white bg-blue-700 hover:bg-blue-800 font-bold rounded-lg text-sm px-2.5 py-2 pt-1.5" :disabled='isPlaybackBuffering'><Icon icon="fluent:color-20-regular" class="inline size-5 mr-2" />Customize Color Tables</button>
              </div>
            </div>
          </div>
          <div class="text-xs my-5 text-center" v-if="this.radarTowersStore.activeTower.properties.code.substr(0, 2) === 'CA'">
            <p>Data Source: <a href="https://www.canada.ca/en/environment-climate-change.html" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">Environment and Climate Change Canada (ECCC)</a></p>
          </div>
        </div>
        <div v-if="view === 'COLORTABLES'">
          <div class="m-4">
            <div>
              <button @click="onBackToProducts" type="button" class="text-white bg-slate-400 dark:bg-ww-blue-200 dark:hover:bg-ww-blue-100 dark:text-white font-medium rounded-lg text-sm px-2 py-1 border border-gray-400 dark:border-gray-500"><Icon icon="octicon:chevron-left-12" class="inline" />Back</button>
              <a href="#" class="ml-2 inline float-right" @click.prevent="showExtraHelp('COLORTABLES')"><Icon icon="carbon:help" class="size-6 inline" /></a>
            </div>
            <div>
              <RadarColortables />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { Icon } from '@iconify/vue'
import { useModal, useModalSlot } from 'vue-final-modal'
import { useSwipe } from '@vueuse/core'

import { useRadarTowersStore } from '@/stores/radar_towers'
import { useRadarSettingsStore } from '@/stores/settings/radar'
import { useAppStore } from '@/stores/app'
import RadarTilts from './RadarDrawer/RadarTilts.vue'
import RadarColortables from './RadarDrawer/RadarColortables.vue'
import DynamicProductTile from './RadarDrawer/DynamicProductTile.vue'
import RadarScanDateTime from './RadarDrawer/RadarScanDateTime.vue'
import RadarPlaybackControls from './RadarDrawer/RadarPlaybackControls.vue'

import { isNavigatorStandalone } from '@/logic/Extra/helpers'

import SimpleModal from '@/logic/Modals/Templates/Simple.vue'
import RadarProductHelpModal from '@/logic//Radar/RadarProductHelp.vue'
import { useRadarPlayback } from '@/logic/Composables/RadarPlayback'

import { getVCPName } from '@/tools/helpers'

export default {
  name: 'RadarDrawer',
  setup() {
    const drawTab = ref(null)
    const instance = getCurrentInstance()

    onMounted(() => {
      const { direction } = useSwipe(drawTab, {
        onSwipe(e) {
          if (direction.value === 'down' && instance.proxy.isOpen) {
            instance.proxy.toggleDraw(e)
          }

          if (direction.value === 'up' && ! instance.proxy.isOpen) {
            instance.proxy.toggleDraw(e)
          }

          e.stopPropagation()
        }
      })
    })

    return {
      drawTab,
      isNavigatorStandalone,
    }
  },
  data() {
    return {
      isOpen: false,
      dynamicProductTiles: ['REF', 'VEL', 'CC', 'SW'],
      view: 'PRODUCTS'
    };
  },
  components: {
    Icon,
    RadarTilts,
    RadarColortables,
    DynamicProductTile,
    RadarScanDateTime,
    RadarPlaybackControls
  },
  computed: {
    radarTowersStore() {
      return useRadarTowersStore()
    },
    radarSettings() {
      return useRadarSettingsStore()
    },
    appStore() {
      return useAppStore()
    },
    productsGrid() {
      return this.radarTowersStore.towerProducts.filter(item => item.tilts.length).reduce((result, item, index) => {
        const groupIndex = Math.floor(index / 3);
        (result[groupIndex] = result[groupIndex] || []).push(item);
        return result;
      }, [])
    },
    productGroup() {
      return this.radarTowersStore.activeProductGroup
    },
    playButtonIcon() {
      if(this.isPlaybackBuffering) return 'eos-icons:three-dots-loading';

      return !this.radarTowersStore.isPlaying ? 'ph:play-fill' : 'ph:pause-fill';
    },
    stormTracksSettingSupported() {
      return this.radarTowersStore.availableProducts.includes('NST');
    },
    lightningSettingSupported() {
      return window.map.radar.isLightningSupported(this.radarTowersStore.activeTower);
    },
    meltingLayerSettingSupported() {
      // TODO
      // Move this elsewhere...
      return this.radarTowersStore.availableProducts.some(p => ['NXM', 'NYM', 'NZM', 'N0M', 'NAM', 'N1M', 'NBM', 'N2M', 'N3M'].includes(p))
    },
    isPlaybackBuffering() {
      return this.radarTowersStore.isPlaybackBuffering;
    }
  },
  watch: {
    'radarTowersStore.anyActive': {
      handler(newValue) {
        if (! newValue) {
          this.isOpen = false
        }
      }
    },
    'isOpen': {
      handler(newValue) {
        this.appStore.radar_drawer_open = newValue;
      }
    }
  },
  unmounted() {
    this.radarTowersStore.inspectorActive = false;
  },
  methods: {
    getImageUrl(name) {
      return new URL(`../assets/product-tiles/${name}.png`, import.meta.url).href
    },
    openDraw() {
      this.isOpen = true;
    },
    closeDraw() {
      this.isOpen = false;
    },
    toggleDraw(e) {
      if(!document.body.contains(e.target) || (this.$refs.controls.contains(e.target) && this.$refs.controls !== e.target)) return false;

      if (!this.isOpen) {
        this.openDraw();
      } else {
        this.closeDraw();
      }
    },
    async onProductClick(e, productGroup) {
      this.productGroup = productGroup;

      // TODO
      const productCode = productGroup.tilts[0].product;

      if (this.radarTowersStore.activeProductCode === productCode) {
        return;
      }

      console.log(this.radarTowersStore.activeTowerId, productCode)

      await window.map.radar.changeRadarProduct(this.radarTowersStore.activeTowerId, productCode)
    },
    onSliceClick(e) {

    },
    showProductHelp(e) {
      const productGroup = this.productGroup;

      if(typeof productGroup.help !== 'string') {
        return alert('Unable to locate help information');
      }

      window.map.radar.openRadarProductHelpModal(productGroup);
    },
    showExtraHelp(feature) {
      let title;
      let text;

      if(feature === 'VCP') {
        title = 'Volume Coverage Pattern (VCP)'
        text = `To capture as much atmospheric data as possible, the WSR-88D uses a volumetric scanning strategy. This involves the radar antenna scanning not just near the ground but also at various elevation angles, allowing it to gather vertical data. This approach helps forecasters better assess the depth and intensity of storms, especially when storms are close to the radar site.

To understand how Volume Coverage Patterns (VCPs) work, it's essential to know the two main operating modes: clear air mode and precipitation mode. Clear air mode, as the name implies, is used during calm weather. In this mode, the radar scans fewer elevation angles at a slower pace, reducing wear on its components. When the radar detects precipitation while in clear air mode, it automatically switches to precipitation mode. In precipitation mode, the radar scans 14 or 15 elevation angles, depending on the VCP being used, at a faster rate.`;

        // More detailed information can be found <a href='https://www.noaa.gov/jetstream/vcp_max' target='_blank' rel="noreferrer" class='font-medium text-blue-600 hover:underline'>here</a> and <a href='https://en.wikipedia.org/wiki/NEXRAD#Scan_strategies' target='_blank' rel="noreferrer" class='font-medium text-blue-600 hover:underline'>here</a>.
      }
      else if(feature === 'STORM_TRACKS') {
        title = 'Storm Tracks';
        text = `The Storm Tracks product is a powerful radar-based tool used for tracking the movement and intensity of storms in real-time. It utilizes data from a weather radar system to monitor severe weather events, including thunderstorms, hail, and tornadoes. By analyzing storm motion and characteristics, the Storm Tracks product provides detailed information on storm paths, estimated speed, direction, and projected areas affected. This product is essential for meteorologists, emergency responders, and weather enthusiasts, enabling more accurate predictions and timely warnings to help safeguard communities from severe weather impacts.`;
      }
      else if(feature === 'LIGHTNING') {
        title = 'Lightning';
        text = `This overlay displays precise locations of recent lightning strikes, with lightning strike markers that gradually fade out as time elapses, helping you stay aware of developing storms and potential lightning threats.`;
      }
      else if(feature === 'COLORTABLES') {
        title = 'Color Tables';
        text = `The colortable feature gives you powerful customization options to enhance radar imagery interpretation. By adjusting color scales, you can represent different intensities of reflectivity, wind speeds, and more, using color gradients that best fit their needs. In addition to selecting from predefined color schemes, you can upload their own .pal colortables, adding a further level of personalization and flexibility.`;
      }
      else {
        return alert('Unable to locate help information');
      }

      const modal = useModal({
        defaultModelValue: true,
        component: SimpleModal,
        attrs: {
          title
        },
        slots: {
          default: useModalSlot({
            component: RadarProductHelpModal,
            attrs: {
              text,
              onClose() {
                modal.close()
              },
            }
          })
        },
      })
    },
    getVCPName,
    async onStormTracksChange() {
      if(this.radarTowersStore.isPlaying) {
        await this.onStopClick()
      }

      if(this.radarSettings.storm_tracks > 0) {
        await window.map.radar.loadLatestStormTracks(this.radarTowersStore.activeTower);
      }
      else {
        window.map.radar.clearStormTracks(this.radarTowersStore.activeTowerId);
      }
    },
    async onLightningChange() {
      if(this.radarTowersStore.isPlaying) {
        await this.onStopClick()
      }

      if(this.radarSettings.lightning > 0) {
        await window.map.radar.lightning.load(this.radarTowersStore.activeTowerId);
        window.map.radar.lightning.show()
      }
      else {
        window.map.radar.lightning.clear(this.radarTowersStore.activeTowerId);
      }
    },
    async onMeltingLayerChange() {
      if(this.radarTowersStore.isPlaying) {
        await this.onStopClick()
      }

      if(this.radarSettings.melting_layer > 0) {
        // await window.map.radar.lightning.load(this.radarTowersStore.activeTowerId);
      }
      else {
        // window.map.radar.lightning.clear();
      }
    },
    onBackToProducts() {
      this.view = 'PRODUCTS';

      this.scrollToTop();
    },
    onColortableChangeClick() {
      this.view = 'COLORTABLES';

      this.scrollToTop();
    },
    scrollToTop() {
      this.$refs['scrollable-content'].scrollTop = 0;
    }
  }
}
</script>

<style scoped>
.drawer-container {
  max-height: 400px;
}

.drawer-closed {
  height: 3.3rem;
  transform: translateY(calc(100% - 3.3rem));
}

.drawer-fullscreen {
  height: 5rem;
  transform: translateY(calc(100% - 5rem));
}

.drawer-open {
  height: 50vh;
  transform: translateY(0);
}

.live-text {
  animation: text-pulsate 3s ease-out infinite;
}

@keyframes text-pulsate {
  0% {
    opacity: 1.0;
  }
  20% {
    opacity: 0.3;
  }
  30%, 100% {
    opacity: 1.0;
  }
}
</style>
