const map = {
    "WV": [-82.6445909999999, 37.2015400000001, -77.7195189999999, 40.638801],
    "FL": [-87.634896, 24.396308, -79.974306, 31.0009680000001],
    "IL": [-91.5130789999999, 36.9702980000001, -87.019935, 42.5084810000001],
    "MN": [-97.239093, 43.4993610000001, -89.483385, 49.3843580000001],
    "MD": [-79.487651, 37.886605, -74.986282, 39.723037],
    "RI": [-71.907258, 41.095834, -71.0885709999999, 42.0187990000001],
    "ID": [-117.243027, 41.988182, -111.043495, 49.000911],
    "NH": [-72.557124, 42.6970420000001, -70.575094, 45.305778],
    "NC": [-84.3218209999999, 33.7528780000001, -75.400119, 36.5881370000001],
    "VT": [-73.4379049999999, 42.726933, -71.465047, 45.0166590000001],
    "CT": [-73.727775, 40.950943, -71.7872389999999, 42.050511],
    "DE": [-75.789023, 38.451132, -74.984165, 39.839516],
    "NM": [-109.050431, 31.332231, -103.002043, 37.000233],
    "CA": [-124.482003, 32.5288320000001, -114.131211, 42.009503],
    "NJ": [-75.5635861928347, 38.7886566096221, -73.8850599422342, 41.357422783023],
    "WI": [-92.8894329999999, 42.49172, -86.2495479999999, 47.3098220000001],
    "OR": [-124.703541, 41.992082, -116.463262, 46.2990990000001],
    "NE": [-104.053514, 39.9999320000001, -95.3082899999999, 43.0017070000001],
    "PA": [-80.519851, 39.7197990000001, -74.6895019999999, 42.5160720000001],
    "WA": [-124.848974, 45.543541, -116.916071, 49.002432],
    "LA": [-94.043352, 28.855127, -88.758388, 33.019543],
    "GA": [-85.6051649999999, 30.355757, -80.751429, 35.0013030000001],
    "AL": [-88.473227, 30.1444250000001, -84.888246, 35.008028],
    "UT": [-114.052885, 36.9976570000001, -109.041572, 42.0017020000001],
    "OH": [-84.820305, 38.403423, -80.518705, 42.3271320000001],
    "TX": [-106.645646, 25.837164, -93.5080389999999, 36.500704],
    "CO": [-109.060204, 36.9924490000001, -102.041522, 41.0034440000001],
    "SC": [-83.3539279999999, 32.0334540000001, -78.4993009999999, 35.21554],
    "OK": [-103.002413, 33.6157870000001, -94.43101, 37.0023120000001],
    "TN": [-90.310298, 34.982924, -81.6469, 36.678255],
    "WY": [-111.054558, 40.9947720000001, -104.052245, 45.005815],
    "HI": [-178.443593, 18.86546, -154.755792, 28.5172690000001],
    "ND": [-104.049118, 45.935072, -96.554411, 49.0006920000001],
    "KY": [-89.571203, 36.497058, -81.9647879999999, 39.1477320000001],
    "VI": [-65.154118, 17.623468, -64.5126739999999, 18.4649840000001],
    "MP": [144.813338, 14.036565, 146.154418, 20.6165560000001],
    "GU": [144.563426, 13.182335, 145.009167, 13.7061790000001],
    "ME": [-71.083928, 42.917126, -66.8854439999999, 47.4598540000001],
    "NY": [-79.76259, 40.477399, -71.7774909999999, 45.0158650000001],
    "NV": [-120.006473, 35.001857, -114.039461, 42.0018420000001],
    "AK": [-129.9795, 71.4410, -179.1505, 51.2097],
    "AS": [-171.141907, -14.6018129999999, -168.101612, -10.997203],
    "MI": [-90.418392, 41.6961180000001, -82.1229709999999, 48.306063],
    "AR": [-94.617919, 33.004106, -89.644395, 36.4997490000001],
    "MS": [-91.6550089999999, 30.139845, -88.097888, 34.9960990000001],
    "MO": [-95.774704, 35.995683, -89.098968, 40.61364],
    "MT": [-116.049153, 44.357915, -104.039694, 49.0011000000001],
    "KS": [-102.051769, 36.9930160000001, -94.588387, 40.0031660000001],
    "IN": [-88.0978919999999, 37.771728, -84.784592, 41.761368],
    "PR": [-67.998751, 17.831509, -65.168503, 18.568002],
    "SD": [-104.057879, 42.4796860000001, -96.436472, 45.9453770000001],
    "MA": [-73.50821, 41.187053, -69.8588609999999, 42.8867780000001],
    "VA": [-83.675395, 36.540852, -75.166435, 39.466012],
    "DC": [-77.119759, 38.7916450000001, -76.909393, 38.995845],
    "IA": [-96.639485, 40.37544, -90.1400609999999, 43.501196],
    "AZ": [-114.816591, 31.3321770000001, -109.045172, 37.003725]
};

export const bboxForState = (code) => {
	return map[code];
};