export default [
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -107.44269,
        52.52048,
        532
      ]
    },
    "properties": {
      "id": "CASRA",
      "code": "CASRA",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -73.85892,
        45.70628,
        70.7
      ]
    },
    "properties": {
      "id": "CASBV",
      "code": "CASBV",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -101.08563,
        50.548748,
        552.5
      ]
    },
    "properties": {
      "id": "CASFW",
      "code": "CASFW",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -119.230432,
        55.694942,
        1015.8
      ]
    },
    "properties": {
      "id": "CASSR",
      "code": "CASSR",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -81.79385,
        49.28126,
        244.5
      ]
    },
    "properties": {
      "id": "CASRF",
      "code": "CASRF",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -81.380698,
        43.372425,
        303.8
      ]
    },
    "properties": {
      "id": "CASET",
      "code": "CASET",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -105.182848,
        50.571232,
        586.8
      ]
    },
    "properties": {
      "id": "CASBE",
      "code": "CASBE",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -65.699294,
        46.222378,
        100.9
      ]
    },
    "properties": {
      "id": "CASCM",
      "code": "CASCM",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -60.205246,
        45.949718,
        102.5
      ]
    },
    "properties": {
      "id": "CASMB",
      "code": "CASMB",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -84.596686,
        47.247807,
        527.1
      ]
    },
    "properties": {
      "id": "CASMR",
      "code": "CASMR",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -113.399366,
        51.20613,
        969.6
      ]
    },
    "properties": {
      "id": "CASSM",
      "code": "CASSM",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -77.808086,
        48.551355,
        394
      ]
    },
    "properties": {
      "id": "CASLA",
      "code": "CASLA",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -110.195362,
        50.312503,
        864
      ]
    },
    "properties": {
      "id": "CASSU",
      "code": "CASSU",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -92.797566,
        49.858047,
        411.3
      ]
    },
    "properties": {
      "id": "CASDR",
      "code": "CASDR",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -53.12667,
        47.326604,
        293.2
      ]
    },
    "properties": {
      "id": "CASHR",
      "code": "CASHR",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -71.913832,
        46.449476,
        106.1
      ]
    },
    "properties": {
      "id": "CASSF",
      "code": "CASSF",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -97.780482,
        50.153001,
        274.4
      ]
    },
    "properties": {
      "id": "CASWL",
      "code": "CASWL",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -67.601036,
        48.480468,
        713.8
      ]
    },
    "properties": {
      "id": "CASVD",
      "code": "CASVD",
      "secondary": false,
      "products": [],
      "elevations": [-0.3, 0.4, 0.9, 1.5, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -122.48736,
        49.01644,
        93
      ]
    },
    "properties": {
      "id": "CASAG",
      "code": "CASAG",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -80.53414,
        45.79309,
        190.3
      ]
    },
    "properties": {
      "id": "CASBI",
      "code": "CASBI",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -114.14447,
        53.56061,
        782.7
      ]
    },
    "properties": {
      "id": "CASCV",
      "code": "CASCV",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -76.11638,
        45.04115,
        145.5
      ]
    },
    "properties": {
      "id": "CASFT",
      "code": "CASFT",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -63.70494,
        45.09818,
        214.2
      ]
    },
    "properties": {
      "id": "CASGO",
      "code": "CASGO",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -79.57288,
        43.96366,
        358.5
      ]
    },
    "properties": {
      "id": "CASKR",
      "code": "CASKR",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -110.06138,
        54.37888,
        634.4
      ]
    },
    "properties": {
      "id": "CASCL",
      "code": "CASCL",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -89.100038,
        48.596001,
        504
      ]
    },
    "properties": {
      "id": "CASSN",
      "code": "CASSN",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -111.21515,
        56.375628,
        756.4
      ]
    },
    "properties": {
      "id": "CASFM",
      "code": "CASFM",
      "secondary": false,
      "products": [],
      "elevations": [0.4, 0.8, 1.2, 1.6, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -57.834579,
        48.930228,
        542
      ]
    },
    "properties": {
      "id": "CASMM",
      "code": "CASMM",
      "secondary": false,
      "products": [],
      "elevations": [0.0, 0.4, 0.9, 1.5, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -119.064196,
        50.369109,
        1884.5
      ]
    },
    "properties": {
      "id": "CASSS",
      "code": "CASSS",
      "secondary": false,
      "products": [],
      "elevations": [-0.3, 0.4, 0.9, 1.5, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -122.95469,
        53.61539,
        1114.2
      ]
    },
    "properties": {
      "id": "CASPG",
      "code": "CASPG",
      "secondary": false,
      "products": [],
      "elevations": [0.0, 0.4, 0.9, 1.5, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -71.430833,
        47.977908,
        887
      ]
    },
    "properties": {
      "id": "CASMA",
      "code": "CASMA",
      "secondary": false,
      "products": [],
      "elevations": [0.0, 0.4, 0.9, 1.5, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -123.853638,
        49.526953,
        572
      ]
    },
    "properties": {
      "id": "CASHP",
      "code": "CASHP",
      "secondary": false,
      "products": [],
      "elevations": [0.0, 0.4, 0.9, 1.5, 2.1, 2.7, 3.5, 4.4, 5.3, 6.4]
    }
  }
]