<script setup>
import { useWarningsStore } from '@/stores/warnings'
import { useMesoscaleDiscussionsStore } from '@/stores/mesoscale_discussions'
import { useTropicalStore } from '@/stores/tropical'
import { computed } from 'vue'

const warningsStore = useWarningsStore()
const mesoscaleDiscussionsStore = useMesoscaleDiscussionsStore()
const tropicalStore = useTropicalStore()

const warningsCount = computed(() => {
  return warningsStore.features.length + mesoscaleDiscussionsStore.geojson.features.length + tropicalStore.storms.length + tropicalStore.disturbances.length
})
</script>

<template>
  <span
    class="absolute top-2 left-1 inline-flex items-center justify-center px-2 py-1 text-sm font-bold leading-none text-[#fff] transform -translate-x-1/2 -translate-y-1/2 bg-[#FF5722] rounded-full"
    v-text="warningsCount"></span>
</template>
