import api from '@/logic/Api'

export default class BaseRadar {
  constructor(map) {
    this.map = map
  }

  async loadLatestFile(id, product) {
    const list = await this.fetchLatestList(id, product)

    // console.log(list)

    if (list.length == 0) {
      // TODO
      return
    }

    const latestScanFilename = list[list.length - 1]

    // console.log(latestScanFilename)

    // const latestScanFilename = 'latest.json';

    return await this.fetchJson(id, product, latestScanFilename)
  }

  async fetchLatestList(id, product, limit = -1) {
    let list;

    if(window.location.href.includes('test-radar-scans')) {
      list = await (await fetch('/test-radar-scans/dir.list')).text()
    }
    else {
      list = await (
        await api.instance().get(`/radar/processed/${id}/${product}/dir.list`, { responseAs: 'response' })
      ).text()
    }

    const cleanList = list.split('\n').filter((l) => l.length > 0);

    if(limit === -1) return cleanList;

    return cleanList.slice(Math.max(cleanList.length - limit, 0))
  }

  async fetchJson(id, product, filename) {
    if(window.location.href.includes('test-radar-scans')) {
      return (await fetch(`/test-radar-scans/${filename}`)).json();
    }
    return api.instance().get(`/radar/processed/${id}/${product}/${filename}`)
  }

  async fetchJsonByUrl(url) {
    const response = await fetch(url);
    if (! response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    return response.json();
  }
}
