import { defineStore } from 'pinia'

import UrlHash from '@/tools/url-hash'

const DEFAULT_OUTLOOK_ID = '0';

export const useOutlookStore = defineStore('outlook', {
  state: () => ({
    activeOutlookId: DEFAULT_OUTLOOK_ID,
    activeStep: null,
    steps: [],
    colortable: [],
    isDataLoading: false
  }),

  persist: {
    // Store some properties related to radar in the url
    storage: {
      getItem: (key) => {
        const params = new UrlHash();
        if(params.has('oid') && params.has('ost')) {
          const id = params.get('oid');
          const step = params.get('ost');

          const store = useOutlookStore();

          store.$patch({
            activeOutlookId: id,
            activeStep: step
          });
        }
      },
      setItem: (key, value) => {
        const store = useOutlookStore();

        const params = new UrlHash();
        if(store.activeOutlookId === DEFAULT_OUTLOOK_ID) {
          params.delete('oid')
          params.delete('ost')
        }
        else {
          params.set('oid', store.activeOutlookId)
          params.set('ost', store.activeStep)
        }

        params.save();
      }
    }
  },
})
