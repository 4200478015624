<script setup>
import WeatherAlerts from '@/components/WeatherAlerts.vue'
import AlertDetails from '@/components/AlertSettings/AlertDetails.vue';
import { useAlertsStore } from '@/stores/alerts';

const alertsStore = useAlertsStore();
</script>

<template>
  <AlertDetails v-if="alertsStore.selectedAlert" />
  <WeatherAlerts v-else />
</template>

<script>
export default {
  name: 'SettingsModal',
}
</script>