export default [
    /*
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          24.869,
          60.2706,
          53
        ]
      },
      "properties": {
        "id": "FIVAN",
        "code": "FIVAN",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
        "elevations": [0.3, 0.4, 0.7, 1.5, 2.0, 3.0, 4.0, 5.0, 9.0]
      }
    },
    */
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          27.1081,
          60.9039,
          101
        ]
      },
      "properties": {
        "id": "FIANJ",
        "code": "FIANJ",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
        "elevations": [0.3, 0.4, 0.7, 1.5, 2.0, 3.0, 4.0, 5.0, 9.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          27.3815,
          62.8626,
          239
        ]
      },
      "properties": {
        "id": "FIKUO",
        "code": "FIKUO",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
        "elevations": [0.3, 0.4, 0.5, 0.7, 1.5, 2.0, 3.0, 4.0, 5.0, 9.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          21.6434,
          60.1285,
          35
        ]
      },
      "properties": {
        "id": "FIKOR",
        "code": "FIKOR",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
        "elevations": [0.4, 0.5, 0.7, 1.5, 2.0, 3.0, 4.0, 5.0, 9.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          26.3189,
          64.7749,
          85
        ]
      },
      "properties": {
        "id": "FIUTA",
        "code": "FIUTA",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
        "elevations": [0.3, 0.7, 0.7, 1.5, 2.0, 3.0, 4.0, 5.0, 9.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          26.8969,
          67.1391,
          514
        ]
      },
      "properties": {
        "id": "FILUO",
        "code": "FILUO",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
        "elevations": [0.1, 0.4, 0.7, 1.5, 2.0, 3.0, 5.0, 7.0, 9.0, 11.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          23.8209,
          63.1048,
          172
        ]
      },
      "properties": {
        "id": "FIVIM",
        "code": "FIVIM",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
        "elevations": [0.3, 0.4, 0.5, 0.7, 1.5, 2.0, 3.0, 4.0, 5.0, 9.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          29.7977,
          61.9069,
          141
        ]
      },
      "properties": {
        "id": "FIKES",
        "code": "FIKES",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
        "elevations": [0.3, 0.4, 0.7, 1.5, 2.0, 3.0, 4.0, 5.0, 9.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          25.4401,
          62.3045,
          241
        ]
      },
      "properties": {
        "id": "FIPET",
        "code": "FIPET",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
        "elevations": [0.3, 0.7, 0.7, 1.5, 2.0, 3.0, 4.0, 5.0, 9.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          29.4489,
          63.8378,
          294
        ]
      },
      "properties": {
        "id": "FINUR",
        "code": "FINUR",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
        "elevations": [0.3, 0.4, 0.7, 1.5, 2.0, 3.0, 4.0, 5.0, 9.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          24.4956,
          60.5562,
          148
        ]
      },
      "properties": {
        "id": "FIVIH",
        "code": "FIVIH",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
        "elevations": [0.3, 0.4, 0.7, 1.5, 2.0, 3.0, 5.0, 7.0, 9.0, 11.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          22.502,
          61.8108,
          142
        ]
      },
      "properties": {
        "id": "FIKAN",
        "code": "FIKAN",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
        "elevations": [0.3, 0.4, 0.7, 1.5, 2.0, 3.0, 4.0, 5.0, 9.0]
      }
    }
  ]