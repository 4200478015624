import { ref } from 'vue';

const audioContext = ref<AudioContext | null>(null);

export function useSoundEffects() {
  const initAudioContext = () => {
    if (!audioContext.value) {
      audioContext.value = new AudioContext();
    }
  };

  const playSound = async (type: 'none' | 'default' | 'urgent' | 'emergency') => {
    if (type === 'none') return;
    
    initAudioContext();
    if (!audioContext.value) return;

    const oscillator = audioContext.value.createOscillator();
    const gainNode = audioContext.value.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioContext.value.destination);

    // Different sound configurations for each type
    switch (type) {
      case 'default':
        oscillator.frequency.setValueAtTime(440, audioContext.value.currentTime); // A4 note
        gainNode.gain.setValueAtTime(0.1, audioContext.value.currentTime);
        break;
      case 'urgent':
        oscillator.frequency.setValueAtTime(880, audioContext.value.currentTime); // A5 note
        gainNode.gain.setValueAtTime(0.2, audioContext.value.currentTime);
        break;
      case 'emergency':
        oscillator.frequency.setValueAtTime(1760, audioContext.value.currentTime); // A6 note
        gainNode.gain.setValueAtTime(0.3, audioContext.value.currentTime);
        break;
    }

    oscillator.start();
    gainNode.gain.exponentialRampToValueAtTime(0.00001, audioContext.value.currentTime + 0.5);
    oscillator.stop(audioContext.value.currentTime + 0.5);
  };

  return {
    playSound
  };
}