export default [
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -76.95566000416875, 2.5178799591958523, 2993
      ]
    },
    "properties": {
      "id": "COCEM",
      "code": "COCEM",
      "secondary": false,
      "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
      "elevations": [1.5, 2.4, 3.1, 5.1, 6.4, 8.0, 10.0, 12.5, 15.0]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -74.11397552490234, 4.675168991088867, 2551
      ]
    },
    "properties": {
      "id": "COBOG",
      "code": "COBOG",
      "secondary": false,
      "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
      "elevations": [0.5, 1.5, 2.5, 3.0, 4.35, 4.7]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -75.52862548828125, 6.19088077545166, 2792
      ]
    },
    "properties": {
      "id": "COSAN",
      "code": "COSAN",
      "secondary": false,
      "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
      "elevations": [0.5, 1.0, 2.0, 4.0]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -72.62846997007728, 2.5337500870227814, 201
      ]
    },
    "properties": {
      "id": "COGUA",
      "code": "COGUA",
      "secondary": false,
      "products": ["REF", "VEL", "CC", "ZDR", "KDP", "SW"],
      "elevations": [0.5, 1.5, 2.4, 3.1, 5.1, 6.4, 8.0, 10.0, 12.5, 15.0]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -81.7080700211227, 12.58859010413289, 11
      ]
    },
    "properties": {
      "id": "COAND",
      "code": "COAND",
      "secondary": false,
      "products": ["REF", "VEL", "ZDR", "KDP"],
      "elevations": [0.5, 1.0, 2.0, 3.0, 5.0, 7.0, 10.0, 15.0, 20.0, 30.0]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -75.28299992904067, 9.330999981611967, 165
      ]
    },
    "properties": {
      "id": "COCOR",
      "code": "COCOR",
      "secondary": false,
      "products": ["REF", "VEL", "ZDR", "KDP"],
      "elevations": [0.5, 1.0, 1.5, 2.2, 3.0, 4.0, 5.3, 7.2, 11.0, 20.0]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -74.20369997620583, 5.011800080537796, 3506
      ]
    },
    "properties": {
      "id": "COTAB",
      "code": "COTAB",
      "secondary": false,
      "products": ["REF", "VEL", "ZDR", "KDP"],
      "elevations": [0.0, 0.5, 1.0, 2.0, 4.5, 7.0, 10.0, 15.0, 25.0]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -71.33273988962173, 4.563660118728876, 170
      ]
    },
    "properties": {
      "id": "COCAR",
      "code": "COCAR",
      "secondary": false,
      "products": ["REF", "VEL", "ZDR", "KDP"],
      "elevations": [0.5, 1.5, 2.4, 3.1, 5.1, 6.4, 8.0, 10.0, 12.5, 15.0]
    }
  }
]