export default [
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          20.9611,
          52.4053,
          93.3
        ]
      },
      "properties": {
        "id": "PLLEG",
        "code": "PLLEG",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP"],
        "elevations": [0.5, 1.5, 2.5, 3.5, 5.4, 9.1, 15.0, 23.8]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          18.7251,
          50.1513,
          321
        ]
      },
      "properties": {
        "id": "PLRAM",
        "code": "PLRAM",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP"],
        "elevations": [0.5, 1.5, 2.5, 3.5, 5.4, 9.1, 15.0, 23.8]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          16.0395,
          50.8925,
          668.8
        ]
      },
      "properties": {
        "id": "PLPAS",
        "code": "PLPAS",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP"],
        "elevations": [0.5, 1.5, 2.5, 3.5, 5.4, 9.1, 15.0, 23.8]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          22.037,
          50.1141,
          211.1
        ]
      },
      "properties": {
        "id": "PLRZE",
        "code": "PLRZE",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP"],
        "elevations": [0.5, 1.5, 2.5, 3.5, 5.4, 9.1, 15.0, 23.8]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          16.797,
          52.4133,
          88.3
        ]
      },
      "properties": {
        "id": "PLPOZ",
        "code": "PLPOZ",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP"],
        "elevations": [0.5, 1.5, 2.5, 3.5, 5.4, 9.1, 15.0, 23.8]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          15.8368,
          53.7958,
          116.6
        ]
      },
      "properties": {
        "id": "PLSWI",
        "code": "PLSWI",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP"],
        "elevations": [0.5, 1.5, 2.5, 3.5, 5.4, 9.1, 15.0, 23.8]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          18.4563,
          54.3843,
          135.2
        ]
      },
      "properties": {
        "id": "PLGDA",
        "code": "PLGDA",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP"],
        "elevations": [0.5, 1.5, 2.5, 3.5, 5.4, 9.1, 15.0, 23.8]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          20.0797,
          50.3942,
          388.5
        ]
      },
      "properties": {
        "id": "PLBRZ",
        "code": "PLBRZ",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP"],
        "elevations": [0.5, 1.5, 2.5, 3.5, 5.4, 9.1, 15.0, 23.8]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          21.4121,
          53.8565,
          202
        ]
      },
      "properties": {
        "id": "PLUZR",
        "code": "PLUZR",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "KDP"],
        "elevations": [0.5, 1.5, 2.5, 3.5, 5.4, 9.1, 15.0, 23.8]
      }
    }
  ]