import moment from 'moment'

export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

export const debounce = (func, timeout) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
};

export const findClosestDate = (dates, refDate) => {
    return dates.reduce((closest, current) => {
        let currentDate = moment.utc(current);
        
        if (closest === null) return current;
        
        return Math.abs(currentDate.diff(refDate)) < Math.abs(moment.utc(closest).diff(refDate)) ? current : closest;
    }, null);
}

export const degreesToCardinal = (degrees) => {
	// Normalize degrees to be within 0-360
	degrees = (degrees % 360 + 360) % 360;

	const directions = [
		'North', 'North-Northeast', 'Northeast', 'East-Northeast', 
		'East', 'East-Southeast', 'Southeast', 'South-Southeast', 
		'South', 'South-Southwest', 'Southwest', 'West-Southwest', 
		'West', 'West-Northwest', 'Northwest', 'North-Northwest'
	];

	const index = Math.round(degrees / 22.5) % 16;
	
	return directions[index];
}

export const getVCPName = (vcpNumber) => {
    const vcpNames = {
    	// NEXRAD
        12: "Clear Air Mode",
        31: "Clear Air Mode",
        32: "Clear Air Mode",
        35: "Clear Air Mode",
        112: "Precipitation Mode",
        121: "Precipitation Mode",
        212: "Precipitation Mode",
        215: "Precipitation Mode",
        // TDWR
        90: "Clear Air Mode",
        80: "Precipitation Mode"

    };
    
    // Retrieve the VCP name or "Unknown VCP" if not found
	return vcpNames[vcpNumber] || null;
};

export const getHCAClassification = (value) => {
    value = parseInt(value)

    // Round to nearest 10
    value = Math.floor(value / 10) * 10;

    if(value < 10 || value > 110) return "Unknown";

    const classifications = {
        10: "Ground Clutter",
        20: "Biological",
        30: "Ice Crystals",
        40: "Dry Snow",
        50: "Wet Snow",
        60: "Light / Moderate Rain",
        70: "Heavy Rain",
        80: "Big Drops",
        90: "Graupel",
        100: "Hail",
        110: "Rain / Hail Mixture",
        120: "Unknown"
    };
    
    return classifications[value] || "Unknown";
}

export const tropicalStormWindSpeedToCategory = (windSpeedKnots) => {
    // Convert knots to miles per hour (1 knot = 1.15078 mph)
    const windSpeedMph = windSpeedKnots * 1.15078;

    // Determine the hurricane category based on the Saffir-Simpson scale
    if (windSpeedMph >= 157) {
        return 5;
    } else if (windSpeedMph >= 130 && windSpeedMph < 157) {
        return 4;
    } else if (windSpeedMph >= 111 && windSpeedMph < 130) {
        return 3;
    } else if (windSpeedMph >= 96 && windSpeedMph < 111) {
        return 2;
    } else if (windSpeedMph >= 74 && windSpeedMph < 96) {
        return 1;
    }

    return 0;
};

export const tropicalStormWindSpeedToColor = (windSpeedKnots) => {
    const windSpeedMph = windSpeedKnots * 1.15078;

    if(windSpeedMph >= 157) return '#A188FC';
    if(windSpeedMph >= 130) return '#FF738A';
    if(windSpeedMph >= 111) return '#FF9E59';
    if(windSpeedMph >= 96) return '#FFD98C';
    if(windSpeedMph >= 74) return '#FFFFD9';
    if(windSpeedMph >= 39) return '#4DFFFF';

    return '#6EC1EA';
};

export const tropicalStormOceanToName = (code) => {
    if(code === 'AT') return 'Atlantic';
    if(code === 'EP') return 'Eastern Pacific';
    if(code === 'CP') return 'Central Pacific';

    return 'Unknown';
};

export const cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for(let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

/**
 * Compare two semantic version strings
 * @param {string} v1 - First version (e.g., "1.2.3")
 * @param {string} v2 - Second version (e.g., "1.3.0")
 * @returns {number} - Returns:
 *                     1 if v1 > v2
 *                     -1 if v1 < v2
 *                     0 if v1 === v2
 */
export function compareSemver(v1, v2) {
  const parseVersion = (version) => version.split('.').map(Number);

  const [major1, minor1, patch1] = parseVersion(v1);
  const [major2, minor2, patch2] = parseVersion(v2);

  if (major1 !== major2) {
    return major1 > major2 ? 1 : -1;
  }
  if (minor1 !== minor2) {
    return minor1 > minor2 ? 1 : -1;
  }
  if (patch1 !== patch2) {
    return patch1 > patch2 ? 1 : -1;
  }
  return 0;
}

export function capitalizeWords(str) {
    return str
        .split(' ') // Split the string into an array of words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter
        .join(' '); // Join the words back into a single string
}
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export async function allSettledLimit(tasks, limit) {
  const results = []; // To store all results
  const running = []; // Tracks currently running promises

  // Iterate over all tasks
  for (const task of tasks) {
    const promise = task()
      .then(value => ({ status: 'fulfilled', value }))
      .catch(reason => ({ status: 'rejected', reason }));

    results.push(promise); // Add to results for eventual `Promise.allSettled`

    // Add the current task to the running set
    running.push(promise);

    // If we reach the concurrency limit, wait for one to finish
    if (running.length >= limit) {
      await Promise.race(running);
      // Remove finished promises from the `running` array
      running.splice(running.findIndex(p => p === promise), 1);
    }
  }

  // Wait for all remaining tasks to finish
  await Promise.all(running);

  // Resolve all results
  return Promise.all(results);
}

export function uniqueList(arr, getKey) {
  const seen = new Set();
  return arr.filter(item => {
    const value = getKey(item);
    if (!seen.has(value)) {
      seen.add(value);
      return true;
    }
    return false;
  });
}

