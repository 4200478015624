<script setup>
defineProps({
  currentStep: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['update:step'])
</script>

<template>
  <div class="dots">
    <button
      v-for="step in 5"
      :key="step"
      @click="emit('update:step', step)"
      class="dot"
      :class="{ active: currentStep === step }"
      :aria-label="`Go to step ${step}`"
    ></button>
  </div>
</template>

<style scoped>
.dots {
  display: flex;
  gap: 0.5rem;
  margin-top: 2rem;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e2e8f0;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: all 0.2s ease;
}

.dot:hover {
  transform: scale(1.2);
}

.dot.active {
  background: #3730a3;
  transform: scale(1.2);
}

:root.dark .dot {
    background: #4b5563;
  }

:root.dark .dot.active {
  background: #818cf8;
}
</style>