import { compareSemver } from '@/tools/helpers'

class App {
  isNativeApp() {
    return window.WeatherWiseBridge !== undefined;
  }

  getNativeVersion() {
    if(! this.isNativeApp()) return null;

    const ua = window.navigator.userAgent.trim();
    if(! ua.startsWith('WeatherWise/')) return null;

    return ua.split(/[ ,]+/)[0].split('/')[1];
  }

  isNativeVersionAtleast(version) {
    const currentVersion = this.getNativeVersion();
    if(currentVersion === null) return false;

    return compareSemver(currentVersion, version) >= 0;
  }

  hash() {
    return __COMMIT_HASH__;
  }
}

const app = new App();

export default app;
