<script setup lang="ts">
import { ref, computed, toRaw } from 'vue'
import { Icon } from '@iconify/vue';

import AlertToggle from './AlertToggle.vue';
import { useAlertsStore } from '@/stores/alerts';
import { useWarningsSettingsStore } from '@/stores/settings/warnings'

import { getConfigForSetting } from '@/data/nws_warning_config.js'

import { useSoundEffects } from '@/composables/useSoundEffects';

const alertsStore = useAlertsStore();
const settingsStore = useWarningsSettingsStore();

const { playSound } = useSoundEffects();

const notificationSounds = [
  { value: 'none', label: 'None' },
  { value: 'default', label: 'Default' },
  { value: 'urgent', label: 'Urgent' },
  { value: 'emergency', label: 'Emergency' }
];

const updateSettings = (key, value) => {
  settingsStore.config[alertsStore.selectedAlert.country_iso][alertsStore.selectedAlert.id][key] = value;
};

const handlePlaySound = () => {
  if (alertsStore.selectedAlert) {
    playSound(alertsStore.selectedAlert.settings.notificationSound);
  }
};

const goBack = () => {
  alertsStore.setSelectedAlert(null);
};

const onResetClick = () => {
  settingsStore.config[alertsStore.selectedAlert.country_iso][alertsStore.selectedAlert.id] = { ...getConfigForSetting(alertsStore.selectedAlert.id) };
};

const config = computed(() => settingsStore.config[alertsStore.selectedAlert.country_iso][alertsStore.selectedAlert.id]);

const anyChanged = computed(() => JSON.stringify(settingsStore.config[alertsStore.selectedAlert.country_iso][alertsStore.selectedAlert.id]) !== JSON.stringify(getConfigForSetting(alertsStore.selectedAlert.id)));
</script>

<template>
  <div class="mt-3">
    <div class="max-w-3xl mx-auto">
      <button 
        @click="goBack"
        class="hover:text-gray-500 dark:text-gray-400 dark:hover:text-white mb-6 flex items-center gap-2"
      >
        <Icon icon="mdi:arrow-left" class="w-5 h-5" />
        <span>Back to Alerts</span>
      </button>
      
      <div class="dark:bg-gray-800 rounded-lg overflow-hidden mb-6">
        <div class="dark:bg-gray-700 px-6 py-4 flex items-center justify-between">
          <div class="flex items-center gap-4">
            <div
              class="h-8 w-8 rounded ring-1 ring-white"
              :style="{ backgroundColor: config.color }"
            />
            <h1 class="text-2xl font-bold dar:text-white">{{ alertsStore.selectedAlert?.name }}</h1>
          </div>
          <AlertToggle
            :enabled="config.enabled"
            @update:enabled="e => updateSettings('enabled', e)"
          />
        </div>
        
        <div class="p-6 space-y-8">
          <!-- Alert Settings -->
          <div>
            <div class="space-y-6">
              <!-- Color Picker -->
              <div class="flex items-center gap-4">
                <label class="dark:text-gray-300 w-40 font-bold">Color</label>
                <input 
                  type="color" 
                  :value="config.color"
                  @input="e => updateSettings('color', e.target.value)"
                  class="h-10 w-20 rounded cursor-pointer border border-[#2563eb]"
                />
              </div>

              <!-- Border Thickness -->
              <div class="flex items-center gap-4">
                <label class="dark:text-gray-300 w-40 font-bold">Border Thickness</label>
                <input 
                  type="range" 
                  min="0" 
                  max="10"
                  step="0.5"
                  :value="config.line_width"
                  @input="e => updateSettings('line_width', Number(e.target.value))"
                  class="w-full h-2 bg-gray-200 dark:bg-ww-blue-100 rounded-lg appearance-none cursor-pointer slider"
                />
                <span class="min-w-16 text-center bg-ww-blue text-white border border-[#17274F] text-xs font-bold px-2.5 py-1 rounded-full inline-block mr-2 mb-1" v-text="`${config.line_width.toFixed(1)}px`"></span>
              </div>

              <!-- Fill Opacity -->
              <div class="flex items-center gap-4">
                <label class="dark:text-gray-300 w-40 font-bold">Fill Opacity</label>
                <input 
                  type="range" 
                  min="0" 
                  max="100"
                  step="5"
                  :value="config.fill_opacity * 100"
                  @input="e => updateSettings('fill_opacity', Number(e.target.value) / 100)"
                  class="w-full h-2 bg-gray-200 dark:bg-ww-blue-100 rounded-lg appearance-none cursor-pointer slider"
                />
                <span class="min-w-16 text-center bg-ww-blue text-white border border-[#17274F] text-xs font-bold px-2.5 py-1 rounded-full inline-block mr-2 mb-1" v-text="`${Math.round(config.fill_opacity * 100)}%`"></span>
              </div>

              <div class="flex items-center gap-4">
                <div v-if="anyChanged" class="w-full">
                  <button
                    class="w-full mt-10 px-2.5 py-2 pt-1.5 text-sm font-medium
                           text-white dark:text-gray-100
                           bg-blue-700 dark:bg-blue-600
                           hover:bg-blue-800 dark:hover:bg-blue-700
                           rounded-lg"
                    @click="onResetClick"
                  >
                    Reset
                  </button>
                </div>
              </div>

              <!-- Notification Sound -->
              <div v-if="false" class="flex items-center gap-4">
                <label class="dark:text-gray-300 w-40 font-bold">Notification Sound</label>
                <div class="flex items-center gap-2">
                  <select 
                    :value="config.notification_sound"
                    @change="e => updateSettings('notification_sound', e.target.value)"
                    class="bg-gray-700 text-white rounded px-3 py-2 w-48"
                  >
                    <option 
                      v-for="sound in notificationSounds" 
                      :key="sound.value" 
                      :value="sound.value"
                    >
                      {{ sound.label }}
                    </option>
                  </select>
                  <button
                    v-if="config.notification_sound !== 'none'"
                    @click="handlePlaySound"
                    class="p-2 dark:text-gray-300 hover:text-white transition-colors rounded-full hover:bg-gray-600"
                    title="Test sound"
                  >
                    <Icon icon="mdi:volume-high" class="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  @apply bg-blue-600 dark:bg-blue-500;
  cursor: pointer;
  border-radius: 10px;
}

.slider::-moz-range-thumb {
  width: 28px;
  height: 28px;
  @apply bg-blue-600 dark:bg-blue-500;
  cursor: pointer;
  border-radius: 10px;
}
</style>

