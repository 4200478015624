export default {
  MCD: {
    priority: 1,
    // TODO
    // #0002FF for Winter Weather
    // Colours found here: https://www.spc.noaa.gov/products/md/
    color: '#FF0002',
    name: 'Mesoscale Convective Discussion',
    icon: 'mdi:warmth',
    help: `When conditions start to align for severe weather, the Storm Prediction Center (SPC) typically releases a Mesoscale Discussion (MCD) statement anywhere from 30 minutes to several hours before issuing a weather watch. MCDs are also issued for hazardous winter weather on the mesoscale level, covering events like locally heavy snowfall, blizzards, and freezing rain (as detailed below).

    Additionally, MCDs can address heavy rainfall, convective trends, and other weather phenomena when the forecaster believes valuable information can be provided that may not be immediately apparent to field forecasters. These discussions are based on mesoscale analysis, observation interpretation, and short-term, high-resolution numerical model output.

An MCD outlines current weather conditions, forecasts for the next few hours, meteorological reasoning, and, in the case of severe thunderstorm potential, the timing and location where SPC might issue a watch. These discussions offer a bit of extra lead time, helping you prepare operations before an official watch is in place. MCDs begin with a numerical string that provides the latitude and longitude coordinates of a polygon, loosely describing the area under discussion.`
  },
  MPD: {
    priority: 2,
    color: '#008B00',
    name: 'Mesoscale Precipitation Discussion',
    icon: 'cil:rain',
    help: `The Weather Prediction Center (WPC) provides short-term guidance during heavy rain events that pose a risk of flash flooding to National Weather Service (NWS) Weather Forecast Offices (WFOs), River Forecast Centers (RFCs), the media, emergency managers, and other relevant partners. This guidance is delivered through Mesoscale Precipitation Discussions (MPDs), which are typically issued 1-6 hours in advance and cover areas approximately half the size of Kansas. Each MPD includes a graphic outlining the area of concern and key meteorological factors, along with a brief text summary emphasizing the mesoscale features driving the expected heavy rainfall. The likelihood of flash flooding is categorized under one of three headlines:

    FLASH FLOODING LIKELY: There is high confidence that environmental conditions are or will become favorable for heavy rainfall leading to flash flooding.

    FLASH FLOODING POSSIBLE: Conditions are favorable or becoming favorable for heavy rainfall, but there is uncertainty about how the event will unfold and whether flash flooding will occur.

    FLASH FLOODING UNLIKELY: There is high confidence that conditions are, or will become, unfavorable for heavy rainfall leading to flash flooding, often issued toward the event's conclusion.

The WPC’s Metwatch desk focuses specifically on flash flooding caused by widespread intense rainfall, excluding localized flash flooding and those triggered by other factors like dam failures or ice jams.`
  }
}
