<template>
  <canvas ref="canvas" width="960" height="960" style="max-width: 100%; height: auto;"></canvas>
</template>

<script>
import RadarColortable from '@/data/Colortables/Radar.js'

import AppCache from '@/logic/AppCache.js'

export default {
  name: 'RadarColortableButton',
  props: ['groupId', 'tableId'],
  mounted() {
    const canvas = this.$refs['canvas'];
    const ctx = canvas.getContext('2d');

    const configMap = {
      'REF': {
        min: -15,
        max: 85
      },
      'VEL': {
        min: -80,
        max: 80
      },
      'CC': {
        min: 0,
        max: 1.04
      },
      'SW': {
        min: 0,
        max: 20.5909605683
      }
    };

    const config = configMap[this.groupId]

    const cacheKey = `dynamic-radar-product-tile:${this.groupId}:${this.tableId}`;

    const cacheTile = AppCache.get(cacheKey)

    if(cacheTile !== null) {
      canvas.width = 480*2;
      canvas.height = 480*2;
      ctx.putImageData(cacheTile, 0, 0);
      return;
    }
    
    // Load the greyscale image
    const image = new Image();
    image.src = `/radar/dynamic-product-tiles/${this.groupId}.png`; // Replace with your greyscale image path
    image.onload = () => {
        // Set canvas dimensions
        canvas.width = image.width;
        canvas.height = image.height;

        // Draw the greyscale image on the canvas
        ctx.drawImage(image, 0, 0);

        // Get image data
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const pixels = imageData.data;

        const ccc = RadarColortable.get(this.groupId, this.tableId);
        // console.log(this.groupId, this.tableId, ccc)

        const minValue = config.min;
        const maxValue = config.max;

        const range = maxValue - minValue;
        // console.log(range)

        // Define a colortable (example: simple gradient from black to red)
        const colortable = [];
        for (let i = 0; i < 256; i++) {
            const gateValue = (i * (range/256)) + minValue;
            // console.log(gateValue)

            if(gateValue < ccc.min) {
                colortable[i] = ccc.colors[0]
            }
            else if(gateValue > ccc.max) {
                colortable[i] = ccc.colors[ccc.colors.length-1]
            }
            else {
                const p = ((gateValue - ccc.min) / (ccc.max - ccc.min))
                // console.log(p)

                let colorIdx = parseInt(ccc.colors.length * p) - 1;
                if(colorIdx < 0) colorIdx = 0;
                else if(colorIdx >= ccc.colors.length) colorIdx = ccc.colors.length - 1;

                // console.log(colorIdx)

                colortable[i] = ccc.colors[colorIdx]
            }
        }

        // console.log(colortable)

        // -15 = black (0,0,0)
        // 85 = white (255,255,255)

        // Map greyscale values to colors
        for (let i = 0; i < pixels.length; i += 4) {
            // console.log(this.groupId, pixels[i], pixels[i+1], pixels[i+2])
            // break;

            if((pixels[i] === 46 || pixels[i] === 45) && pixels[i+1] === 133 && pixels[i+2] === 255) {
              pixels[i] = 67;
              pixels[i+1] = 77;
              pixels[i+2] = 92;
              pixels[i+3] = 255;
              continue
            }

            const greyValue = pixels[i]; // Assuming greyscale, R=G=B
            const color = colortable[greyValue];

            // Update pixel color
            pixels[i] = color[0];     // Red
            pixels[i + 1] = color[1]; // Green
            pixels[i + 2] = color[2]; // Blue

            // Ignore alpha value...
            if(color.length === 4) {
              // pixels[i + 3] = color[3]; // Alpha
            }
        }

        // Put the updated image data back on the canvas
        ctx.putImageData(imageData, 0, 0);

        AppCache.set(cacheKey, imageData)
    };
  }
}
</script>