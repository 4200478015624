<script setup>
import { computed, ref, onMounted } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import { locateUser, isLocating } from '@/tools/locationUtils';
import { Icon } from '@iconify/vue';
import Modal from './Onboarding/Modal.vue'
import Step1 from './Onboarding/Step1.vue'
import Step2 from './Onboarding/Step2.vue'
import Step3 from './Onboarding/Step3.vue'
import Step4 from './Onboarding/Step4.vue'
import Step5 from './Onboarding/Step5.vue'

const props = defineProps({
  currentStep: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['update:step', 'completion', 'close'])
const isDesktop = ref(false)
const slideDirection = ref('right')
const previousStep = ref(1)

const steps = [Step1, Step2, Step3, Step4, Step5]

const currentComponent = computed(() => {
  return steps[props.currentStep - 1] || Step1
})

const showNextButton = computed(() => {
  return props.currentStep < steps.length
})

const nextStep = () => {
  if (props.currentStep < steps.length) {
    previousStep.value = props.currentStep
    slideDirection.value = 'right'
    emit('update:step', props.currentStep + 1)
  } else {
    completeOnboarding()
  }
}

const skip = () => {
  completeOnboarding()
  nextStep()
}

const checkViewport = () => {
  isDesktop.value = window.innerWidth >= 768
}

const completeOnboarding = () => {
  localStorage.setItem('onboardingComplete', 'true')
  emit('completion')
  closeOnboarding()
}

const closeOnboarding = () => {
  emit('close')
}

const startLocate = async () => {
  try {
    await locateUser();
  } catch (error) {
    console.error(error);
  } finally {
    completeOnboarding();
  }
}

onMounted(() => {
  checkViewport()
  window.addEventListener('resize', checkViewport)
})
</script>

<template>
  <Modal :is-desktop="isDesktop">
    <div class="onboarding mb-12 sm:mb-0" v-on-click-outside="closeOnboarding">
      <transition
        :name="slideDirection === 'right' ? 'slide-left' : 'slide-right'"
        mode="out-in"
      >
        <component
          :is="currentComponent"
          :current-step="currentStep"
          @update:step="$emit('update:step', $event)"
        />
      </transition>
      <div class="navigation">
        <button @click="skip" class="skip">skip</button>
        <button @click="nextStep" v-if="currentStep < steps.length" class="next bg-[#F6B91A] text-[#2C3E50]">
          <span>Next</span>
          <span class="arrow">→</span>
        </button>
        <button @click="startLocate" :disabled="isLocating" v-else class="next bg-[#F6B91A] text-[#2C3E50]">
          <span>Locate Me</span>
          <Icon v-if="isLocating" icon="eos-icons:three-dots-loading" class="inline size-8" />
          <span v-else class="arrow">→</span>
        </button>
      </div>
    </div>
  </Modal>
</template>

<style scoped>
html, body {
  overflow: auto;
}

.onboarding {
  display: flex;
  flex-direction: column;
  min-height: 100%; /* Ensure full height */
  padding: 1rem;
}

.navigation {
  margin-top: auto; /* Push to bottom */
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1.5rem; /* Add bottom spacing */
}

button {
  padding: 0.8rem 1.5rem;
  border-radius: 2rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.skip {
  background: transparent;
  color: #94a3b8;
}

.skip:hover {
  color: #64748b;
}

.next {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.next:hover {
  transform: translateX(2px);
}

.arrow {
  font-size: 1.2rem;
}

/* Slide Transitions */
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.3s ease-out;
}

.slide-left-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}

.slide-right-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
