<script setup>
import { onMounted } from 'vue'
import { ModalsContainer } from 'vue-final-modal'
import { useHead } from '@unhead/vue'

import { PAGE_TITLE } from '@/brand'
import { overrideAPIs } from '@/tools/overrideAPIs.js'
import { usePreferencesStore } from '@/stores/settings/preferences'
import socket from '@/logic/Socket.js'


overrideAPIs()

// remove any existing listeners (after a hot module replacement)
socket.off()

socket.connect()

useHead({
  title: PAGE_TITLE
})

// Prevent zoom and text selection on mobile
const preventZoomAndTextSelection = () => {
  document.addEventListener('touchstart', (event) => {
    if (event.touches.length > 1) {
      event.preventDefault(); // Prevent zoom
    }
  }, { passive: false });

  document.addEventListener('gesturestart', (event) => {
    event.preventDefault(); // Prevent zoom
  });

  document.addEventListener('selectstart', (event) => {
    event.preventDefault(); // Prevent text selection
  });
};

preventZoomAndTextSelection();

const preferencesStore = usePreferencesStore()

const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

// Cross-browser compatible event listener for system dark mode changes
const handleDarkModeChange = (e) => {
  setTimeout(() => {
    preferencesStore.applyDarkMode()
  }, 100)
}

darkModeMediaQuery.addEventListener('change', handleDarkModeChange)

onMounted(() => {
  setTimeout(() => {
    preferencesStore.applyDarkMode()
  }, 100)
})

</script>

<template>
  <RouterView />
  <ModalsContainer />
</template>
