import { defineStore } from 'pinia'

export const useTropicalSettingsStore = defineStore('settings/tropical', {
  state: () => ({
    visible: true,
  }),

  persist: true,

  actions: {
    toggleVisible() {
      this.visible = !this.visible;
    }
  }
})
