import { toRaw, render, h } from 'vue'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'
import { colord } from 'colord'

import { renderToPopup } from '@/tools/mapbox-map'
import { debounce, capitalizeWords } from '@/tools/helpers'
import { hatch } from '@/tools/graphics'

import socket from '@/logic/Socket'
import api from '@/logic/Api'
import SimpleModal from './Modals/Templates/Simple.vue'
import OutlookDiscussionModal from './Outlooks/Discussion.vue'
import OutlookHelpModal from './Outlooks/OutlookHelp.vue'

import { useOutlookStore } from '@/stores/outlook'

import AllSteps from './Outlooks/steps'
import AllHelp from './Outlooks/help'

import AvalanchePopup from './Outlooks/AvalanchePopup.vue'
import { renderToString } from 'vue/server-renderer'

export default class Outlooks {
  constructor(map) {
    this.map = map

    this.outlookStore = useOutlookStore();

    this.sourceNonHatchedId = 'outlooks-non-hatched-source'
    this.sourceHatchedId = 'outlooks-hatched-source'
    
    this.lineNonHatchedLayerId = 'outlooks-line-non-hatched-layer'
    this.fillNonHatchedLayerId = 'outlooks-fill-non-hatched-layer'
    
    this.lineHatchedLayerId = 'outlooks-line-hatched-layer'
    this.fillHatchedLayerId = 'outlooks-fill-hatched-layer'

    this.activeSocketRooms = [];
    this.renderedId = null;
    this.renderedStep = null;
    this.renderedGeojson = null;

    this.addLayer()

    const onPolygonClick = renderToPopup((e) => {
      if(e.features.length == 0) return;
      // console.log(e.features);

      let validFeatures = e.features.filter(f => f.source.startsWith('outlooks-'));

      if(validFeatures.length === 0) return;

      // Will use the first (top) feature
      validFeatures = [validFeatures[0]];
      
      const containers = [];

      const hasMetadata = this.renderedGeojson.metadata !== undefined && this.renderedGeojson.metadata.discussion !== undefined;
      const defaultHtml = () => {
        const caretSvg = `<svg class='inline size-4 h-full ml-1' xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="m184.49 136.49l-80 80a12 12 0 0 1-17-17L159 128L87.51 56.49a12 12 0 1 1 17-17l80 80a12 12 0 0 1-.02 17"/></svg>`;
        const titleHtml = validFeatures.map(f => `<div><strong>${f.properties.name}</strong></div>`).join('');

        let html = `<div class='flex cursor-pointer'><div>${titleHtml}</div>`;

        if(hasMetadata) {
          html+=`<div class='ml-auto'>${caretSvg}</div>`;
        }
  
        html+=`</div>`;

        return html;
      }

      const avalancheHtml = () => {
        validFeatures.forEach(feature => {
          const container = window.document.createElement('div');
          render(h(AvalanchePopup, { feature }), container);
          containers.push(container);
        })
      }

      switch(this.renderedId) {
        case 'USA/AVALANCHE_ORG/AVALANCHES/CONDITIONS': {
          avalancheHtml();
          break;
        }
        default:
          const container = window.document.createElement('div');
          const div = window.document.createElement('div');
          div.innerHTML = defaultHtml();
          div.addEventListener('click', () => {
            this.map.popups.clear();
    
            if(this.renderedGeojson === null) return false;
    
            if(hasMetadata) {
              this.openDiscussionModel(this.renderedId, this.renderedStep, 'Forecast Discussion', this.renderedGeojson.metadata);
            }
          });
          container.appendChild(div)
          if(this.renderedId.startsWith('USA/WPC/EXCESSIVE_RAINFALL/RAINFALL') && ['DAY_1', 'DAY_2', 'DAY_3'].includes(this.renderedStep)) {
            const qpfButton = window.document.createElement('button')
            qpfButton.innerText = 'See QPF';
            qpfButton.classList.add("w-full", "px-4", "py-1", "font-bold", "rounded-lg", "text-white", "bg-blue-600", "hover:bg-blue-700", "mt-2")
            qpfButton.addEventListener('click', (e) => {
              this.map.popups.clear();

              this.renderOutlook('USA/WPC/QPF/DAILY', this.renderedStep);

              return false;
            })
            container.appendChild(qpfButton)
          }
          containers.push(container);
          break;
      }

      return containers;
    });

    map.on('click', this.fillHatchedLayerId, onPolygonClick)
    map.on('click', this.fillNonHatchedLayerId, onPolygonClick)

    const hatchGraphic = hatch({
      color: '#000000'
    });
    this.map.addImage('dashed-black-hatch-pattern', hatchGraphic);
  }

  addLayer() {
    this.map.addSource(this.sourceHatchedId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    this.map.addSource(this.sourceNonHatchedId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    this.map.addLayer({
      id: this.lineHatchedLayerId,
      type: 'line',
      source: this.sourceHatchedId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'line-color'],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': ['get', 'line-width']
      }
    }, window.map.mesoscaleDiscussions.lineLayerId)

    this.map.addLayer({
      id: this.fillHatchedLayerId,
      type: 'fill',
      source: this.sourceHatchedId,
      layout: {
        'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill-color'],
        'fill-opacity': ['get', 'fill-opacity'],
        'fill-pattern': ['get', 'fill-pattern']
      }
    }, this.lineHatchedLayerId)

    this.map.addLayer({
      id: this.lineNonHatchedLayerId,
      type: 'line',
      source: this.sourceNonHatchedId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'line-color'],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': ['get', 'line-width']
      }
    }, this.fillHatchedLayerId)

    this.map.addLayer({
      id: this.fillNonHatchedLayerId,
      type: 'fill',
      source: this.sourceNonHatchedId,
      layout: {
        'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill-color'],
        'fill-opacity': ['get', 'fill-opacity'],
        // 'fill-pattern': ["coalesce", ['get', 'fill-pattern'], null]
      }
    }, this.lineNonHatchedLayerId)
  }

  async fetchOutlook(id, step) {
    const geojson = await api.instance().get(`/outlooks/${id}/${step}/latest.geojson?_=${(new Date()).getTime()}`);

    return geojson;
  }

  async renderOutlook(id, step) {
    this.stopListeningToRooms();

    this.setSteps(id);
    this.outlookStore.activeOutlookId = id;
    this.outlookStore.activeStep = step;

    let geojson;

    this.outlookStore.isDataLoading = true;
    const startLoadingAt = new Date();
    try {
      geojson = await this.fetchOutlook(id, step);
    }
    catch(e) {
      return console.error(`Failed to fetch outlook: ${id} ${step}`, e);
    }
    finally {
      const endLoadingAt = new Date();
      setTimeout(() => {
        this.outlookStore.isDataLoading = false;
      }, 650 - (endLoadingAt-startLoadingAt));
    }

    if(id.startsWith('USA/WPC/EXCESSIVE_RAINFALL')) {
      const copy = { ...geojson}
      copy.features = geojson.features.map(f => {
        f.properties['line-sort-key'] = f.properties['DN'];
        f.properties['fill-sort-key'] = f.properties['DN'];
        const darkenedColor = colord(f.properties['fill']).darken(0.15).toHex()
        f.properties['line-color'] = darkenedColor;
        f.properties['fill-color'] = f.properties['fill'];
        f.properties['line-opacity'] = 1;
        f.properties['fill-opacity'] = 1;
        f.properties['line-width'] = 2;

        return f;
      });

      this.map.getSource(this.sourceHatchedId).setData({
        type: 'FeatureCollection',
        features: []
      })
      this.map.getSource(this.sourceNonHatchedId).setData(copy)
    }
    else if(id.startsWith('USA/WPC/QPF')) {
      const copy = { ...geojson};

      copy.features = geojson.features.map(f => {
        f.properties['line-sort-key'] = f.properties['DN'];
        f.properties['fill-sort-key'] = f.properties['DN'];
        f.properties['line-color'] = f.properties['stroke'];
        f.properties['fill-color'] = f.properties['fill'];
        f.properties['line-opacity'] = 0;
        f.properties['fill-opacity'] = 1;
        f.properties['line-width'] = 0;

        return f;
      });

      this.map.getSource(this.sourceHatchedId).setData({
        type: 'FeatureCollection',
        features: []
      })
      this.map.getSource(this.sourceNonHatchedId).setData(copy)
    }
    else if(id.startsWith('USA/WPC/WSSI') || id.startsWith('USA/WPC/WINTER_WEATHER')) {
      const copy = { ...geojson}
      copy.features = geojson.features.map(f => {
        f.properties['line-sort-key'] = f.properties['DN'];
        f.properties['fill-sort-key'] = f.properties['DN'];
        f.properties['line-color'] = f.properties['fill'];
        f.properties['fill-color'] = f.properties['fill'];
        f.properties['line-opacity'] = 0;
        f.properties['fill-opacity'] = 1;
        f.properties['line-width'] = 0;

        return f;
      });

      this.map.getSource(this.sourceHatchedId).setData({
        type: 'FeatureCollection',
        features: []
      })
      this.map.getSource(this.sourceNonHatchedId).setData(copy)
    }
    else if(id.startsWith('USA/AVALANCHE_ORG/AVALANCHES')) {
      const copy = { ...geojson}
      copy.features = geojson.features.map(f => {
        f.properties['line-sort-key'] = 1000 - f.properties['danger_level'];
        f.properties['fill-sort-key'] = 1000 - f.properties['danger_level'];
        const darkenedColor = colord(f.properties['color']).darken(0.15).toHex()
        f.properties['line-color'] = darkenedColor;
        f.properties['fill-color'] = f.properties['color'];
        f.properties['line-opacity'] = 1;
        f.properties['fill-opacity'] = 0.7;
        f.properties['line-width'] = 1.5;

        return f;
      });

      this.map.getSource(this.sourceHatchedId).setData({
        type: 'FeatureCollection',
        features: []
      })
      this.map.getSource(this.sourceNonHatchedId).setData(copy)
    }
    else if(id.startsWith('USA/CPC/DROUGHT')) {
      const copy = { ...geojson}
      copy.features = geojson.features.map(f => {
        f.properties['line-sort-key'] = f.properties['DN'];
        f.properties['fill-sort-key'] = f.properties['DN'];
        f.properties['line-color'] = f.properties['fill'];
        f.properties['fill-color'] = f.properties['fill'];
        f.properties['line-opacity'] = 0;
        f.properties['fill-opacity'] = 1;
        f.properties['line-width'] = 0;

        return f;
      });

      this.map.getSource(this.sourceHatchedId).setData({
        type: 'FeatureCollection',
        features: []
      })
      this.map.getSource(this.sourceNonHatchedId).setData(copy)
    }
    else {
      // A quick hack...
      const hatched = { ...geojson}
      hatched.features = geojson.features.filter(f => f.properties.LABEL === 'SIGN').map(f => {
        if(f.properties.LABEL === 'SIGN') {
          f.properties['DN'] = 1000;
          f.properties['fill'] = "rgba(0,0,0,0)";
          f.properties['fill-pattern'] = 'dashed-black-hatch-pattern';
        }
        f.properties['line-sort-key'] = f.properties['DN'];
        f.properties['fill-sort-key'] = f.properties['DN'];
        f.properties['line-color'] = f.properties['stroke'];
        f.properties['fill-color'] = f.properties['fill'];
        f.properties['line-opacity'] = 1;
        f.properties['fill-opacity'] = 1;
        f.properties['line-width'] = 2;

        // Hack to set the name
        if(f.properties.name === undefined) {
          if(f.properties.LABEL2 !== undefined) {
            f.properties.name = f.properties.LABEL2;
          }
        }

        return f;
      });

      const nonHatched = { ...geojson};
      nonHatched.features = geojson.features.filter(f => f.properties.LABEL !== 'SIGN').map(f => {
        if(f.properties.LABEL === 'SIGN') {
          f.properties['DN'] = 1000;
          f.properties['fill'] = "rgba(0,0,0,0)";
        }
        f.properties['line-sort-key'] = f.properties['DN'];
        f.properties['fill-sort-key'] = f.properties['DN'];
        f.properties['line-color'] = f.properties['stroke'];
        f.properties['fill-color'] = f.properties['fill'];
        f.properties['line-opacity'] = 1;
        f.properties['fill-opacity'] = 1;
        f.properties['line-width'] = 2;

        // Hack to set the name
        if(f.properties.name === undefined) {
          if(f.properties.LABEL2 !== undefined) {
            f.properties.name = f.properties.LABEL2;
          }
        }

        return f;
      });

      this.map.getSource(this.sourceHatchedId).setData(hatched)
      this.map.getSource(this.sourceNonHatchedId).setData(nonHatched)
    }

    this.renderedId = id;
    this.renderedStep = step;
    this.renderedGeojson = geojson;

    // console.log(geojson);

    const room = `outlook:${id}/${step}`;
    this.activeSocketRooms.push(room)
    socket.roomJoin(room)
    socket.on(room, async (data) => {
      console.log('Outlook update', room, data)

      await this.renderOutlook(id, step);
    });

    this.setColortableBasedOnGeojson(geojson)

    return geojson;
  }

  setColortableBasedOnGeojson(geojson) {
    const outlookId = this.outlookStore.activeOutlookId;

    let colortable = geojson.features.filter(f => {
      if(typeof f.properties.fill !== 'string') return false;

      return f.properties.fill.length > 0;
    });

    colortable = colortable.map(f => {
      const rgb = colord(f.properties.fill).toRgb();

      let name = f.properties.name;

      if(outlookId === 'USA/SPC/CONVECTIVE/CATEGORICAL') {
        name = name.replace('Thunderstorms', '');
      }
      else if(outlookId === 'USA/WPC/EXCESSIVE_RAINFALL/RAINFALL') {
        // As the names can be very long here
        // If there are more than 2 and is a mobile device
        // Then use a 'short name'
        if(colortable.length > 2 && window.innerWidth < 640) {
          name = name.split(/[ ,]+/)[0];
        }
      }

      return {
        color: Object.values(rgb),
        name: name
      }
    });

    if(outlookId.startsWith('USA/WPC/QPF/') || outlookId.startsWith('USA/WPC/WINTER_WEATHER/') || outlookId.startsWith('USA/WPC/WSSI/') || outlookId.startsWith('USA/CPC/DROUGHT/')) {
      let unique = [...new Map(colortable.map(item =>[item['name'], item])).values()]

      colortable = unique;
    }
    else if(outlookId.startsWith('USA/CPC/CLIMATE/')) {
      this.outlookStore.colortable = [];
      return;
    }
    else if(outlookId.startsWith('USA/AVALANCHE_ORG/AVALANCHES/')) {
      colortable = geojson.features.map(f => {
        const rgb = colord(f.properties.color).toRgb();

        return {
          name: capitalizeWords(f.properties.danger),
          color: Object.values(rgb),
          sortIdx: f.properties.danger_level
        }
      });

      let unique = [...new Map(colortable.map(item =>[item['name'], item])).values()]

      unique.sort((a, b) => {
        return a.sortIdx - b.sortIdx;
      });
     
      colortable = unique;
    }
    else {
      if(colortable.length > 4) {
        this.outlookStore.colortable = [];
        return;
      }
    }

    if(colortable.length === 0) {
      colortable.push({
        color: [0,0,0],
        name: 'No data'
      })
    }

    this.outlookStore.colortable = colortable
  }

  stopListeningToRooms() {
    this.activeSocketRooms.forEach(room => {
      socket.roomLeave(room)
      socket.removeAllListeners(room)
    })
    this.activeSocketRooms = []
  }

  clear() {
    this.stopListeningToRooms();

    this.map.getSource(this.sourceHatchedId).setData({
      type: 'FeatureCollection',
      features: []
    })
    this.map.getSource(this.sourceNonHatchedId).setData({
      type: 'FeatureCollection',
      features: []
    })
    this.map.getSource(this.sourceHatchedId).setData({
      type: 'FeatureCollection',
      features: []
    })
  }

  openDiscussionModel(id, step, title, metadata) {
    useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: title
      },
      slots: {
        default: useModalSlot({
          component: OutlookDiscussionModal,
          attrs: {
            id,
            step,
            metadata
          }
        })
      },
    });
  }

  openOutlookHelpModal(outlookId) {
    let h = null;
    let outlook = outlookId.slice(0);
    while(true) {
      h = AllHelp[outlook];

      if(h === undefined) {
        const outlookParts = outlook.split('/');
        outlookParts.pop();
        if(outlookParts.length === 0) break;

        outlook = outlookParts.join('/');
      }
      else {
        break;
      }
    }

    if(h === null || h === undefined) {
      return alert('Unable to locate help information');
    }

    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: h.title
      },
      slots: {
        default: useModalSlot({
          component: OutlookHelpModal,
          attrs: {
            text: h.text,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  setSteps(outlookId) {
    let steps = AllSteps[outlookId];

    if(steps === undefined) steps = [];

    if(typeof steps === 'function') {
      steps = steps();
    }

    // Check if the outlook has a step
    // that is the same as the active step
    if(steps.length > 0 && steps.find(s => s.id === this.outlookStore.activeStep) === undefined) {
      this.outlookStore.activeStep = steps[0].id;
    }

    this.outlookStore.steps = steps;

    return steps;
  }

  show() {
    for(const layerId of [this.lineNonHatchedLayerId, this.fillNonHatchedLayerId, this.lineHatchedLayerId, this.fillHatchedLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }
  }

  hide() {
    for(const layerId of [this.lineNonHatchedLayerId, this.fillNonHatchedLayerId, this.lineHatchedLayerId, this.fillHatchedLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }
}
