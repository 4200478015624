import { defineStore } from 'pinia'
import { enableDarkMode, enableLightMode, disableDarkMode, disableLightMode } from '@/logic/Extra/DarkMode'

export const usePreferencesStore = defineStore('settings/preferences', {
    state: () => ({
        darkMode: 'off',
        leftHanded: false,
    }),

    getters: {
        isLeftHanded: (state) => state.leftHanded,
    },

    actions: {
        setDarkMode(mode) {
            this.darkMode = mode;
            this.applyDarkMode();
        },

        applyDarkMode() {
            // If dark mode is explicitly set, use that setting
            if (this.darkMode === 'on') {
                enableDarkMode();
                return;
            }
            
            if (this.darkMode === 'off') {
                enableLightMode();
                return;
            }

            // Only check system preference if we're in 'auto' mode
            if (this.darkMode === 'auto') {
                // TODO: we added this because of a bug in react-native-webview and auto: https://github.com/react-native-webview/react-native-webview/issues/3013
                // Force dark mode off for now
                this.darkMode = 'off';
                enableLightMode();
            }
        },
        toggleLeftHanded() {
            this.leftHanded = !this.leftHanded;
        }
    },

    persist: true
})
