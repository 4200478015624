<script setup>
import { useRecordingStore } from '@/stores/recording'
</script>

<template>
  <div v-if="recordingStore.recording" id="recording-glow-effect"></div>
</template>

<script>
export default {
  name: 'RecordingGlowEffect',
  data() {
    return {}
  },
  computed: {
    recordingStore: () => useRecordingStore(),
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped>
#recording-glow-effect {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  pointer-events: none;
  animation: recording-pulsate 2s ease-out infinite;
  z-index: 1000;
}

@keyframes recording-pulsate {
  0%   { box-shadow: inset 0 0 0 red; }
  50%  { box-shadow: inset 0 0 30px red; }
  100% { box-shadow: inset 0 0 0 red; }
}
</style>
