export const CATEGORY_STORMBASED = 'stormbased';
export const CATEGORY_WINTER = 'winter';

export const warningConfig = {
  'Tsunami Warning.W': {
    name: 'Tsunami Warning',
    category: '',
    priority: 1,
    color: '#FD6347',
    icon: '',
    help: ``
  },
  'TO.W': {
    name: 'Tornado Warning',
    category: CATEGORY_STORMBASED,
    priority: 2,
    color: '#FF0000',
    icon: 'bi:tornado',
    help: `A Tornado Warning is issued when a tornado has been spotted by trained weather observers or detected by the WSR-88D radar. When this happens, residents in the affected area should take immediate shelter. Tornado Warnings can be issued even if a Tornado Watch is not already in place, and they typically last about 30 minutes.

Your local National Weather Service office (NWFO) issues Tornado Warnings, which detail where the tornado was detected and which towns are in its projected path. If the tornado could impact coastal or nearshore waters, the warning will be combined with a Special Marine Warning. In cases where the storm causing the tornado is also producing heavy rain, the warning may also be combined with a Flash Flood Warning. A warning that includes an ampersand (&) symbol at the bottom indicates it was based on a confirmed severe weather report.

Once the warning is issued, the NWFO will provide updates through Severe Weather Statements. These statements will offer current information on the tornado and announce when the warning is no longer in effect.`
  },
  'EW.W': {
    name: 'Extreme Wind Warning',
    category: '',
    priority: 3,
    color: '#FF8C00',
    icon: 'carbon:windy-strong',
    help: `An Extreme Wind Warning (EWW) is an urgent alert issued by the National Weather Service (NWS) to warn the public of imminent, life-threatening winds associated with a major weather event, such as a landfalling hurricane or intense derecho. These warnings are typically issued when sustained winds of 115 mph (185 km/h) or higher are expected within the next hour, posing a significant threat to life and property. The EWW urges individuals in the affected area to take immediate shelter in an interior room on the lowest floor of a sturdy building, as flying debris and structural damage are highly likely. Extreme Wind Warnings are usually short-lived, covering specific locations for a brief period, but they emphasize the critical need for rapid protective action.`
  },
  'SV.W': {
    name: 'Severe Thunderstorm Warning',
    category: CATEGORY_STORMBASED,
    priority: 4,
    color: '#FFA500',
    icon: 'uil:thunderstorm',
    help: `A Severe Thunderstorm Warning is issued when the WSR-88D radar detects a severe thunderstorm, or when a spotter reports a storm producing hail of one inch or larger in diameter, and/or winds of 58 miles per hour or higher. People in the affected area should seek shelter immediately, as severe thunderstorms can produce tornadoes with little or no warning. Lightning frequency is not a factor in issuing this warning. Typically, these warnings last for about an hour and can be issued even without a preceding Severe Thunderstorm Watch.

Similar to a Tornado Warning, Severe Thunderstorm Warnings are issued by your local National Weather Service Forecast Office (NWFO). They provide details about the storm's location, the towns it may impact, and the primary threats associated with it. If the storm is expected to affect nearshore or coastal waters, the warning may be combined with a Special Marine Warning. Additionally, if torrential rains accompany the storm, the warning might also include a Flash Flood Warning. An ampersand (&) at the bottom of the warning indicates that it was based on a severe weather report.

Once issued, the NWFO will periodically update the public with Severe Weather Statements, providing the latest information on the storm and announcing when the warning is no longer in effect.`
  },
  'FF.W': {
    name: 'Flash Flood Warning',
    category: CATEGORY_STORMBASED,
    priority: 5,
    color: '#79ff26',
    icon: 'material-symbols:flood',
    help: `This is issued to notify the public, emergency management, and cooperating agencies when flash flooding is either currently happening, imminent, or very likely in the near future, based on data from Doppler radar, stream gauges, or reports from Skywarn spotters or local emergency officials.

    A flash flood occurs within six hours of heavy rainfall and poses significant risks to life and property, especially fin low-lying, flood-prone areas like urban zones with poor drainage, rivers, and streams. Other causes, such as ice jams or dam failures, can also trigger flash floods.

    These warnings are issued by the local Weather Forecast Office for specific counties (or their equivalents) and typically last between two and six hours, though they may extend longer during tropical cyclones or occasionally be shorter than two hours.`
  },
  'Flash Flood Statement.S': {
    name: 'Flash Flood Statement',
    category: '',
    priority: 6,
    color: '#8B0000',
    icon: '',
    help: ``
  },
  'Severe Weather Statement.S': {
    name: 'Severe Weather Statement',
    category: CATEGORY_STORMBASED,
    priority: 7,
    color: '#00FFFF',
    icon: '',
    help: ``
  },
  'Shelter In Place Warning.W': {
    name: 'Shelter In Place Warning',
    category: '',
    priority: 8,
    color: '#FA8072',
    icon: '',
    help: ``
  },
  'Civil Danger Warning.W': {
    name: 'Civil Danger Warning',
    category: '',
    priority: 10,
    color: '#FFB6C1',
    icon: '',
    help: ``
  },
  'Nuclear Power Plant Warning.W': {
    name: 'Nuclear Power Plant Warning',
    category: '',
    priority: 11,
    color: '#4B0082',
    icon: '',
    help: ``
  },
  'Radiological Hazard Warning.W': {
    name: 'Radiological Hazard Warning',
    category: '',
    priority: 12,
    color: '#4B0082',
    icon: '',
    help: ``
  },
  'Hazardous Materials Warning.W': {
    name: 'Hazardous Materials Warning',
    category: '',
    priority: 13,
    color: '#4B0082',
    icon: '',
    help: ``
  },
  'Fire Warning.W': {
    name: 'Fire Warning',
    category: '',
    priority: 14,
    color: '#A0522D',
    icon: '',
    help: ``
  },
  'Law Enforcement Warning.W': {
    name: 'Law Enforcement Warning',
    category: '',
    priority: 16,
    color: '#C0C0C0',
    icon: '',
    help: ``
  },
  'Storm Surge Warning.W': {
    name: 'Storm Surge Warning',
    category: '',
    priority: 17,
    color: '#B524F7',
    icon: '',
    help: ``
  },
  'Hurricane Force Wind Warning.W': {
    name: 'Hurricane Force Wind Warning',
    category: '',
    priority: 18,
    color: '#CD5C5C',
    icon: '',
    help: ``
  },
  'Hurricane Warning.W': {
    name: 'Hurricane Warning',
    category: '',
    priority: 19,
    color: '#DC143C',
    icon: '',
    help: ``
  },
  'Typhoon Warning.W': {
    name: 'Typhoon Warning',
    category: '',
    priority: 20,
    color: '#DC143C',
    icon: '',
    help: ``
  },
  'MA.W': {
    name: 'Special Marine Warning',
    category: CATEGORY_STORMBASED,
    priority: 21,
    color: '#731e56',
    icon: 'carbon:marine-warning',
    help: `A warning issued for potentially hazardous weather conditions, typically lasting up to 2 hours, that involve sustained marine thunderstorm winds or gusts of 34 knots or more, hail 3/4 inch in diameter or larger, or waterspouts.

    This warning applies to areas covered by a Coastal Waters Forecast, Nearshore Marine Forecast, or Great Lakes Open Lakes Forecast when existing marine warnings are insufficient. It is also used for short-term mesoscale events, such as strong cold fronts, gravity waves, or squall lines, that produce winds or gusts of 34 knots or greater and last less than 2 hours.`
  },
  'BZ.W': {
    name: 'Blizzard Warning',
    category: CATEGORY_WINTER,
    priority: 22,
    color: '#FF4500',
    icon: 'f7:wind-snow',
    help: `A Blizzard Warning is issued by the National Weather Service (NWS) to alert the public of severe winter weather characterized by sustained winds or frequent gusts of at least 35 mph, accompanied by heavy snowfall or blowing snow, reducing visibility to a quarter-mile or less for at least three hours. These dangerous conditions can cause whiteouts, make travel nearly impossible, and pose life-threatening risks. Residents should avoid travel, stay indoors, and prepare for potential power outages and extended disruptions.`
  },
  'SQ.W': {
    name: 'Snow Squall Warning',
    category: CATEGORY_WINTER,
    priority: 23,
    color: '#C71585',
    icon: 'mingcute:snow-line',
    help: `A Snow Squall Warning is issued by the National Weather Service (NWS) to alert the public of intense, short-lived bursts of heavy snow and strong winds that significantly reduce visibility, often to near whiteout conditions. These dangerous conditions can develop quickly and may result in sudden icy roadways, making travel extremely hazardous. Snow squalls are typically localized and brief but can cause rapid deterioration in weather conditions, particularly during cold weather events.`
  },
  'IS.W': {
    name: 'Ice Storm Warning',
    category: CATEGORY_WINTER,
    priority: 24,
    color: '#8B008B',
    icon: 'mingcute:rainstorm-fill',
    help: `An Ice Storm Warning is issued by the National Weather Service (NWS) to alert the public of significant ice accumulation from freezing rain, typically 0.25 inches or more. These conditions can make travel extremely hazardous, damage trees and power lines, and lead to widespread power outages. The warning indicates that severe icing is imminent or occurring, and residents should avoid travel, prepare for possible disruptions, and take precautions to stay safe.`
  },
  'Heavy Freezing Spray Warning.W': {
    name: 'Heavy Freezing Spray Warning',
    category: '',
    priority: 25,
    color: '#00BFFF',
    icon: '',
    help: ``
  },
  'WS.W': {
    name: 'Winter Storm Warning',
    category: CATEGORY_WINTER,
    priority: 26,
    color: '#FF69B4',
    icon: 'mingcute:snow-line',
    help: `A Winter Storm Warning is issued by the National Weather Service (NWS) to alert the public of significant winter weather events that are expected or occurring. These events typically include heavy snowfall, sleet, or freezing rain that can create dangerous travel conditions, power outages, and other life-threatening impacts. The warning signifies that severe winter weather is imminent, ongoing, or highly likely, and people in the affected areas should take immediate precautions to stay safe.`
  },
  'LE.W': {
    name: 'Lake Effect Snow Warning',
    category: CATEGORY_WINTER,
    priority: 27,
    color: '#008B8B',
    icon: 'wpf:snow',
    help: `A Lake Effect Snow Warning is issued by the National Weather Service (NWS) to alert the public of heavy snowfalls caused by cold air passing over a warmer lake surface. This phenomenon can produce intense and localized snow bands, often leading to rapid accumulations and hazardous travel conditions. The warning indicates that significant snowfall is expected or occurring, and people in the affected areas should prepare for dangerous driving conditions and limited visibility.`
  },
  'Dust Storm Warning.W': {
    name: 'Dust Storm Warning',
    category: '',
    priority: 28,
    color: '#FFE4C4',
    icon: '',
    help: ``
  },
  'Blowing Dust Warning.W': {
    name: 'Blowing Dust Warning',
    category: '',
    priority: 29,
    color: '#FFE4C4',
    icon: '',
    help: ``
  },
  'High Wind Warning.W': {
    name: 'High Wind Warning',
    category: '',
    priority: 30,
    color: '#DAA520',
    icon: '',
    help: ``
  },
  'Tropical Storm Warning.W': {
    name: 'Tropical Storm Warning',
    category: '',
    priority: 31,
    color: '#B22222',
    icon: '',
    help: ``
  },
  'Storm Warning.W': {
    name: 'Storm Warning',
    category: '',
    priority: 32,
    color: '#9400D3',
    icon: '',
    help: ``
  },
  'Tsunami Advisory.Y': {
    name: 'Tsunami Advisory',
    category: '',
    priority: 33,
    color: '#D2691E',
    icon: '',
    help: ``
  },
  'Tsunami Watch.A': {
    name: 'Tsunami Watch',
    category: '',
    priority: 34,
    color: '#FF00FF',
    icon: '',
    help: ``
  },
  'AV.W': {
    name: 'Avalanche Warning',
    category: CATEGORY_WINTER,
    priority: 35,
    color: '#1E90FF',
    icon: 'hugeicons:avalanche',
    help: `An Avalanche Warning is issued by the National Weather Service (NWS) or avalanche forecasting agencies to alert the public of extremely dangerous avalanche conditions in mountainous areas. This warning indicates that avalanches are very likely or already occurring, and travel in avalanche-prone terrain should be avoided. People in or near affected areas should take immediate precautions to protect lives and property, including avoiding backcountry activities and following local safety guidance.`
  },
  'Earthquake Warning.W': {
    name: 'Earthquake Warning',
    category: '',
    priority: 36,
    color: '#8B4513',
    icon: '',
    help: ``
  },
  'Volcano Warning.W': {
    name: 'Volcano Warning',
    category: '',
    priority: 37,
    color: '#2F4F4F',
    icon: '',
    help: ``
  },
  'Ashfall Warning.W': {
    name: 'Ashfall Warning',
    category: '',
    priority: 38,
    color: '#A9A9A9',
    icon: '',
    help: ``
  },
  'Flood Warning.W': {
    name: 'Flood Warning',
    category: '',
    priority: 39,
    color: '#00FF00',
    icon: '',
    help: ``
  },
  'Coastal Flood Warning.W': {
    name: 'Coastal Flood Warning',
    category: '',
    priority: 40,
    color: '#228B22',
    icon: '',
    help: ``
  },
  'Lakeshore Flood Warning.W': {
    name: 'Lakeshore Flood Warning',
    category: '',
    priority: 41,
    color: '#228B22',
    icon: '',
    help: ``
  },
  'Ashfall Advisory.Y': {
    name: 'Ashfall Advisory',
    category: '',
    priority: 42,
    color: '#696969',
    icon: '',
    help: ``
  },
  'High Surf Warning.W': {
    name: 'High Surf Warning',
    category: '',
    priority: 43,
    color: '#228B22',
    icon: '',
    help: ``
  },
  'Excessive Heat Warning.W': {
    name: 'Excessive Heat Warning',
    category: '',
    priority: 44,
    color: '#C71585',
    icon: '',
    help: ``
  },
  'TO.A': {
    name: 'Tornado Watch',
    category: CATEGORY_STORMBASED,
    priority: 45,
    color: '#FFFF00',
    icon: 'bi:tornado',
    help: `A Tornado Watch is issued by the National Weather Service when conditions are conducive to the formation of tornadoes in and near the designated area. The size of the watch area varies depending on the specific weather situation and is typically issued for 4 to 8 hours. Watches are generally released well ahead of severe weather to give people time to prepare. During this time, individuals should review tornado safety protocols and be ready to seek shelter if threatening weather develops.

The Storm Prediction Center (SPC) in Norman, Oklahoma, is responsible for issuing Tornado Watches. Before doing so, the SPC usually consults with the local National Weather Forecast Office (NWFO) to discuss current weather conditions. Once a preliminary Tornado Watch is issued by the SPC, the local NWFO may adjust the watch area, adding or removing counties as necessary, and then release it to the public. The NWFO keeps the public updated on conditions in the watch area and announces when the watch expires or is canceled, often through a Watch Redefining Statement.`
  },
  'SV.A': {
    name: 'Severe Thunderstorm Watch',
    category: CATEGORY_STORMBASED,
    priority: 46,
    color: '#DB7093',
    icon: 'uil:thunderstorm',
    help: `The National Weather Service issues a Severe Thunderstorm Watch when conditions are favorable for the formation of severe thunderstorms in or near the designated area. A severe thunderstorm is defined as one that produces hail at least one inch in diameter and/or winds of 58 miles per hour or greater. The size of the watch area can vary depending on the weather situation and is typically in effect for 4 to 8 hours. These watches are issued well before severe weather is expected, giving people time to review safety guidelines and be ready to move to a safe location if threatening weather approaches.

Severe Thunderstorm Watches are issued by the Storm Prediction Center (SPC) in Norman, Oklahoma. Before a watch is issued, the SPC typically consults with the local National Weather Service Forecast Office (NWFO) to assess the weather situation. After this, the SPC issues a preliminary watch, which the local NWFO can adjust by adding or removing counties or parishes before releasing it to the public through a Watch Redefining Statement. Throughout the watch period, the NWFO will keep the public updated on conditions in the watch area and notify them when the watch is canceled or expires.`
  },
  'FF.A': {
    name: 'Flash Flood Watch',
    category: CATEGORY_STORMBASED,
    priority: 47,
    color: '#2E8B57',
    icon: 'material-symbols:flood',
    help: `Issued to highlight current or developing hydrologic conditions that increase the likelihood of flash flooding in and around the watch area, though the event is not guaranteed or immediately expected.`
  },
  'Gale Warning.W': {
    name: 'Gale Warning',
    category: '',
    priority: 48,
    color: '#DDA0DD',
    icon: '',
    help: ``
  },
  'Flood Statement.S': {
    name: 'Flood Statement',
    category: '',
    priority: 49,
    color: '#00FF00',
    icon: '',
    help: ``
  },
  'EC.W': {
    name: 'Extreme Cold Warning',
    category: CATEGORY_WINTER,
    priority: 50,
    color: '#0000FF',
    icon: 'hugeicons:thermometer-cold',
    help: `An Extreme Cold Warning is issued by the National Weather Service (NWS) to alert the public of dangerously cold temperatures or wind chills that pose a risk of frostbite, hypothermia, and other cold-related health issues. This warning signifies that conditions are severe and could be life-threatening if precautions are not taken. Residents in the affected area should limit outdoor exposure, dress in warm layers, and ensure proper heating indoors.`
  },
  'FZ.W': {
    name: 'Freeze Warning',
    category: CATEGORY_WINTER,
    priority: 51,
    color: '#483D8B',
    icon: 'mdi:freeze-advisory',
    help: `A Freeze Warning is issued by the National Weather Service (NWS) to alert the public that temperatures are expected to drop to 32°F (0°C) or below during the growing season. These conditions can harm or kill crops, plants, and other sensitive vegetation. Residents in the warning area should take immediate steps to protect plants, outdoor pipes, and other freeze-vulnerable items.`
  },
  'Red Flag Warning.W': {
    name: 'Red Flag Warning',
    category: '',
    priority: 52,
    color: '#FF1493',
    icon: '',
    help: ``
  },
  'Storm Surge Watch.A': {
    name: 'Storm Surge Watch',
    category: '',
    priority: 53,
    color: '#DB7FF7',
    icon: '',
    help: ``
  },
  'Hurricane Watch.A': {
    name: 'Hurricane Watch',
    category: '',
    priority: 54,
    color: '#FF00FF',
    icon: '',
    help: ``
  },
  'Hurricane Force Wind Watch.A': {
    name: 'Hurricane Force Wind Watch',
    category: '',
    priority: 55,
    color: '#9932CC',
    icon: '',
    help: ``
  },
  'Typhoon Watch.A': {
    name: 'Typhoon Watch',
    category: '',
    priority: 56,
    color: '#FF00FF',
    icon: '',
    help: ``
  },
  'Tropical Storm Watch.A': {
    name: 'Tropical Storm Watch',
    category: '',
    priority: 57,
    color: '#F08080',
    icon: '',
    help: ``
  },
  'Storm Watch.A': {
    name: 'Storm Watch',
    category: '',
    priority: 58,
    color: '#FFE4B5',
    icon: '',
    help: ``
  },
  'Tropical Cyclone Local Statement.S': {
    name: 'Tropical Cyclone Local Statement',
    category: '',
    priority: 59,
    color: '#FFE4B5',
    icon: '',
    help: ``
  },
  'WW.Y': {
    name: 'Winter Weather Advisory',
    category: CATEGORY_WINTER,
    priority: 60,
    color: '#7B68EE',
    icon: 'mingcute:snow-line',
    help: `A Winter Weather Advisory is issued by the National Weather Service (NWS) to inform the public of less severe but still impactful winter weather conditions, such as light snow, sleet, or freezing rain. While not as dangerous as a warning, these conditions can still cause travel disruptions and safety hazards. The advisory urges people to exercise caution and be prepared for potential inconveniences or minor impacts, especially on roads and sidewalks.`
  },
  'Avalanche Advisory.Y': {
    name: 'Avalanche Advisory',
    category: '',
    priority: 61,
    color: '#CD853F',
    icon: '',
    help: ``
  },
  'CW.Y': {
    name: 'Cold Weather Advisory',
    category: CATEGORY_WINTER,
    priority: 62,
    color: '#AFEEEE',
    icon: 'ph:thermometer-cold',
    help: `A Cold Weather Advisory is issued by the National Weather Service (NWS) to alert the public of lower-than-normal temperatures that, while not extreme, may pose risks to vulnerable populations, such as the elderly, children, or those without adequate heating. These conditions could lead to health concerns like frostbite or hypothermia with prolonged exposure. Residents should take precautions, dress warmly, and ensure heating systems are functioning properly.`
  },
  'Heat Advisory.Y': {
    name: 'Heat Advisory',
    category: '',
    priority: 63,
    color: '#FF7F50',
    icon: '',
    help: ``
  },
  'Flood Advisory.Y': {
    name: 'Flood Advisory',
    category: '',
    priority: 64,
    color: '#00FF7F',
    icon: '',
    help: ``
  },
  'Coastal Flood Advisory.Y': {
    name: 'Coastal Flood Advisory',
    category: '',
    priority: 65,
    color: '#7CFC00',
    icon: '',
    help: ``
  },
  'Lakeshore Flood Advisory.Y': {
    name: 'Lakeshore Flood Advisory',
    category: '',
    priority: 66,
    color: '#7CFC00',
    icon: '',
    help: ``
  },
  'High Surf Advisory.Y': {
    name: 'High Surf Advisory',
    category: '',
    priority: 67,
    color: '#BA55D3',
    icon: '',
    help: ``
  },
  'Dense Fog Advisory.Y': {
    name: 'Dense Fog Advisory',
    category: '',
    priority: 68,
    color: '#708090',
    icon: '',
    help: ``
  },
  'Dense Smoke Advisory.Y': {
    name: 'Dense Smoke Advisory',
    category: '',
    priority: 69,
    color: '#F0E68C',
    icon: '',
    help: ``
  },
  'Small Craft Advisory.Y': {
    name: 'Small Craft Advisory',
    category: '',
    priority: 70,
    color: '#D8BFD8',
    icon: '',
    help: ``
  },
  'Brisk Wind Advisory.Y': {
    name: 'Brisk Wind Advisory',
    category: '',
    priority: 71,
    color: '#D8BFD8',
    icon: '',
    help: ``
  },
  'Hazardous Seas Warning.W': {
    name: 'Hazardous Seas Warning',
    category: '',
    priority: 72,
    color: '#D8BFD8',
    icon: '',
    help: ``
  },
  'Dust Advisory.Y': {
    name: 'Dust Advisory',
    category: '',
    priority: 73,
    color: '#BDB76B',
    icon: '',
    help: ``
  },
  'Blowing Dust Advisory.Y': {
    name: 'Blowing Dust Advisory',
    category: '',
    priority: 74,
    color: '#BDB76B',
    icon: '',
    help: ``
  },
  'Lake Wind Advisory.Y': {
    name: 'Lake Wind Advisory',
    category: '',
    priority: 75,
    color: '#D2B48C',
    icon: '',
    help: ``
  },
  'Wind Advisory.Y': {
    name: 'Wind Advisory',
    category: '',
    priority: 76,
    color: '#D2B48C',
    icon: '',
    help: ``
  },
  'FR.Y': {
    name: 'Frost Advisory',
    category: CATEGORY_WINTER,
    priority: 77,
    color: '#6495ED',
    icon: 'mingcute:snow-line',
    help: `A Frost Advisory is issued by the National Weather Service (NWS) to alert the public of expected frost conditions, typically during the growing season, when temperatures are forecasted to drop between 33°F and 36°F (1°C to 2°C). Frost can harm or kill sensitive plants and vegetation. Residents in the advisory area should take precautions, such as covering plants or bringing them indoors, to minimize potential damage.`
  },
  'Freezing Fog Advisory.Y': {
    name: 'Freezing Fog Advisory',
    category: '',
    priority: 78,
    color: '#008080',
    icon: '',
    help: ``
  },
  'Freezing Spray Advisory.Y': {
    name: 'Freezing Spray Advisory',
    category: '',
    priority: 79,
    color: '#00BFFF',
    icon: '',
    help: ``
  },
  'Low Water Advisory.Y': {
    name: 'Low Water Advisory',
    category: '',
    priority: 80,
    color: '#A52A2A',
    icon: '',
    help: ``
  },
  'WS.A': {
    name: 'Winter Storm Watch',
    category: CATEGORY_WINTER,
    priority: 82,
    color: '#4682B4',
    icon: 'mingcute:snow-line',
    help: `A Winter Storm Watch is issued by the National Weather Service (NWS) to alert the public of the potential for significant winter weather, such as heavy snow, sleet, or freezing rain, within the next 24 to 48 hours. It signals that while conditions are favorable for a severe winter storm, its occurrence, timing, and exact impacts remain uncertain. Those in the watch area should monitor forecasts closely and prepare for possible hazardous conditions.`
  },
  'Rip Current Statement.S': {
    name: 'Rip Current Statement',
    category: '',
    priority: 83,
    color: '#40E0D0',
    icon: '',
    help: ``
  },
  'Beach Hazards Statement.S': {
    name: 'Beach Hazards Statement',
    category: '',
    priority: 84,
    color: '#40E0D0',
    icon: '',
    help: ``
  },
  'Gale Watch.A': {
    name: 'Gale Watch',
    category: '',
    priority: 85,
    color: '#FFC0CB',
    icon: '',
    help: ``
  },
  'AV.A': {
    name: 'Avalanche Watch',
    category: CATEGORY_WINTER,
    priority: 86,
    color: '#F4A460',
    icon: 'hugeicons:avalanche',
    help: `An Avalanche Watch is issued by the National Weather Service (NWS) or avalanche forecasting agencies to alert the public of the potential for dangerous avalanche conditions within the next 24 to 48 hours. It signifies that weather factors, such as heavy snowfall or rapid temperature changes, could lead to an increased avalanche risk in mountainous areas. People in or near affected regions should monitor updates, avoid avalanche-prone terrain, and be prepared to take safety precautions.`
  },
  'Hazardous Seas Watch.A': {
    name: 'Hazardous Seas Watch',
    category: '',
    priority: 87,
    color: '#483D8B',
    icon: '',
    help: ``
  },
  'Heavy Freezing Spray Watch.A': {
    name: 'Heavy Freezing Spray Watch',
    category: '',
    priority: 88,
    color: '#BC8F8F',
    icon: '',
    help: ``
  },
  'Flood Watch.A': {
    name: 'Flood Watch',
    category: '',
    priority: 89,
    color: '#2E8B57',
    icon: '',
    help: ``
  },
  'Coastal Flood Watch.A': {
    name: 'Coastal Flood Watch',
    category: '',
    priority: 90,
    color: '#66CDAA',
    icon: '',
    help: ``
  },
  'Lakeshore Flood Watch.A': {
    name: 'Lakeshore Flood Watch',
    category: '',
    priority: 91,
    color: '#66CDAA',
    icon: '',
    help: ``
  },
  'High Wind Watch.A': {
    name: 'High Wind Watch',
    category: '',
    priority: 92,
    color: '#B8860B',
    icon: '',
    help: ``
  },
  'Excessive Heat Watch.A': {
    name: 'Excessive Heat Watch',
    category: '',
    priority: 93,
    color: '#800000',
    icon: '',
    help: ``
  },
  'EC.A': {
    name: 'Extreme Cold Watch',
    category: CATEGORY_WINTER,
    priority: 94,
    color: '#5F9EA0',
    icon: 'hugeicons:thermometer-cold',
    help: `An Extreme Cold Watch is issued by the National Weather Service (NWS) to alert the public of the potential for dangerously cold temperatures or wind chills within the next 24 to 48 hours. These conditions could lead to frostbite, hypothermia, and other cold-related health risks if precautions are not taken. Residents should monitor forecasts, prepare for extreme cold, and plan to limit outdoor activities during the affected period.`
  },
  'FZ.A': {
    name: 'Freeze Watch',
    category: CATEGORY_WINTER,
    priority: 95,
    color: '#00FFFF',
    icon: 'mdi:freeze-advisory',
    help: `A Freeze Watch is issued by the National Weather Service (NWS) to alert the public of the potential for freezing temperatures within the next 24 to 48 hours. This advisory is typically issued during the growing season when temperatures near or below 32°F (0°C) could damage or kill crops, plants, and other sensitive vegetation. People in the watch area should prepare to protect vulnerable plants, outdoor pipes, and other freeze-sensitive items.`
  },
  'Fire Weather Watch.A': {
    name: 'Fire Weather Watch',
    category: '',
    priority: 96,
    color: '#FFDEAD',
    icon: '',
    help: ``
  },
  'Coastal Flood Statement.S': {
    name: 'Coastal Flood Statement',
    category: '',
    priority: 99,
    color: '#6B8E23',
    icon: '',
    help: ``
  },
  'Lakeshore Flood Statement.S': {
    name: 'Lakeshore Flood Statement',
    category: '',
    priority: 100,
    color: '#6B8E23',
    icon: '',
    help: ``
  },
  'SP.S': {
    name: 'Special Weather Statement',
    category: CATEGORY_STORMBASED,
    priority: 101,
    color: '#00FFFF',
    icon: 'mdi:weather-cloudy-alert',
    help: `An official National Weather Service product that provides follow-up information on severe weather events—such as severe thunderstorms and tornadoes—that have occurred or are currently occurring.`
  },
  'Marine Weather Statement.S': {
    name: 'Marine Weather Statement',
    category: '',
    priority: 102,
    color: '#FFDAB9',
    icon: '',
    help: ``
  },
  'Air Stagnation Advisory.Y': {
    name: 'Air Stagnation Advisory',
    category: '',
    priority: 104,
    color: '#808080',
    icon: '',
    help: ``
  },
  'Hazardous Weather Outlook.O': {
    name: 'Hazardous Weather Outlook',
    category: '',
    priority: 105,
    color: '#EEE8AA',
    icon: '',
    help: ``
  },
  'Hydrologic Outlook.O': {
    name: 'Hydrologic Outlook',
    category: '',
    priority: 106,
    color: '#90EE90',
    icon: '',
    help: ``
  },
  'Short Term Forecast.F': {
    name: 'Short Term Forecast',
    category: '',
    priority: 107,
    color: '#98FB98',
    icon: '',
    help: ``
  }
}

export const getList = () => {
  const list = [];

  for(const key in warningConfig) {
    if(key.length !== 4) continue;

    const config = { ...warningConfig[key] };
    config.id = key;

    const keySplit = key.split('.');

    config.country_iso = 'USA';
    config.product = keySplit[0];
    config.significance = keySplit[1];

    list.push(config);
  }

  return list;
}

export const getConfigForSetting = (key) => {
  const c = warningConfig[key];

  return {
    enabled: true,
    color: c.color,
    line_width: 2.5,
    fill_opacity: 0.4
  };
};

export const getConfigForSettings = () => {
  const config = {};

  for(const key in warningConfig) {
    if(key.length !== 4) continue;

    config[key] = getConfigForSetting(key);
  }

  return config;
};

export const codesForSignificance = (sig) => {
  const codes = [];

  for(const key in warningConfig) {
    if(key.length !== 4) continue;

    if(key.endsWith(`.${sig}`)) {
      codes.push(key.substr(0, 2));
    }
  }

  return codes;
};



