class InMemoryCache {
  constructor() {
    this.cache = new Map();
  }

  // Set a value for a key
  set(key, value) {
    this.cache.set(key, value);
  }

  // Get a value by key
  get(key) {
    return this.cache.get(key) || null;
  }

  // Delete a value by key
  delete(key) {
    this.cache.delete(key);
  }

  // Check if a key exists
  has(key) {
    return this.cache.has(key);
  }

  // Clear the entire cache
  clear() {
    this.cache.clear();
  }
}

export default new InMemoryCache();
