<script setup>
import { useRadarTowersStore } from '@/stores/radar_towers'

const radarTowersStore = useRadarTowersStore()
</script>

<template>
    <div class="text-xs">
        <template v-if="radarTowersStore.scanDatetime === null">Loading...</template>
        <template v-else>
            <span class="">Scan At: </span>
            <span v-text="radarTowersStore.scanLocalTime" :title="`UTC: ${radarTowersStore.scanDatetime}`"
                class="font-bold" :class="{
                'bg-red-100 text-red-800 px-2.5 py-0.5 rounded-full': !radarTowersStore.isPlaying && radarTowersStore.scanIsOld && !radarTowersStore.hasBufferedScans
              }"></span>
            <span v-if="!radarTowersStore.isPlaying && radarTowersStore.scanIsNew && !radarTowersStore.hasBufferedScans"
                class="font-black text-red-800 dark:text-red-500 live-text ml-2">Live</span>
        </template>
    </div>
</template>
