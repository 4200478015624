import { useRadarTowersStore } from '@/stores/radar_towers';
import { storeToRefs } from 'pinia';

const useRadarPlayback = () => {
    const radarTowersStore = useRadarTowersStore()

    const {isPlaybackBuffering} = storeToRefs(radarTowersStore);

    const togglePlayback = async () => {
        const isPlaying = radarTowersStore.isPlaying;
        const towerId = radarTowersStore.activeTowerId;
        const product = radarTowersStore.activeProductCode;

        if (towerId === '' || product === '') return;

        if (isPlaying) {
            radarTowersStore.isPlaying = false;
        } else {

            try {
                isPlaybackBuffering.value = true;
                await window.map.radar.loadHistory(towerId, product);
                window.map.radar.playScans(towerId, product);
            } catch(e) {
                console.error('Failed to buffer radar scans', e);
            } finally {
                isPlaybackBuffering.value = false;
            }
        }
    }

    return {
        togglePlayback
    }
}

export { useRadarPlayback }