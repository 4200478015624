const html = document.documentElement;

const enableDarkMode = () => {
    html.classList.remove('light');
    html.classList.add('dark');
}

const enableLightMode = () => {
    html.classList.remove('dark');
    html.classList.add('light');
}

const disableDarkMode = () => {
    html.classList.remove('dark');
}

const disableLightMode = () => {
    html.classList.remove('light');
}

export { enableDarkMode, enableLightMode, disableDarkMode, disableLightMode }
