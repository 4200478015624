import { mande } from 'mande'

import UrlHash from '@/tools/url-hash'

class Api {
  constructor() {
    this.api = mande(this.url(), { headers: { 'Content-Type': null } })
  }

  url() {
    const params = new UrlHash();
    if(params.has('data_server')) {
      return params.get('data_server')
    }

    return 'https://data2.weatherwise.app';
  }

  instance() {
    return this.api
  }
}

export default new Api()
