<script setup lang="ts">
interface RegionSettings {
  border: {
    thickness: number;
    color: string;
  };
  label: {
    fontSize: number;
    color: string;
    density: number;
  };
}

const props = defineProps<{
  title: string;
  kind: string;
  settings: RegionSettings;
}>();

const emit = defineEmits(['update:settings', 'change']);

const updateSettings = (path: string, value: any) => {
  const newSettings = JSON.parse(JSON.stringify(props.settings));
  const pathParts = path.split('.');
  let current = newSettings;

  for (let i = 0; i < pathParts.length - 1; i++) {
    current = current[pathParts[i]];
  }
  current[pathParts[pathParts.length - 1]] = value;

  emit('update:settings', newSettings);
  emit('change');
};
</script>

<template>
  <div class="my-2">
    <h3 class="text-xl font-bold text-gray-800 dark:text-slate-300 mb-4">{{ title }}</h3>

    <div class="space-y-4">
      <!-- Border Settings -->
      <div class="bg-white/50 dark:bg-ww-blue/50 rounded-lg" v-if="['country', 'state', 'county'].includes(kind)">
        <h4 class="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-3">Borders</h4>
        <div class="grid grid-cols-[1fr_auto] gap-4 items-start">
          <div>
            <label class="block text-sm font-medium text-gray-600 dark:text-gray-400 mb-1 pointer-events-none">
              Thickness
            </label>
            <div class="space-y-1">
              <input
                type="range"
                :value="settings.border.thickness"
                min="0.5"
                max="8"
                step="0.5"
                class="w-full h-2 bg-gray-200 dark:bg-ww-blue-100 rounded-lg appearance-none cursor-pointer slider"
                @input="e => updateSettings('border.thickness', parseFloat((e.target as HTMLInputElement).value))"
              />
              <div class="flex justify-between px-1 pointer-events-none">
                <span class="text-xs text-gray-500 dark:text-gray-400">Thin</span>
                <span class="text-xs font-bold text-gray-500 dark:text-gray-400">{{ settings.border.thickness }}px</span>
                <span class="text-xs text-gray-500 dark:text-gray-400">Thick</span>
              </div>
            </div>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-600 dark:text-gray-400 mb-1 pointer-events-none">
              Color
            </label>
            <input
              type="color"
              :value="settings.border.color"
              class="h-8 w-12 rounded cursor-pointer border border-[#2563eb]"
              @input="e => updateSettings('border.color', (e.target as HTMLInputElement).value)"
            />
          </div>
        </div>
      </div>

      <!-- Label Settings -->
      <div class="bg-white/50 dark:bg-ww-blue/50 rounded-lg" v-if="['country', 'state', 'settlement'].includes(kind)">
        <h4 class="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-3">Labels</h4>
        <div class="space-y-4">
          <div class="grid grid-cols-[1fr_auto] gap-4 items-start">
            <div>
              <label class="block text-sm font-medium text-gray-600 dark:text-gray-400 mb-1 pointer-events-none">
                Font Size
              </label>
              <div class="space-y-1">
                <input
                  type="range"
                  :value="settings.label.fontSize"
                  min="8"
                  max="40"
                  step="1"
                  class="w-full h-2 bg-gray-200 dark:bg-ww-blue-100 rounded-lg appearance-none cursor-pointer slider"
                  @input="e => updateSettings('label.fontSize', parseInt((e.target as HTMLInputElement).value))"
                />
                <div class="flex justify-between px-1 pointer-events-none">
                  <span class="text-xs text-gray-500 dark:text-gray-400">Small</span>
                  <span class="text-xs font-bold text-gray-500 dark:text-gray-400">{{ settings.label.fontSize }}px</span>
                  <span class="text-xs text-gray-500 dark:text-gray-400">Large</span>
                </div>
              </div>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-600 dark:text-gray-400 mb-1 pointer-events-none">
                Color
              </label>
              <input
                type="color"
                :value="settings.label.color"
                class="h-8 w-12 rounded cursor-pointer border border-[#2563eb]"
                @input="e => updateSettings('label.color', (e.target as HTMLInputElement).value)"
              />
            </div>
          </div>

          <div v-if="kind === 'settlement'">
            <label class="block text-sm font-medium text-gray-600 dark:text-gray-400 mb-1 pointer-events-none">
              Density
            </label>
            <div class="space-y-1">
              <input
                type="range"
                :value="settings.label.density"
                min="0"
                max="5"
                step="1"
                class="w-full h-2 bg-gray-200 dark:bg-ww-blue-100 rounded-lg appearance-none cursor-pointer slider"
                @input="e => updateSettings('label.density', parseInt((e.target as HTMLInputElement).value))"
              />
              <div class="flex justify-between px-1 pointer-events-none">
                <span class="text-xs text-gray-500 dark:text-gray-400">Less</span>
                <span class="text-xs font-bold text-gray-500 dark:text-gray-400">{{ settings.label.density }}/5</span>
                <span class="text-xs text-gray-500 dark:text-gray-400">More</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  @apply bg-blue-600 dark:bg-blue-500;
  cursor: pointer;
  border-radius: 10px;
}

.slider::-moz-range-thumb {
  width: 28px;
  height: 28px;
  @apply bg-blue-600 dark:bg-blue-500;
  cursor: pointer;
  border-radius: 10px;
}
</style>
