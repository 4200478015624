<template>
  <Transition name="slide-fade">
    <div class="inspector absolute left-1/2 top-1/2" :class="{
      'radar-drawer-open': shouldApplyYOffset
    }">
      <div class="relative">
        <Icon icon="game-icons:convergence-target" class="inline size-8" />
        <div v-show="active" class="color absolute h-10 w-10" :style="`background-color: ${color}`"></div>
        <div v-show="active" class="value-container absolute">
          <div class="value">{{ value_str }}</div>
          <div class="beam-height">{{ beam_height_kft.toFixed(1) }} kft</div>
        </div>
        <div v-show="!active" class="no-value-container absolute">
          <div class="value">No data</div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { distance } from '@turf/turf'
import { Icon } from '@iconify/vue'

import { beamHeight } from '@/logic/Radar/helpers'

import { useRadarTowersStore } from '@/stores/radar_towers'
import { useAppStore } from '@/stores/app'

import { PRT_VALUE_RANGE, PRT_RAIN_VALUE_MAX, PRT_SNOW_VALUE_MIN, PRT_SNOW_VALUE_MAX, PRT_MIXED_VALUE_MIN, PRT_MIXED_VALUE_MAX } from '@/data/radar_products.js'

import { getHCAClassification } from '@/tools/helpers'

let onMapMovement = null;

export default {
  name: 'RadarValueInspector',
  props: ['colortable'],
  components: {
    Icon,
  },
  data() {
    return {
      active: true,
      color: 'rgba(0,0,0,0)',
      value_str: '',
      beam_height_kft: 0
    }
  },
  watch: {
    // Watch for radar scan changes
    // And then inspect the data
    'radarTowersStore.scanDatetime': {
      handler(newValue) {
        if(newValue !== null) {
          onMapMovement()
        }
      }
    },
    'appStore.radar_drawer_open': {
      handler(newValue) {
        if(newValue) {
          setTimeout(() => {
            onMapMovement();
          }, 310)
        }
        else {
          onMapMovement();
        }
      }
    }
  },
  mounted() {
    // console.log('mounted')

    onMapMovement = () => {
      if(this.radarTowersStore.scanDatetime === null) return;

      const center = window.map.getCenter();
      const centerPixel = map.project(center);

      const aboveCenterPixel = {
        x: centerPixel.x,
        y: centerPixel.y
      };

      if(this.shouldApplyYOffset) {
        // Substract half the height of the radar drawer
        aboveCenterPixel.y-=200;
      }

      const aboveCenterLocation = map.unproject(aboveCenterPixel);

      const point = window.map.radar.renderer.getValueAt(aboveCenterLocation);

      if(point === null) {
        // this.active = false;
        this.color = 'rgba(0,0,0,0)';

        this.value_str = 'No data';
      }
      else {
        // this.active = true;
        this.color = `rgba(${point.rgbaColor?.join(', ')})`

        const groupId = this.radarTowersStore.activeProductGroup.id;

        if(groupId === 'HCA') {
          const classification = getHCAClassification(point.value);

          this.value_str = classification;
        }
        else if(groupId === 'PRT') {
          let value = point.value;
          let prt = 'Rain';
          if(value > PRT_SNOW_VALUE_MIN && value <= PRT_SNOW_VALUE_MAX) {
            prt = 'Snow';
            value-=(PRT_VALUE_RANGE * 1);
          }
          else if(value > PRT_MIXED_VALUE_MIN && value <= PRT_MIXED_VALUE_MAX) {
            prt = 'Mixed';
            value-=(PRT_VALUE_RANGE * 2);
          }

          this.value_str = `${value.toFixed(1)} ${this.radarTowersStore.activeProductGroupUnit} (${prt})`;
        }
        else {
          this.value_str = `${point.value.toFixed(1)} ${this.radarTowersStore.activeProductGroupUnit}`;
        }
      }

      const dist = distance(aboveCenterLocation.toArray(), this.radarTowersStore.activeTower.geometry.coordinates, { units: "meters" })

      // TODO
      // Fix this, as this assumes the radar max range is 400km
      this.active = dist < 400 * 1000;

      const altitude = this.radarTowersStore.activeTower.geometry.coordinates[2] ?? 0;

      // TODO
      // Bug here, fix this...
      // We need to use the real elevation angle (0.5 is typical but not always)
      const bh = altitude + beamHeight(dist, 0.5)

      // Convert meters to kilo-feet
      this.beam_height_kft = bh * 0.00328083989;
    }

    window.map.on('move', onMapMovement);
    window.map.on('zoom', onMapMovement);

    onMapMovement();
  },
  unmounted() {
    // console.log('unmounted')

    window.map.off('move', onMapMovement);
    window.map.off('zoom', onMapMovement);
  },
  computed: {
    radarTowersStore() {
      return useRadarTowersStore()
    },
    appStore() {
      return useAppStore()
    },
    shouldApplyYOffset() {
      return this.appStore.radar_drawer_open && window.innerWidth < 640;
    }
  }
}
</script>

<style scoped>
.inspector {
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-top: -15px;
  pointer-events: none;
}

.inspector.radar-drawer-open {
  transition: margin-top 300ms;
  /* Substract half the height of the radar drawer */
  margin-top: -215px;
}

.inspector .color {
  top: 7px;
  left: 7px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #2C3D50;
}

.inspector .value-container {
  width: 80px;
  margin-left: -25px;
  margin-top: 4px;
  background-color: rgba(255,255,255,0.8);
  border-radius: 20px;
  border: 1px solid #2C3D50;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
}

.inspector .value-container .value {
  font-weight: bold;
}

.inspector .value-container .beam-height {
  font-weight: bold;
}

.inspector .no-value-container {
  width: 60px;
  margin-left: -15px;
  margin-top: 4px;
  background-color: rgba(255,255,255,0.8);
  border-radius: 20px;
  border: 1px solid #2C3D50;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
}
.inspector .no-value-container .value {
  font-weight: bold;
}
</style>
