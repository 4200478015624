<script setup>
import { Icon } from '@iconify/vue'
</script>

<template>
	<h1 class="text-2xl sm:text-4xl font-medium"><Icon :icon="config.icon" class="s-6 sm:s-10 inline mr-3" :style="{ color: config.color }" />{{ config.name }}</h1>
</template>

<script>
export default {
  name: 'MDHelpTitle',
  props: ['config'],
  emits: ['close']
}
</script>
