import { defineStore } from 'pinia'
import moment from 'moment'

export const useRecordingStore = defineStore('recording', {
  state: () => ({
    recording: false,
    started_at: null,
    ended_at: null,
    screenshot: false,
    recording_method: null
  }),

  actions: {
    startScreenshot() {
      this.screenshot = true
    },

    stopScreenshot() {
      this.screenshot = false
    },

    start(method: string) {
      this.$patch({
        recording: true,
        started_at: moment.utc(),
        recording_method: method
      })
    },

    stop() {
      this.$patch({
        recording: false,
        ended_at: moment.utc(),
        recording_method: null
      })
    }
  }
})
