import mapboxgl from 'mapbox-gl'

class MapboxPopups {
  constructor(map) {
    this.map = map;
    this.popups = []
  }

  create(options) {
    return new mapboxgl.Popup(options);
  }

  render(p) {
    p.addTo(this.map);

    this.push(p);
  }

  push(p) {
    if(this.popups.find(po => po === p) !== undefined) return;

    this.popups.push(p)
  }

  clear() {
    this.popups.forEach(p => p.remove());
    this.popups = [];
  }
}

export default MapboxPopups;
