<script setup lang="ts">
import { ref } from 'vue'
import DropZone from './DropZone.vue'

interface FileData {
  name: string
  content: string
}

const props = defineProps<{
  onFileContent?: (text: string, fileName: string) => void
}>()

const dragActive = ref(false)
const success = ref('')
const error = ref('')

const isPalFile = (file: File) => {
  return file.name.toLowerCase().endsWith('.pal')
}

const readFileContent = async (file: File) => {
  try {
    const text = await file.text()
    // console.log(`Contents of ${file.name}:`, text)

    // Call the callback if provided
    if (props.onFileContent) {
      props.onFileContent(text, file.name)
    }

    success.value = `Successfully uploaded`
  } catch (err) {
    console.error(`Error reading ${file.name}:`, err)
    error.value = `Failed to read ${file.name}`
    return null
  }
}

const processFiles = async (fileList: File[]) => {
  const validFiles = fileList.filter(isPalFile)
  if (validFiles.length < fileList.length) {
    error.value = 'Only .pal files are allowed'
  } else {
    error.value = ''
  }

  if(validFiles.length === 0) return;

  await readFileContent(validFiles[0])
}

const handleDrag = (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()
  if (e.type === "dragenter" || e.type === "dragover") {
    dragActive.value = true
  } else if (e.type === "dragleave") {
    dragActive.value = false
  }
}

const handleDrop = async (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()
  dragActive.value = false

  if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
    await processFiles(Array.from(e.dataTransfer.files))
  }
}

const handleChange = async (e: Event) => {
  const target = e.target as HTMLInputElement
  if (target.files && target.files.length > 0) {
    await processFiles(Array.from(target.files))
  }
}
</script>

<template>
  <div class="max-w-xl mx-auto">
    <form
      @dragenter="handleDrag"
      @dragover="handleDrag"
      @dragleave="handleDrag"
      @drop="handleDrop"
      @submit.prevent
      class="relative"
    >
      <input
        type="file"
        @change="handleChange"
        class="absolute inset-0 w-full h-full opacity-0 z-50 cursor-pointer"
      />
      <DropZone
        :success="success"
        :error="error"
        :drag-active="dragActive"
      />
    </form>
  </div>
</template>