<script setup>
import { VueFinalModal } from 'vue-final-modal'
import { useTimeAgo } from '@vueuse/core'
import { Icon } from '@iconify/vue'
import moment from 'moment'

import modals from '../Modals/Helper'
</script>

<template>
  <hr class="divider" :style="{ 'border-color': feature.properties['line-color'] }">
  <div class="text-center tags mt-3">
    <span class="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-slate-300 text-sm font-medium me-2 px-2.5 py-0.5 rounded" :title="feature.properties.issued_at">Issued {{ useTimeAgo(feature.properties.issued_at) }}</span>

    <span v-if="moment.utc(feature.properties.expires_at).isBefore(moment.utc())" class="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded" :title="feature.properties.expires_at">Expired {{ useTimeAgo(feature.properties.expires_at) }}!</span>
    <span v-else class="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-slate-300 text-sm font-medium me-2 px-2.5 py-0.5 rounded" :title="feature.properties.expires_at">Expires {{ useTimeAgo(feature.properties.expires_at) }}</span>

    <button 
      type="button" 
      class="bg-[#F6B91A] text-[#17274F] hover:bg-[#17274F] hover:text-[#F6B91A] dark:hover:bg-[#F6B91A] dark:hover:text-[#17274F] rounded-lg text-xs px-2.5 py-0.5 relative top-[-2px] text-center me-2 mb-2 font-medium transition-all duration-200 transform active:scale-90 active:shadow-[0_0_0_2px_#17274F,0_0_0_4px_#F6B91A] dark:active:shadow-[0_0_0_2px_#F6B91A,0_0_0_4px_#17274F]"
      @click="onShareClick($event)"
    >
      <Icon 
        icon="ion:share-outline" 
        class="inline mr-1 size-5" 
      />
      <span class="relative top-[2px] font-bold">Share</span>
    </button>

  </div>
  <pre class="text">{{ feature.properties.text }}</pre>

  <hr class="my-4">
  <p class="text-sm text-slate-600 dark:text-slate-400">The information is provided to us in realtime from the National Weather Service (NWS), specifically from the Storm Prediction Center (SPC) and the Weather Prediction Center (WPC).</p>
</template>

<style type="text/css" scoped>
.divider {
  border-width: 2px;
}

.text {
  white-space: pre-wrap;
}
</style>

<script>
export default {
  name: 'MesoscaleDiscussionModal',
  props: ['feature'],
  methods: {
    onShareClick(e) {
      modals.share();
    }
  }
}
</script>

<style type="text/css" scoped>
.divider {
  border-width: 2px;
}

.tags span {
  display: inline-block;
  margin-bottom: 4px;
}

.text {
  white-space: pre-wrap;
}
</style>
