import { defineStore } from 'pinia'

export const useDrawingSettingsStore = defineStore('settings/drawing', {
    state: () => ({
        lineColor: [255, 0, 0, 1],
        lineWidth: 10,
    }),

    getters: {
        getRGBALineColor: (state) => `rgba(${state.lineColor.join(',')})`,
        getLineWidth: (state) => state.lineWidth,
        getLineColor: (state) => state.lineColor,
    },

    actions: {
        setLineColor(color) {
            this.lineColor = color;
        },
        setLineWidth(width) {
            this.lineWidth = width;
        }
    },

    persist: true,
})
