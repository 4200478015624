import hotkeys from 'hotkeys-js';
import { useAppStore } from '@/stores/app';
import { useRadarTowersStore } from '@/stores/radar_towers';
import { useDrawingStore } from '@/stores/drawing';
import { useWarningsSettingsStore } from '@/stores/settings/warnings'
import { useMesoscaleDiscussionsSettingsStore } from '@/stores/settings/mesoscale_discussions'
import { useTropicalSettingsStore } from '@/stores/settings/tropical'
import { useDebounceFn } from '@vueuse/core'

import { useRadarPlayback } from '@/logic/Composables/RadarPlayback';

export default {
    install(app) {
        const appStore = useAppStore();
        const radarTowersStore = useRadarTowersStore();
        const drawingStore = useDrawingStore();

        const warningsSettingsStore = useWarningsSettingsStore();
        const mesoscaleDiscussionsSettingsStore = useMesoscaleDiscussionsSettingsStore();
        const tropicalSettingsStore = useTropicalSettingsStore();

        // Debounced mode switching functions
        const setRadarMode = useDebounceFn(() => {
            appStore.setMode('RADAR');
        }, 300);

        const setSatelliteMode = useDebounceFn(() => {
            appStore.setMode('SATELLITE');
        }, 300);

        const setOutlooksMode = useDebounceFn(() => {
            appStore.setMode('OUTLOOKS');
        }, 300);

        // Debounced radar product changes
        const setReflectivity = useDebounceFn(() => {
            if (!appStore.isRadarMode || !radarTowersStore.anyActive) return;
            const towerId = radarTowersStore.activeTowerId;
            window.map.radar.changeRadarProduct(towerId, 'N0B');
        }, 300);

        const setVelocity = useDebounceFn(() => {
            if (!appStore.isRadarMode || !radarTowersStore.anyActive) return;
            const towerId = radarTowersStore.activeTowerId;
            window.map.radar.changeRadarProduct(towerId, 'N0G');
        }, 300);

        const setCorrelationCoefficient = useDebounceFn(() => {
            if (!appStore.isRadarMode || !radarTowersStore.anyActive) return;
            const towerId = radarTowersStore.activeTowerId;
            window.map.radar.changeRadarProduct(towerId, 'N0C');
        }, 300);

        const setDifferentialReflectivity = useDebounceFn(() => {
            if (!appStore.isRadarMode || !radarTowersStore.anyActive) return;
            const towerId = radarTowersStore.activeTowerId;
            window.map.radar.changeRadarProduct(towerId, 'NXX');
        }, 300);

        const setSpectrumWidth = useDebounceFn(() => {
            if (!appStore.isRadarMode || !radarTowersStore.anyActive) return;
            const towerId = radarTowersStore.activeTowerId;
            window.map.radar.changeRadarProduct(towerId, 'NSW');
        }, 300);


        const togglePlayback = () => {
            if (!appStore.isRadarMode || radarTowersStore.isPlaybackBuffering) return;
            useRadarPlayback().togglePlayback();
        };

        const toggleDrawing = () => {
            if (! drawingStore.isAnnotating) {
                window.drawing.enable();
                return;
            }
            window.drawing.disable();
        };

        const toggleInspector = () => {
            if (!appStore.isRadarMode) return;
            radarTowersStore.inspectorActive = !radarTowersStore.inspectorActive;
        };

        const toggleSidebar = () => {
            appStore.toggleWarningsSidebar();
        };

        const toggleWarningsOverlay = () => {
            warningsSettingsStore.toggleVisible();
        };

        const toggleMesoscaleDiscussions = () => {
            mesoscaleDiscussionsSettingsStore.toggleVisible();
        };

        const toggleTropical = () => {
            tropicalSettingsStore.toggleVisible();
        };

        const toggleMenu = () => {
            appStore.toggleMenu();
        };

        const shortcuts = {
            'shift+1': {
                fn: setRadarMode,
                description: 'Set mode to Radar'
            },
            'shift+2': {
                fn: setSatelliteMode,
                description: 'Set mode to Satellite'
            },
            'shift+3': {
                fn: setOutlooksMode,
                description: 'Set mode to Outlooks'
            },
            'shift+r': {
                fn: setReflectivity,
                description: 'Set radar product to Reflectivity'
            },
            'shift+v': {
                fn: setVelocity,
                description: 'Set radar product to Velocity'
            },
            'shift+c': {
                fn: setCorrelationCoefficient,
                description: 'Set radar product to Correlation Coefficient'
            },
            'shift+d': {
                fn: setDifferentialReflectivity,
                description: 'Set radar product to Differential Reflectivity'
            },
            'shift+w': {
                fn: setSpectrumWidth,
                description: 'Set radar product to Spectrum Width'
            },
            'space': {
                fn: togglePlayback,
                description: 'Toggle radar playback'
            },
            'd': {
                fn: toggleDrawing,
                description: 'Toggle drawing mode'
            },
            'i': {
                fn: toggleInspector,
                description: 'Toggle radar inspector'
            },
            'a': {
                fn: toggleSidebar,
                description: 'Toggle alerts sidebar'
            },
            'alt+shift+w': {
                fn: toggleWarningsOverlay,
                description: 'Toggle warning overlays'
            },
            'alt+shift+m': {
                fn: toggleMesoscaleDiscussions,
                description: 'Toggle mesoscale discussion overlays'
            },
            'alt+shift+t': {
                fn: toggleTropical,
                description: 'Toggle tropical overlays'
            },
            'tab': {
                fn: toggleMenu,
                description: 'Toggle menu'
            }
        }

        // Bind hotkeys to debounced functions
        for (const [key, fn] of Object.entries(shortcuts)) {
            hotkeys(key, fn.options || {}, fn.fn);
        }

        app.provide('keyboardShortcuts', shortcuts);

        // console.log('KeyboardShortcuts installed', hotkeys.getAllKeyCodes());
    }
}
