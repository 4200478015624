import modals from '@/logic/Modals/Helper'
import { useRecordingStore } from '@/stores/recording'
import { locateUser, isLocating } from '@/tools/locationUtils';
import { useDrawingStore } from '@/stores/drawing';

export function shareMap() {
    modals.share()
}

export function screenshot() {
    window.screenshot.take()
}

export function recordGif() {
    window.gif.record()
    useRecordingStore().start('GIF')
}

export function recordVideo() {
    window.mp4.record()
    useRecordingStore().start('MP4')
}

export async function locateMe() {
    if(isLocating.value) return;
    try {
        await locateUser();
    } catch (error) {
        if (typeof window.WeatherWiseBridge !== 'undefined') {
            window._alert(error.message, 'Location Error');
        }
        console.error(error);
    }
}

export function draw() {
    if (!useDrawingStore().isAnnotating) {
        window.drawing.enable();
    }
    else {
        window.drawing.disable();
    }
}
