export default /* glsl */`
precision highp float;
varying float colorUV;

uniform sampler2D color_map_texture;
uniform float opacity;

void main() {
    vec4 color = texture2D(color_map_texture, vec2(colorUV, 0.0));
    color.a *= opacity;

    gl_FragColor = color;
}

`