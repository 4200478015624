export default {
  'USA/SPC/CONVECTIVE': {
    title: 'SPC Convective/Severe Weather',
    text: `The Storm Prediction Center (SPC) provides Day 1, Day 2, and Day 3 Convective Outlooks that highlight both non-severe thunderstorm regions and severe thunderstorm risks across the contiguous U.S. These probabilistic forecasts reflect the likelihood of severe weather occurring within 25 miles of any given point.

The categorical risk levels in these outlooks are based on probability forecasts for tornadoes, damaging winds, and large hail on Days 1 and 2, and a combined severe weather risk for Day 3.

- TSTM (light green): General or non-severe thunderstorms – Indicates areas with a 10% or higher probability of thunderstorms during the forecast period.
- 1-MRGL (dark green): Marginal risk – Indicates a chance of severe storms that are either limited in organization and duration or very low in coverage and intensity.
- 2-SLGT (yellow): Slight risk – Highlights areas of organized severe storms that are not widespread but can vary in intensity.
- 3-ENH (orange): Enhanced risk – Denotes a higher coverage of severe storms compared to Slight risk, with varying intensity levels.
- 4-MDT (red): Moderate risk – Forecasts widespread severe weather, including several tornadoes or numerous intense thunderstorms. This level is typically used for days with intense supercells producing tornadoes or large hail, or for widespread damaging winds.
- 5-HIGH (magenta): High risk – Signals a severe weather outbreak is likely, including numerous long-lived, intense tornadoes or a derecho with hurricane-force winds and widespread damage. This level is reserved for extreme confidence in severe weather outbreaks.

Forecasting severe weather events, such as tornadoes, large hail, or damaging winds, involves significant uncertainty. It's essential not to rigidly associate the risk category (e.g., 2-SLGT-yellow) with the expected intensity of every storm in the area. Violent tornadoes, for instance, can occur in 2-SLGT-yellow, 3-ENH-orange, or 4-MDT-red risk zones, not just in 5-HIGH-magenta. Some 2-SLGT-yellow areas may cover large regions with only a few tornadoes expected, while others may pose no tornado threat but still bring severe hail and wind.

SPC outlooks focus on organized convection, including supercells, squall lines, and multicell thunderstorms, all of which can cause damage from tornadoes, high winds, or large hail. Pulse-type thunderstorms, which are isolated and brief, are not considered organized and are typically excluded from severe risk areas unless confidence is high enough to designate a 1-MRGL-dark green risk. While any thunderstorm can briefly produce severe weather, it's not uncommon for local National Weather Service offices to issue severe thunderstorm warnings outside of an SPC risk zone.`
  },

  'USA/SPC/THUNDERSTORM': {
    title: 'SPC Thunderstorms',
    text: `The SPC (Storm Prediction Center) provides Thunderstorm Outlooks that indicate the likelihood of thunderstorms across the contiguous United States in 4 or 8-hour intervals. These probabilistic forecasts offer the best estimate of a thunderstorm occurring within 12 miles of a given point, with forecast thresholds set at 10%, 40%, and 70%. The table below outlines the issuance times and the corresponding forecast periods, up to three time windows:

- 0130Z: 0400-1200Z
- 0600Z: 1200-1600Z, 1600-2000Z, 2000-0000Z
- 1300Z: 1600-2000Z, 2000-0000Z, 0000-0400Z
- 1700Z: 2000-0000Z, 0000-0400Z, 0400-1200Z
- 2100Z: 0000-0400Z, 0400-1200Z

Thunderstorm forecasts are crucial for protecting lives and property, as every thunderstorm brings the risk of lightning, a serious hazard. The SPC's enhanced temporal resolution helps National Weather Service (NWS) forecasters and partners make time-sensitive decisions regarding thunderstorms.`
  },
  
  'USA/SPC/FIRE': {
    title: 'SPC Fire Weather',
    text: `The Storm Prediction Center (SPC) provides the Fire Weather Outlooks and are designed to identify areas across the continental U.S. where existing fuel conditions, combined with forecasted weather over the next 8 days, pose a significant risk for the ignition and spread of wildfires. This product is used by the National Weather Service (NWS) and various federal, state, and local agencies.

Each outlook includes a categorical forecast that visually represents fire weather risk areas across the U.S., along with a detailed text narrative. The graphic uses different labels and colors to depict five types of Fire Weather Outlook risk areas:

- ELEVATED (orange): Elevated risk due to wind and relative humidity.
- CRITICAL (red): Critical risk from wind and relative humidity.
- EXTREME (magenta): Extremely critical risk from wind and relative humidity.
- ISODRYT (brown): Elevated risk from isolated dry thunderstorms.
- SCTDRYT (red): Critical risk from scattered dry thunderstorms.

Guidelines for issuing Critical and Extremely Critical areas are as follows:

Critical risk from dry thunderstorms:

- Dry fuels (as defined below).
- At least 40% coverage of cloud-to-ground lightning strikes, with rainfall of 0.10" or less.
- Relative humidity at or below regional thresholds (refer to this graphic).
- Temperatures at or above 50-60°F, depending on the season.

Critical risk from wind and relative humidity:

- Dry fuels.
- Sustained winds of 20 mph or greater (15 mph in Florida).
- Relative humidity at or below regional thresholds.
- Temperatures at or above 50-60°F, depending on the season.
- These conditions must persist for 3 hours or more.

Extremely Critical risk from wind and relative humidity:

- Very dry fuels.
- Sustained winds of 30 mph or greater (25 mph in Florida).
- Relative humidity at or below two-thirds of regional thresholds.
- Temperatures at or above 60-70°F, depending on the season.
- These conditions must persist for 3 hours or more.

Extremely Critical areas are designated when wind, humidity, and temperatures significantly deviate from typical norms. They may also be issued for borderline weather conditions if exceptional drought is present.`
  },

  'USA/CPC/CLIMATE': {
    title: 'CPC Climate',
    text: `The NOAA Climate Prediction Center (CPC) offers multiple climate outlooks across different timeframes to help anticipate temperature and precipitation trends:

<strong>6-10 Day Outlook</strong>
    This short-range outlook predicts temperature and precipitation anomalies over a 6-10 day period, highlighting areas likely to experience above or below-normal conditions. It is useful for preparing for near-term weather patterns and is updated daily based on recent atmospheric and climate model data.

<strong>8-14 Day Outlook</strong>
    Similar to the 6-10 day outlook, this 8-14 day forecast predicts weather trends within a slightly longer range, providing insights on temperature and precipitation anomalies. Updated daily, it’s helpful for medium-range planning, offering a broader view of upcoming conditions that could impact agriculture, energy use, and travel.

<strong>Week 3-4 Outlook</strong>
    Extending to a three-to-four-week window, this outlook forecasts temperature and precipitation trends that are influenced by longer-term atmospheric patterns. Updated weekly, it provides valuable insights for planning purposes in industries like agriculture and energy that rely on understanding weather in advance.

<strong>1-Month Outlook</strong>
    This monthly outlook forecasts temperature and precipitation anomalies for the upcoming month. Released during the latter half of each month, it incorporates models, recent trends, and seasonal climate drivers, such as La Niña or El Niño, to anticipate broader weather trends useful for longer-term planning.

<strong>3-Month Outlook</strong>
    The longest-range CPC outlook, the 3-month forecast, provides an assessment of temperature and precipitation anomalies for the upcoming three months. It factors in seasonal climate drivers and long-term atmospheric patterns. This quarterly view is particularly valuable for industries affected by seasonal climate trends, like agriculture, water management, and energy.`
  },

  'USA/CPC/DROUGHT': {
    title: 'CPC Drought',
    text: `The CPC (Climate Prediction Center) Drought Outlook is a climate forecast tool that provides a forward-looking assessment of drought conditions across the United States. It highlights areas where drought is expected to persist, improve, or develop over a specified time frame, typically ranging from one month to a full season. The outlook combines various data sources, including current drought conditions, precipitation trends, soil moisture levels, long-term weather patterns, and climate model forecasts.

The report uses clear visual representations, such as color-coded maps, to indicate areas of concern and anticipated changes in drought status. These forecasts are vital for agricultural planning, water resource management, and disaster preparedness, providing stakeholders with actionable insights to mitigate the impacts of drought.`
  },

  'USA/WPC/EXCESSIVE_RAINFALL': {
    title: 'WPC Excessive Rainfall',
    text: `In the Excessive Rainfall Outlooks (ERO), the Weather Prediction Center (WPC) predicts the likelihood that rainfall will surpass flash flood guidance (FFG) within 40 kilometers (25 miles) of any given point. The gridded FFG, provided by the twelve NWS River Forecast Centers (RFCs) covering the lower 48 states, indicates the amount of rainfall over short durations (1, 3, and 6 hours) that could bring rivers and streams to their bankfull levels. To estimate this, the WPC evaluates environmental factors like moisture levels and steering winds, identifies weather patterns linked to heavy rainfall, and utilizes a range of deterministic and ensemble-based models to consider both meteorological and hydrological flash flooding factors. The ERO is a collaborative product enhanced by input from meteorologists and hydrologists at the WFOs, RFCs, and the National Water Center.`
  },

  'USA/WPC/QPF': {
    title: 'WPC Quantitive Precipitation Forecast (QPF)',
    text: `The WPC Quantitative Precipitation Forecast (QPF) provides expert analysis and projections of anticipated precipitation amounts across the United States. Issued by the Weather Prediction Center (WPC), the QPF outlines forecasted rainfall, snowfall, and other precipitation over specified time intervals, typically in 6-hour increments, and extends up to 7 days. It uses various meteorological models, historical data, and real-time observations to predict the potential for rainfall accumulation, helping agencies and the public prepare for potential flooding, drought conditions, and other weather impacts. This forecast is a crucial tool for emergency management, agriculture, water resource planning, and weather-sensitive industries, providing detailed maps with estimated precipitation levels to support informed decision-making and preparedness.`
  },

  'USA/WPC/WSSI': {
    title: 'WPC Winter Storm Severity Index',
    text: `The Winter Storm Severity Index (WSSI), developed by the Weather Prediction Center (WPC), is a specialized tool used to communicate the potential impacts of winter weather. It provides a color-coded map that categorizes the severity of expected winter storms based on factors like snowfall, icing, sleet, and blowing snow, along with their potential impacts on travel, infrastructure, and daily life. Ranging from "Limited" to "Extreme" impact levels, the WSSI helps forecasters, emergency managers, and the public quickly assess the possible disruption and hazards posed by winter storms in a specific area. By focusing on impact rather than purely meteorological criteria, the WSSI enables users to make informed decisions about winter weather preparedness and response.

    The WSSI includes a range of impact-based products, each focusing on different aspects of winter weather to provide a comprehensive understanding of potential hazards:

    <strong>Overall</strong>: This provides a comprehensive assessment of the potential impact of all winter weather factors combined, including snowfall, ice, wind, and temperature drops. By synthesizing data from individual components—such as Snow Amount, Snow Load, Ice Accumulation, Flash Freeze, Blowing Snow, and Ground Blizzard—this category offers a clear, color-coded overview of the total severity of a winter storm event in a given area. Ranging from "Limited" to "Extreme" impact levels, the Overall product helps users quickly identify regions facing the most significant combined hazards, supporting effective planning and response to mitigate storm-related risks across communities.

    <strong>Snow Amount</strong>: This product highlights the severity of snowfall accumulation, considering not only total snow depth but also how it may affect travel, infrastructure, and daily routines. Higher snow amounts are likely to bring greater disruptions, particularly when accumulations exceed thresholds for typical snow removal capabilities.

    <strong>Snow Load</strong>: Focused on the weight of accumulated snow, the Snow Load product assesses potential impacts on structures, trees, and power lines. Heavy, wet snow can lead to structural stress and damage, particularly for vulnerable or flat-roofed buildings, as well as cause tree limbs to break and disrupt power service.

    <strong>Ice Accumulation</strong>: This product identifies the severity of ice build-up from freezing rain, which poses risks to road conditions, power lines, and trees. Even minor ice accumulations can make travel dangerous, while heavier accumulations can lead to widespread power outages and infrastructure damage.

    <strong>Flash Freeze</strong>: This product tracks areas where rapid drops in temperature cause water on roads or surfaces to freeze almost instantly. Flash freeze events can lead to highly hazardous driving conditions and make walkways treacherous in a short time.

    <strong>Blowing Snow</strong>: Highlighting visibility and travel impacts, the Blowing Snow product assesses the severity of snow being lifted and carried by the wind. This can reduce visibility drastically, especially on open roads, creating hazardous driving conditions.

    <strong>Ground Blizzard</strong>: This product focuses on blizzard conditions that occur without new snowfall, where existing snow on the ground is whipped up by high winds. Ground blizzards can create whiteout conditions, making travel nearly impossible and significantly increasing the risk of accidents.`
  },

  'USA/WPC/WINTER_WEATHER': {
    title: 'WPC Winter Weather',
    text: `The Weather Prediction Center (WPC) Winter Weather Outlooks provide advanced, comprehensive forecasts focusing on winter weather conditions across the United States. These outlooks cover potential snow and ice accumulations, storm tracks, temperature trends, and anticipated impacts, allowing for efficient planning and response. Issued several days in advance, they are designed to inform the public, emergency responders, and decision-makers about high-impact winter weather events. The outlooks are updated frequently, using the latest meteorological data and model guidance, and include graphics that highlight areas of heightened risk, probabilities of snowfall thresholds, and potential hazards from ice and extreme cold.`
  },

  'USA/AVALANCHE_ORG/AVALANCHES': {
    title: 'US Avalanches',
    text: `Avalanche.org serves as a hub for avalanche information and education across the United States. It is a collaborative effort between the American Avalanche Association (A3) and the U.S. Forest Service National Avalanche Center (NAC). By aggregating data from professional forecast centers, the site offers real-time avalanche updates to the public.

  Avalanche centers create and share safety messages to inform the public about current and forecasted avalanche conditions. Many also offer free or low-cost avalanche education programs.

<strong>Forest Service</strong>

<a class="text-blue-600 hover:underline" href="http://bridgeportavalanchecenter.org">Bridgeport Avalanche Center</a>
Box 1000
Bridgeport, CA 93517

<a class="text-blue-600 hover:underline" href="http://jhavalanche.org">Bridger-Teton Avalanche Center</a>
PO Box 424
Teton Village, WY 83025

<a class="text-blue-600 hover:underline" href="http://www.cnfaic.org">Chugach NF Avalanche Center</a>
P.O. Box 129
Girdwood, AK 99587

<a class="text-blue-600 hover:underline" href="http://www.flatheadavalanche.org">Flathead Avalanche Center </a>
10 Hungry Horse Drive
Hungry Horse, MT 59919

<a class="text-blue-600 hover:underline" href="http://www.mtavalanche.com">Gallatin National Forest Avalanche Center</a>
PO Box 130
Bozeman, MT 59771

<a class="text-blue-600 hover:underline" href="http://www.idahopanhandleavalanche.org/">Idaho Panhandle Avalanche Center</a>
PO Box 405
Ponderay, ID 83852

<a class="text-blue-600 hover:underline" href="https://avalanche.org/national-avalanche-center/">National Avalanche Center</a>
P.O. Box 130
Bozeman, MT 59771

<a class="text-blue-600 hover:underline" href="http://www.nwac.us">Northwest Avalanche Center</a>
7600 Sand Point Way NE
Seattle, WA 98115

<a class="text-blue-600 hover:underline" href="http://www.shastaavalanche.org">Mt Shasta Avalanche Center</a>
204 West Alma Street
Mount Shasta, CA 96067

<a class="text-blue-600 hover:underline" href="http://www.mountwashingtonavalanchecenter.org">Mt Washington Avalanche Center</a>
300 Glen Road
Gorham, NH &nbsp;03581

<a class="text-blue-600 hover:underline" href="http://payetteavalanche.org">Payette Avalanche Center</a>
PO Box 2177
McCall, ID 83638

<a class="text-blue-600 hover:underline" href="http://www.sawtoothavalanche.com">Sawtooth Avalanche Center</a>
206 Sun Valley Road
Sun Valley, ID &nbsp;83353

<a class="text-blue-600 hover:underline" href="http://www.sierraavalanchecenter.org">Sierra Avalanche Center</a>
10811 Stockrest Springs Rd
Truckee, CA 96161

<a class="text-blue-600 hover:underline" href="https://utahavalanchecenter.org">Utah Avalanche Center
</a>2242 West North Temple
Salt Lake City, UT 84116

<a class="text-blue-600 hover:underline" href="http://missoulaavalanche.org">West Central Montana Avalanche Center</a>
PO Box 72
Missoula, MT 59806

<strong>State</strong>

<a class="text-blue-600 hover:underline" href="http://avalanche.state.co.us">Colorado Avalanche Information Center</a>
325 Broadway WS1
Boulder, CO 80305

<strong>Local Nonprofit</strong>

<a class="text-blue-600 hover:underline" href="https://alaskasnow.org/" target="_blank" rel="noopener noreferrer">Alaska Avalanche Information Center</a>
Supports the Haines, Valdez, Cordova, and E AK Range Avalanche Centers
PO Box 911
Valdez, AK 99686

<a class="text-blue-600 hover:underline" href="http://www.coavalanche.org/" target="_blank" rel="noopener noreferrer">Central Oregon Avalanche Center</a>
2701 NW Scandia Loop
Bend, Oregon 97701

<a class="text-blue-600 hover:underline" href="http://cbavalanchecenter.org/" target="_blank" rel="noopener noreferrer">Crested Butte Avalanche Center</a>
PO Box 2351
Crested Butte, CO 81224

<a class="text-blue-600 hover:underline" href="https://www.esavalanche.org/" target="_blank" rel="noopener noreferrer">Eastern Sierra Avalanche Center</a>
PO Box 1675
Mammoth Lakes, CA 93546

<a class="text-blue-600 hover:underline" href="https://hpavalanche.org/" target="_blank" rel="noopener noreferrer">Hatcher Pass Avalanche Center</a>
5629 Byrwood Circle
Palmer, AK 99645

<a class="text-blue-600 hover:underline" href="https://kachinapeaks.org/">Kachina Peaks Avalanche Center</a>
PO Box 174
Flagstaff, AZ 86002-0174

<a class="text-blue-600 hover:underline" href="https://taosavalanchecenter.org/" target="_blank" rel="noopener noreferrer">Taos Avalanche Center</a>
PO Box 267
El Prado, NM 87529

<a class="text-blue-600 hover:underline" href="https://www.wallowaavalanchecenter.org/" target="_blank" rel="noopener noreferrer">Wallowa Avalanche Center</a>
P.O. Box 324
Joseph, Oregon 97846
`
  },

}