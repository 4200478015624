class UrlHash extends URLSearchParams {
  constructor() {
    super(window.location.hash.substr(1));
  }

  save(force = false) {
    // For whatever reason, Mapbox uses non-url encoded characters for the map position in the url
    const hash = this.toString().replaceAll('%2F', '/')

    if(window.location.hash.substr(1) !== hash || force) {
      window.location.hash = hash;

      console.log("Saving to URL hash", hash);
    }
  }
}

export default UrlHash
