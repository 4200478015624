<template>
	<div class="relative inline">
    <button type="button" class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl font-bold rounded-lg text-xs sm:text-sm px-2.5 py-1 text-center me-2 mt-2" @click.prevent="toggle($event)"><Icon icon="ph:wind-duotone" class="inline mr-1 size-5" />Wind Speed <span class="sm:hidden font-bold">Prob.</span><span class="hidden sm:inline font-bold">Probabilities</span> <Icon :icon="isOpen ? 'gravity-ui:chevron-up-wide' : 'gravity-ui:chevron-down-wide'" class="inline -mt-[1px] ml-1 font-bold size-5" /></button>
    <Transition name="slide-fade">
      <div v-if="isOpen" v-on-click-outside="onMenuOutsideClick" class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 left-0 top-10 absolute">
        <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
        <li v-for="wsp in wsps">
          <a href="#" class="block px-4 py-2 hover:bg-gray-100" :class="{
            'font-bold': tropicalStore.wsp === wsp.id
          }" v-text="wsp.name" @click.prevent="onWspOptionClick($event, wsp)"></a>
        </li>
      </ul>
      </div>
    </Transition> 
  </div>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components'
</script>

<script>
import { Icon } from '@iconify/vue'

import { useTropicalStore } from '@/stores/tropical'

export default {
  name: 'WspButton',
  components: {
    Icon
  },
  data() {
    return {
      isOpen: false,
      wsps: [{
        id: '34kt',
        name: '34 knots (~39 mph)'
      }, {
        id: '50kt',
        name: '50 knots (~58 mph)'
      }, {
        id: '64kt',
        name: '64 knots (~74 mph)'
      }]
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    onMenuOutsideClick() {
      setTimeout(() => {
        this.isOpen = false;
      }, 10);
    },
    toggle() {
      if (!this.isOpen) {
        this.open();
      } else {
        this.close();
      }
    },
    async onWspOptionClick(e, wsp) {
      console.log(wsp)

      this.close();

      this.$parent.$emit('close')

      if(wsp.id === this.tropicalStore.wsp) {
        window.map.tropical.clearWsp();
      }
      else {
        await window.map.tropical.fetchAndRenderWsp(wsp.id);
      }
    }
  },
  computed: {
    tropicalStore: () => useTropicalStore(),
  },
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  display: none;
}
</style>
