
import { ref } from 'vue';

export const isLocating = ref(false);
export const userLocation = ref(null);
export const buttonIcon = ref('solar:map-arrow-up-outline');
export const isError = ref(false);
export const userMarker = ref(false);

const size = 128;

const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),
    onAdd: function () {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
    },
    render: function () {
        const duration = 1000;
        const t = (performance.now() % duration) / duration;
        const radius = (size / 2) * 0.3;
        const outerRadius = (size / 2) * 0.7 * t + radius;
        const context = this.context;
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
        context.fillStyle = `rgba(255, 100, 100, ${1 - t})`;
        context.fill();
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context.fillStyle = 'rgba(255, 87, 34, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();
        this.data = context.getImageData(0, 0, this.width, this.height).data;
        window.map.triggerRepaint();
        return true;
    }
};

export const locateUser = async () => {
    if (!navigator._geolocation) {
        console.error('Geolocation is not supported.');
        return;
    }

    isLocating.value = true;
    isError.value = false;

    try {
        const permission = await navigator._geolocation.requestPermission();

        if (permission === 'denied') {
            throw new Error('Please enable location permissions in your settings to use this feature.');
        }

        const position = await Promise.race([
            navigator._geolocation.getCurrentPosition({
                enableHighAccuracy: true,
                maximumAge: 0
            }),
            new Promise((_, reject) => setTimeout(() => {
                reject(new Error('Location request timed out. Please try again.'))
            }, 15000))
        ]);

        userLocation.value = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        };

        const coords = [userLocation.value.longitude, userLocation.value.latitude];

        window.map.flyTo({
            center: coords,
            zoom: 12,
            essential: true
        });

        if (userMarker.value) {
            window.map.removeLayer('layer-with-pulsing-dot');
            window.map.removeSource('dot-point');
        }

        window.map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

        window.map.addSource('dot-point', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': coords
                        }
                    }
                ]
            }
        });

        window.map.addLayer({
            'id': 'layer-with-pulsing-dot',
            'type': 'symbol',
            'source': 'dot-point',
            'layout': {
                'icon-image': 'pulsing-dot'
            }
        });

        window.map.flyTo({
            center: coords,
            zoom: 8,
            essential: true
        });

        userMarker.value = true;

        await window.map.radar.turnOnClosestOnlineRadar(coords, 'REF');

    } catch (error) {
        console.error('Error getting location:', error);
        buttonIcon.value = 'bi:exclamation-lg';
        isError.value = true;
        setTimeout(() => {
            buttonIcon.value = 'solar:map-arrow-up-outline';
            isError.value = false;
        }, 2000);
        throw error;
    } finally {
        isLocating.value = false;
    }
};

