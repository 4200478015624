import { defineStore } from 'pinia'

import { getConfigForSettings } from '@/data/nws_warning_config.js'

export const useWarningsSettingsStore = defineStore('settings/warnings', {
  state: () => ({
    visible: true,
    // sort_by: 'ISSUANCE-A-Z',
    filter_active: false,
    config: {
      'USA': getConfigForSettings()
    }
  }),

  persist: true,

  actions: {
    toggleVisible() {
      this.visible = !this.visible;
    },

    toggleFilterActive() {
      this.filter_active = !this.filter_active;
    },
  }
})
