<script setup lang="ts">
import type { PropType } from 'vue'
import { Icon } from '@iconify/vue'
import moment from 'moment'

interface Feature {
    properties: {
        danger_level: number;
        danger: string;
        name: string;
        center: string;
        start_date: string;
        end_date: string;
        link: string;
        'fill-color': string;
        color: string;
        state: string;
        timezone: string;
        travel_advice: string;
        center_id: string;
        center_link: string;
    }
}

const props = defineProps({
    feature: {
        type: Object as PropType<Feature>,
        required: true
    }
})

const formatDate = (dateStr: string) => {
    return moment.utc(dateStr).local().format('MMM Do HH:mm')
}
</script>

<template>
    <div class="flex flex-col dark:bg-ww-blue">
        <!-- Danger Level Header -->
        <div v-if="feature.properties.danger_level >= 0" 
             class="flex items-center gap-3 mb-3">
            <div class="flex items-center justify-center bg-[#F6B91A] rounded-lg p-2 w-10 h-10">
                <Icon icon="material-symbols:warning" class="text-[#17274F] size-6" />
            </div>
            <div>
                <div class="text-lg font-bold text-[#17274F] dark:text-white capitalize">
                    {{ feature.properties.danger_level }} - {{ feature.properties.danger }}
                </div>
                <div class="text-sm text-gray-600 dark:text-gray-300">
                    Avalanche Danger
                </div>
            </div>
        </div>
        <div v-else class="flex items-center gap-3 mb-3">
            <div class="flex items-center justify-center bg-blue-100 dark:bg-ww-blue-200 rounded-lg p-2 w-12 h-12">
                <Icon icon="material-symbols:info" class="text-blue-700 dark:text-blue-300 size-8" />
            </div>
            <div>
                <div class="text-lg font-bold text-[#17274F] dark:text-white capitalize">
                    {{ feature.properties.danger }}
                </div>
                <div class="text-sm text-gray-600 dark:text-gray-300">
                    Information Available
                </div>
            </div>
        </div>

        <!-- Location Info -->
        <div class="space-y-1 mb-2">
            <div class="text-[#17274F] dark:text-white font-semibold">
                {{ feature.properties.name }}
            </div>
            <div class="text-gray-600 dark:text-gray-300 !mt-0">
                {{ feature.properties.center }}
            </div>
            <div class="text-[#17274F] dark:text-white">
                {{ feature.properties.travel_advice }}
            </div>            
        </div>

        <!-- Validity Period -->
        <div class="text-sm text-gray-600 dark:text-gray-300 mb-3" v-if="feature.properties.start_date !== feature.properties.end_date">
            <div class="text-center">
                <span class="font-semibold">{{ formatDate(feature.properties.start_date) }}</span> - 
                <span class="font-semibold">{{ formatDate(feature.properties.end_date) }}</span>
            </div>
        </div>

        <!-- Action Button -->
        <a :href="feature.properties.link" 
           target="_blank"
           class="inline-flex items-center justify-center py-1.5 bg-[#17274F] hover:bg-[#223a6b] dark:bg-[#F6B91A] dark:hover:bg-[#f7c54a] text-white dark:text-[#17274F] font-semibold rounded-lg transition-colors duration-200 text-sm">
            Get the Forecast
        </a>
    </div>
</template>

<style scoped>
:root.dark .bg-white {
    background-color: var(--ww-blue);
}
</style>
