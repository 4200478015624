<script setup>
import { vOnClickOutside } from '@vueuse/components'
</script>

<template>
  <div class="absolute top-3 left-3 md:left-1/2 md:ml-[-300px] md:w-[600px] z-[26]">
    <div class="px-4 bg-ww-slate-100 dark:bg-ww-blue rounded-lg flex select-none">
      <template v-if="appStore.updating">
        <button @click.stop="toggleDropdown" class="flex items-center focus:outline-none hover:bg-gray-200 dark:hover:bg-ww-blue-200 dark:text-gray-100 rounded-lg transition-colors duration-200 group my-2 px-2">
          <Icon icon="line-md:downloading-loop" class="size-8" />
          <div class="font-[Outfit] text-lg items-center flex flex-1 ml-3 hidden md:flex">
            <span class="text-[#17274F] dark:text-gray-100 font-bold drop-shadow-sm transition-colors duration-200">Updating</span><span class="text-[#F6B91A] font-bold drop-shadow-sm group-hover:text-[#F6B91A] transition-colors duration-200">...</span>
          </div>
          <Icon :icon="dropdownOpen ? 'gravity-ui:chevron-up-wide' : 'gravity-ui:chevron-down-wide'" class="self-center ml-2 transition-colors duration-200 text-gray-700 dark:text-gray-300" />
        </button>
      </template>
      <template v-else>
        <button @click.stop="toggleDropdown" class="flex items-center focus:outline-none hover:bg-gray-200 dark:hover:bg-ww-blue-200 rounded-lg transition-colors duration-200 group my-2 px-2">
          <img src="../assets/weatherwise-logo.svg" class="h-3/4 max-w-[27px] object-contain flex-initial self-center" alt="WeatherWise Logo">
          <div class="font-[Outfit] text-lg items-center flex flex-1 ml-3 hidden md:flex">
            <span class="text-[#17274F] dark:text-gray-100 font-bold drop-shadow-sm transition-colors duration-200">Weather</span><span class="text-[#F6B91A] font-bold drop-shadow-sm group-hover:text-[#F6B91A] transition-colors duration-200">Wise</span>
          </div>
          <Icon :icon="dropdownOpen ? 'gravity-ui:chevron-up-wide' : 'gravity-ui:chevron-down-wide'" class="self-center ml-2 transition-colors duration-200 text-gray-700 dark:text-gray-300" />
        </button>
      </template>

      <div style="border-right: 1px solid #CCC;" class="my-2 mx-5 dark:border-gray-600"></div>

      <ul class="flex flex-wrap text-sm font-medium text-center text-gray-700 dark:text-gray-200 md:hidden my-[7px]">
        <li>
          <a @click.prevent="onMobileMenuItemClick($event)" href="#" class="inline-block px-4 py-2 font-bold text-white bg-blue-700 hover:bg-blue-800 rounded-lg active"><Icon :icon="activeMode.icon" class="inline size-6" /><span class="font-bold ml-2" v-text="activeMode.name"></span><Icon :icon="menuOpen ? 'gravity-ui:chevron-up-wide' : 'gravity-ui:chevron-down-wide'" class="size-4 inline ml-3" /></a>
        </li>
      </ul>

      <ul class="flex flex-nowrap text-sm font-medium text-center text-gray-700 dark:text-gray-300 hidden md:flex my-[7px]">
        <li v-for="(mode, index) in modes" :class="{
            'ms-2': index !== 0
          }">
          <a href="#" @click.prevent="onModeClick($event, mode.id)" class="inline-block px-4 py-2 font-bold rounded-lg" :class="{
            'text-white bg-blue-700 hover:bg-blue-800 active': appStore.mode === mode.id,
            'hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-ww-blue-200': appStore.mode !== mode.id
          }"><Icon :icon="mode.icon" class="inline size-6" /><span class="font-bold ml-2" v-text="mode.name"></span></a>
        </li>
      </ul>
    </div>

    <Transition name="slide-down">
      <div v-if="menuOpen" v-on-click-outside="onMenuOutsideClick" class="absolute left-0 md:left-1/2 md:-translate-x-1/2 w-full z-30 select-none origin-top mt-[7px]">
        <div class="bg-white dark:bg-ww-blue rounded-lg shadow-lg dark:shadow-gray-900">
          <ul class="text-sm font-medium text-center text-gray-700 dark:text-gray-300 p-3">
            <li v-for="(mode, index) in modes" :class="{
              'mt-3': index !== 0
            }">
              <a href="#" @click.prevent="onModeClick($event, mode.id)" class="block px-4 py-2 font-bold rounded-lg relative" :class="{
                'text-white bg-blue-700 active': appStore.mode === mode.id,
                'hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-ww-blue-200': appStore.mode !== mode.id
              }"><Icon :icon="mode.icon" class="inline size-6 absolute left-3 top-1.5" />{{ mode.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </Transition>

    <Transition name="slide-down">
      <div v-if="dropdownOpen" v-on-click-outside="closeDropdown" class="absolute left-0 z-[40] bg-white dark:bg-ww-blue rounded-lg shadow-lg dark:shadow-gray-900 p-4 w-full origin-top select-none mt-[7px]">
        <!-- Add your dropdown menu items here -->
        <ul class="space-y-2">
          <li v-if="!inApp"><a href="#" @click.prevent="onAppsClick" class="block hover:bg-gray-200 dark:hover:bg-ww-blue-200 px-4 py-2 rounded dark:text-gray-200">Download iPhone/Android App</a></li>
          <li><a href="https://x.com/WxWiseApp" target="_blank" class="block hover:bg-gray-200 dark:hover:bg-ww-blue-200 px-4 py-2 rounded dark:text-gray-200">Follow us on X</a></li>
          <li class="py-2">
            <hr class="border-t border-gray-200 dark:border-gray-600">
          </li>
          <li><a href="#" @click.prevent="onUserPreferencesClick" class="block hover:bg-gray-200 dark:hover:bg-ww-blue-200 px-4 py-2 rounded dark:text-gray-200">My Preferences</a></li>
          <li><a href="#" @click.prevent="onMapSettingsClick" class="block hover:bg-gray-200 dark:hover:bg-ww-blue-200 px-4 py-2 rounded dark:text-gray-200">Map Settings</a></li>
          <li><a href="#" @click.prevent="onKeyboardShortcutsClick" class="block hover:bg-gray-200 dark:hover:bg-ww-blue-200 px-4 py-2 rounded dark:text-gray-200 hidden md:block">Keyboard Shortcuts</a></li>
          <li class="py-2">
            <hr class="border-t border-gray-200 dark:border-gray-600">
          </li>
          <li><a href="#" @click.prevent="onLeaveFeedbackClick" class="block hover:bg-gray-200 dark:hover:bg-ww-blue-200 dark:text-gray-200 px-4 py-2 rounded">Leave Feedback</a></li>
          <li class="py-2">
            <hr class="border-t border-gray-200 dark:border-gray-600">
          </li>
          <li><a href="https://www.weatherwise.app" target="_blank" class="block hover:bg-gray-200 dark:hover:bg-ww-blue-200 dark:text-gray-200 px-4 py-2 rounded">About</a></li>
          <li><a href="https://www.weatherwise.app/disclaimer.html" target="_blank" class="block hover:bg-gray-200 dark:hover:bg-ww-blue-200 dark:text-gray-200 px-4 py-2 rounded">Disclaimer</a></li>
          <li><a href="https://www.weatherwise.app/privacy-policy.html" target="_blank" class="block hover:bg-gray-200 dark:hover:bg-ww-blue-200 dark:text-gray-200 px-4 py-2 rounded">Privacy Policy</a></li>
          <li><a href="https://www.weatherwise.app/eula.html" target="_blank" class="block hover:bg-gray-200 dark:hover:bg-ww-blue-200 dark:text-gray-200 px-4 py-2 rounded">Terms of Service</a></li>
          <li><a href="#" @click.prevent="onCreditsClick" class="block hover:bg-gray-200 dark:hover:bg-ww-blue-200 dark:text-gray-200 px-4 py-2 rounded">Credits</a></li>
          <!-- <li><a href="#" @click.prevent="onCrash1Click" class="block hover:bg-gray-200 px-4 py-2 rounded">Crash1</a></li> -->
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue'
import { useAppStore } from '@/stores/app'
import { vOnClickOutside } from '@vueuse/components'

import modals from '@/logic/Modals/Helper'

import radarTowers from '@/data/radar_towers.js'

export default {
  name: 'AppLogo',
  components: {
    Icon
  },
  directives: {
    'on-click-outside': vOnClickOutside,
  },
  data() {
    return {
      menuOpen: false,
      dropdownOpen: false,
      modes: [{
        id: 'RADAR',
        icon: 'game-icons:radar-sweep',
        name: 'Radar'
      }, {
        id: 'SATELLITE',
        icon: 'material-symbols-light:satellite-alt-rounded',
        name: 'Satellite'
      }, {
        id: 'OUTLOOKS',
        icon: 'arcticons:forecaster',
        name: 'Outlooks'
      }]
    }
  },
  computed: {
    appStore() {
      return useAppStore()
    },
    inApp() {
      return typeof window.WeatherWiseBridge !== 'undefined'
    },
    activeMode() {
      return this.modes.find(m => m.id === this.appStore.mode)
    }
  },
  methods: {
    onModeClick(e, mode) {
      this.menuOpen = false;

      this.appStore.setMode(mode);

      // throw new Error('Test rollbar error');
    },
    onMobileMenuItemClick(e) {
      this.menuOpen = !this.menuOpen;
    },
    onMenuOutsideClick() {
      // Hack to deal with mobile toggle button not working...
      setTimeout(() => {
        this.menuOpen = false;
      }, 10);
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeDropdown(event) {
      // Check if the click target is not the button
      if (!event.target.closest('button')) {
        this.dropdownOpen = false;
      }
    },
    onCreditsClick() {
      modals.credits()
      this.dropdownOpen = false;
    },
    onAppsClick() {
      modals.apps()
      this.dropdownOpen = false;
    },
    onUserPreferencesClick() {
      modals.userPreferences()
      this.dropdownOpen = false;
    },
    onMapSettingsClick() {
      modals.mapSettings()
      this.dropdownOpen = false;
    },
    onKeyboardShortcutsClick() {
      modals.keyboardShortcuts()
      this.dropdownOpen = false;
    },
    onLeaveFeedbackClick() {
      window.Userback.open();
      this.dropdownOpen = false;
    },
    async onCrash1Click() {
      this.dropdownOpen = false;

      for(const feature of radarTowers.features) {
        await window.map.radar.turnOnRadarViaId(feature.properties.id);
      }

      alert('Done')
    }
  }
}
</script>

<style scoped>
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.3s ease-out;
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.slide-down-enter-to,
.slide-down-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>