// modules/LocationModule.js
const LocationModule = {
  requestPermission: () => {
    return new Promise((resolve, reject) => {
      const messageHandler = (event) => {
        console.log('Received message:', event.data || event.nativeEvent?.data); // Log all received messages
        try {
          const data = JSON.parse(event.data || event.nativeEvent?.data);
          console.log('Parsed data:', data);
          if (data.type === 'LOCATION_PERMISSION_RESULT') {
            console.log('Location permission status:', data.status); // Debug log for permission status
            window.removeEventListener('message', messageHandler, true);
            resolve(data.status);
          } else if (data.type === 'ERROR') {
            console.error('Error requesting location permission:', data.message); // Debug log for error
            window.removeEventListener('message', messageHandler, true);
            reject(new Error(data.message));
          }
        } catch (error) {
          console.error('Error parsing message:', error);
        }
      };

      window.addEventListener('message', messageHandler, true);
      console.log('Event listener attached');

      console.log('Requesting location permission...'); // Debug log for request initiation
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'REQUEST_LOCATION_PERMISSION' }));
    });
  },

  getCurrentPosition: () => {
    return new Promise((resolve, reject) => {
      const messageHandler = (event) => {
        try {
          const data = JSON.parse(event.data || event.nativeEvent?.data);
          console.log('Received message for current position:', data); // Debug log for received message
          if (data.type === 'LOCATION_RESULT') {
            window.removeEventListener('message', messageHandler, true);
            console.log('Current location received:', data.location); // Debug log for location result
            resolve(data.location);
          } else if (data.type === 'ERROR') {
            window.removeEventListener('message', messageHandler, true);
            console.error('Error getting current position:', data.message); // Debug log for error
            reject(new Error(data.message));
          }
        } catch (error) {
          console.error('Error parsing message:', error);
        }
      };
      window.addEventListener('message', messageHandler, true);
      console.log('Requesting current position...'); // Debug log for request initiation
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_LOCATION' }));
    });
  },
};

export default LocationModule;
