<template>
  <p v-html="text.replace(/(?:\r\n|\r|\n)/g, '<br>')"></p>
</template>

<script>
export default {
  name: 'RadarProductHelp',
  props: ['text'],
  emits: ['close']
}
</script>
