// GeolocationWrapper.js
import ExpoBridge from '../Expo/ExpoBridge';
import { isExpoBridgeAvailable } from '@/tools/expo.js';

const GeolocationWrapper = {
  requestPermission: async () => {
    console.log('Checking if ExpoBridge is available...');
    if (isExpoBridgeAvailable()) {
      console.log('ExpoBridge is available, requesting permission...');
      return ExpoBridge.location.requestPermission();
    } else {
      console.log('ExpoBridge is not available, querying navigator permissions...');
      return new Promise((resolve, reject) => {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          console.log('Geolocation permission query result:', result.state);
          if (result.state === 'granted') {
            console.log('Geolocation permission granted.');
            resolve('granted');
          } else if (result.state === 'prompt') {
            console.log('Geolocation permission prompt shown.');
            resolve('prompt');
          } else {
            console.error('Geolocation permission denied.');
            reject(new Error('Permission denied'));
          }
        }).catch((error) => {
          console.error('Error querying geolocation permissions:', error);
          reject(error);
        });
      });
    }
  },

  getCurrentPosition: async (options) => {
    console.log('Requesting location permission...');
    const permissionStatus = await GeolocationWrapper.requestPermission();
    if (permissionStatus === 'denied') {
      console.error('Location permission denied.');
      throw new Error('Location permission denied');
    }

    try {
      console.log('Getting current position...');
      if (isExpoBridgeAvailable()) {
        console.log('Using ExpoBridge to get current position...');
        try {
          const expoLocation = await ExpoBridge.location.getCurrentPosition();
          return Promise.resolve({
            coords: {
              latitude: expoLocation.latitude,
              longitude: expoLocation.longitude,
            },
            _expo: expoLocation
          })
        } catch (error) {
          return Promise.reject(error);
        }
      } else {
        console.log('Using navigator.geolocation to get current position...');
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
      }
    } catch (error) {
      console.error('Error getting current position:', error);
      throw error;
    }
  },
};

export default GeolocationWrapper;
