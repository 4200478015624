// @todo test this in the app
const AlertModule = {
  alert(title, message) {
    window.ReactNativeWebView.postMessage(JSON.stringify(
      {
        type: 'NATIVE_ALERT',
        message: message,
        title: title
      }
    ));
  }
}

export default AlertModule;